import { Suspense, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import "../styles/reportBody.css";

import {
  ALL_WIDGETS,
  BUTTON_TYPE,
  BUTTON_VARIANT,
  HEADER_ELEMENT,
  SIZES,
  
} from "../../class/constants";

import { lang } from "../../language/messages_en";
import { getLocalStorageValueByParameter, logout} from "../../class/common";
import ErrorBoundary from "../../ErrorBoundary";
import {  updateDatasetState, updatePeriodsStatusState, updateVectorState} from "../../actions/scenariosActions";
import { getClientPeriods, getDatasets, getPeriodsStatus, getVectors } from "../api/api";
import { useAuthenticated, useCalendarToggle } from "../CustomHooks";
import { renderPeriodCalendar } from "../functions/componentFunctions";
import { ReportHeader } from "../ReportHeader";
import { TabsNew } from "../../form/elements";
import { convertPxToViewport } from "../../class/formatting";
import { areObjectsNotEmpty, copyObjectValues} from "../../class/utils";
import { saveCookie } from "../../class/jqueries";

import { ReactComponent as ExecutiveViewIcon } from "../../styles/images/actionableInsights/eye.svg";
import { ReactComponent as DepartmentViewIcon } from "../../styles/images/actionableInsights/users.svg";
import { ReactComponent as ManagerViewIcon } from "../../styles/images/actionableInsights/user.svg";
import '../../components/actionableInsights/returnInsights.css'
import { LoaderSkeleton } from "../LoaderSkeleton";

import ExecutiveView from "../../components/actionableInsights/ExecutiveView";
import DepartmentView from "../../components/actionableInsights/DepartmentView";
import ManagerView from "../../components/actionableInsights/ManagerView";
import Button from "../../newComponents/Button";
import Label from "../../newComponents/Label";

/**
 * @author Sarah Farjallah
 * @description component renders {@link } component 
 * @param {*} props 
 * @returns {@link ReportHeader}, {@link }
 */
const ReturnInsightsWrapper = (props) => {
  /**Store */
  const userAllowedMenuLinks = props.userAllowedMenuLinks;
  const userSettings = props.userSettings;
  const scenarioState = props.scenarioState;

  /**References & react utility */
  const dispatch = useDispatch();
  const params = useParams();
  
  const reportRef = useRef();

  /**Custom Hooks */
  const isAuthenticated = useAuthenticated(userAllowedMenuLinks);
  const { toggleCalendar } = useCalendarToggle();

  /**State */
  const [periodsStatusState, setPeriodsStatusState] = useState({});
  const [clientPeriodsState, setClientPeriodsState] = useState([]);
  const [vectorState, setVectorState] = useState();
  const [datasetState, setDatasetState] = useState({});



  /**Header Related States */
  const [headerElements, setHeaderElements] = useState([]);
  const [profitFormat, setProfitFormat] = useState(ALL_WIDGETS.ACTIONABLE_INSIGHTS.RETURN_INSIGHTS);
  const [reportTitle, setReportTitle] = useState(ALL_WIDGETS.TITLES.ACTIONABLE_INSIGHTS.RETURN_INSIGHTS);
  const [activeTab, setActiveTab] = useState('Executive View');

  /**on screen initialization if user entered url with no access to screen he gets logged out */
  useEffect(() => {
    if (!isAuthenticated) {
      logout();
    }
  }, [isAuthenticated]);


  useEffect(() => {
    if (scenarioState) {
      setPeriodsStatusState({});
      setClientPeriodsState({});
      setVectorState({});
      getPeriodsStatus(scenarioState, dispatch, updatePeriodsStatusState, setPeriodsStatusState, props, profitFormat, userSettings, 12);
      getClientPeriods(undefined, undefined, setClientPeriodsState, profitFormat, params, userSettings);
      getDatasets(scenarioState, dispatch, updateDatasetState,setDatasetState, props, profitFormat, params,false, userSettings, datasetState);
      getVectors(scenarioState, dispatch, updateVectorState, setVectorState, reportTitle, profitFormat, props, false, undefined, userSettings, vectorState);
    }
  }, [scenarioState]);

  useEffect(() => {
    if (
      Object.keys(periodsStatusState).length > 0 &&
      Object.keys(scenarioState).length > 0 &&
      Object.keys(clientPeriodsState).length > 0 &&
      userSettings.user
    ) {
      let headerElements = getHeaderElements();
      setHeaderElements(headerElements);
    }
  }, [periodsStatusState, clientPeriodsState, reportRef.current, activeTab]);


  const handleTabChange = (tab) => {
    setActiveTab(tab);
  }

  const handleElementChange = (name, e, fromMount = false, dimIndex=0)=> {
    switch(name){
        case HEADER_ELEMENT.SELECTION_RANGE: {
            let selectedYears = e.filter(year => year.isSelected);
            let tempState = copyObjectValues(periodsStatusState);
            if (selectedYears.length > 0) {
                let periods = getLocalStorageValueByParameter("periods")? JSON.parse(getLocalStorageValueByParameter("periods")): [];
                let sortedSelectedYears = selectedYears.map(p => p.year + p.value).sort();
                let startPeriod = sortedSelectedYears[0];
                let endPeriod = sortedSelectedYears[sortedSelectedYears.length - 1];
                let startDate = new Date(periods.filter(e=>e.value === startPeriod)[0].start_date);
                let endDate = new Date(periods.filter(e=>e.value === endPeriod)[0].end_date);
  
                tempState.nextCustomStartDate = startDate;
                tempState.nextCustomEndDate = endDate;
                tempState.customStartDate = startDate;
                tempState.customEndDate = endDate;
                tempState.startPeriod = startPeriod;
                tempState.endPeriod = endPeriod;
                
                saveCookie("nextCustomStartDate", startPeriod);
                saveCookie("nextCustomEndDate", endPeriod);
                setPeriodsStatusState(tempState);                
             
            }
            break;
        }
        default: ""
      }
    }

  const getHeaderElements = () => {
    let headerElements = [];
    headerElements.push(
        <div style={{display: "flex", width: "100%", alignItems: "center", padding: convertPxToViewport(10)}}
             className='first_header_row gap_between_buttons'>
            {<div style={{fontSize: convertPxToViewport(24), fontWeight: "600"}}>{reportTitle}</div>}
            <Label size="small" value="Beta"/>
            <div style={{padding: convertPxToViewport(7), width: '11%'}}>
                {renderPeriodCalendar(periodsStatusState, handleElementChange, undefined, clientPeriodsState.allPeriods, toggleCalendar, false, scenarioState)}
            </div>
        </div>
    );

      headerElements.push(
          <div className='second_row_header' style={{width: "100%"}}>
              <div className={"uk-margin-small-left uk-margin-medium-bottom uk-margin-medium-top"} style={{display: "flex", width: "100%", alignItems: "flex-start", columnGap: '0.5vw'}}>
              {/* <TabsNew
                  activeTab={activeTab}
                  onChange={handleTabChange}
                  tabs={[{text:'Executive View', leftIcon:<ExecutiveViewIcon style={{transform:'scale(0.7)'}}/>}, {text:'Department View', leftIcon:<DepartmentViewIcon style={{transform:'scale(0.7)'}}/>}, {text:'Manager View', leftIcon:<ManagerViewIcon style={{transform:'scale(0.7)'}}/>}]}
              /> */}

              <Button
                  label={"Executive View"}
                  forceNewDesign = {true} // force new design even if flag is off (for a demo) -- to be removed later
                  variant={activeTab === "Executive View"? BUTTON_VARIANT.PRIMARY : BUTTON_VARIANT.SECONDARY}
                  size={SIZES.LARGE}
                  type={BUTTON_TYPE.DEFAULT}
                  onBtnClick={()=>{handleTabChange("Executive View")}}
                  leftIcon={<ExecutiveViewIcon style={{transform:'scale(0.7)'}}/>} // adding marginTop to give the illusion of having the edit and delete aligned with the profile name
              />
              <Button  
                  label={"Department View"}
                  forceNewDesign = {true} // force new design even if flag is off (for a demo) -- to be removed later
                  variant={activeTab === "Department View"? BUTTON_VARIANT.PRIMARY : BUTTON_VARIANT.SECONDARY}
                  size={SIZES.LARGE}
                  type={BUTTON_TYPE.DEFAULT}
                  onBtnClick={()=>{handleTabChange("Department View")}}
                  leftIcon={<DepartmentViewIcon style={{transform:'scale(0.7)'}}/>} // adding marginTop to give the illusion of having the edit and delete aligned with the profile name
              />
              <Button
                  label={"Manager View"}
                  forceNewDesign = {true} // force new design even if flag is off (for a demo) -- to be removed later
                  variant={activeTab === "Manager View"? BUTTON_VARIANT.PRIMARY : BUTTON_VARIANT.SECONDARY}
                  size={SIZES.LARGE}
                  type={BUTTON_TYPE.DEFAULT}
                  onBtnClick={()=>{handleTabChange("Manager View")}}
                  leftIcon={<ManagerViewIcon style={{transform:'scale(0.7)'}}/>} // adding marginTop to give the illusion of having the edit and delete aligned with the profile name
              />

        </div>
      </div>
    );
  
    return headerElements;
  };

  const renderTab =() => {
    if (activeTab === "Executive View") {
      return (
        <ExecutiveView periodsStatusState={periodsStatusState} datasetState={datasetState} clientPeriodsState={clientPeriodsState} scenarioState={scenarioState}/>
      )
    } else if (activeTab === 'Department View') {
      return (
        <DepartmentView periodsStatusState={periodsStatusState} datasetState={datasetState} clientPeriodsState={clientPeriodsState} scenarioState={scenarioState}/>
      )
    } else if (activeTab === 'Manager View'){
      return (
        <ManagerView periodsStatusState={periodsStatusState} datasetState={datasetState} clientPeriodsState={clientPeriodsState} scenarioState={scenarioState}/>
      )
    }

  }
  let showSkeleton = !(
    areObjectsNotEmpty([periodsStatusState, vectorState, scenarioState, datasetState]) &&
    headerElements.length > 0
  );
  return (
    <>
      <ErrorBoundary>
        <Suspense fallback={<p id="loading">Loading...</p>}>
        {showSkeleton && <LoaderSkeleton />}
        {!showSkeleton && areObjectsNotEmpty([periodsStatusState, vectorState, scenarioState, datasetState]) ?
          <>
            <ReportHeader headerElements={headerElements}/>
            {renderTab()}
          </>
        :""}
        </Suspense>
      </ErrorBoundary>
    </>
  );
};

export { ReturnInsightsWrapper };

