
import { Suspense, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';


// import NewHeader from './NewHeader';
import Base from '../../Base';
import AppliedFilters from '../../components/AppliedFilters';
import ConfirmPasswordModal from '../../components/ConfirmPasswordModal';
import FilterByDropdown from '../../components/FilterByDropdown';
import ManageColumns from '../../components/manageColumns/ManageColumns';
import NewBanner from '../../components/NewBanner';
import { DimensionsDropdowns, Loader } from '../../form/elements';
import Button from '../../newComponents/Button';
import DropDown from '../../newComponents/DropDown';
import Modal from '../../newComponents/Modal';
import SwitchToggle from '../../newComponents/SwitchToggle';
import FilterDialog from '../../sections/filter/FilterDialog';
import { ReportHeader } from '../ReportHeader';

import {
    ALL_FORMATS,
    ALL_WIDGETS,
    API_URL,
    BUTTON_TYPE, BUTTON_VARIANT,
    CURRENT_FILTER_COOKIE,
    DIALOG_SIZE,
    DROPDOWN_TYPE,
    FILTER, FY_VALUES,
    FormatTypes,
    HEADER_ELEMENT, LISTENER, MENU_ITEM,
    PROFILE_COLUMN, PSS,
    ROLLING_SEGMENTS,
    SAVED_FILTER_ID_COOKIE,
    SAVE_COMPANY_FILTERS,
    SCENARIO_STATUS,
    SIZES, UI_ACTIONS,
    costtype
} from '../../class/constants';
import { lang } from "../../language/messages_en";

import { formatAdvancedFilter, formatBasicFilter, getLocalStorageValueByParameter, logout, setLocalStorageValueByParameter, updateWindowLoading } from '../../class/common';
import { generateQuarter, getActualYear, getPeriodDifference, getPeriodFromDate, getPeriodTextFromMonth, getPeriodsObjectNew } from '../../class/date';
import { convertPxToViewport } from '../../class/formatting';
import { alertAndLogError, saveCookie } from '../../class/jqueries';
import { areObjectsNotEmpty, checkIfMonthsValid, copyObjectValues, findOptionByKey, getSectionExists, profileHasColumnsAccess, tryParse } from '../../class/utils';
import ErrorBoundary from '../../ErrorBoundary';
import { getNewEntityFilter } from '../../sections/filter/FilterHelperFunctions';
import { ReactComponent as RoundInfoIcon } from "../../styles/images/menu-svgs/info.svg";
import { updateDatasetState, updatePeriodsStatusState, updatePsViewsState, updateVectorState } from '../../actions/scenariosActions';
import { getBridgeOptions, getClientPeriods, getCustomViewData, getDatasets, getFilterEntities, getPeriodsStatus, getVectors } from '../api/api';
import { useAuthenticated, useCalendarToggle, useLoading } from "../CustomHooks";
import {
    messageDialogContent,
    renderBreadcrumbsAndDescription,
    renderButton,
    renderPeriodCalendar,
    renderTitle,
    runExtendedProfitStack,
    tablesToExcel
} from '../functions/componentFunctions';
import { addCorrespondingParenthesisToFilter } from '../functions/filterFunctions';
import { LoaderSkeleton } from '../LoaderSkeleton';
import { Dashboard } from '@mui/icons-material';
import Dashboards from '../../sections/dashboards/Dashboards';
import ManageDashboards from '../../sections/dashboards/ManageDashboards';
import { updateBridgeOptions } from '../actions/bridgeOptionsActions';
import { checkIfAtLeastOnePeriodIsGenerated } from '../functions/periodFunctions';
import { QuickFilters } from '../../components/quickFilters/QuickFilters';
import { useListener } from '../../actions/CustomEventHandler';
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { useFeatureFlagEnabled } from 'posthog-js/react';

const _modified = " Modified"; 
const _selectedListProfile = "selectedProfile_list";
/**
 * @author Mostafa Haydar
 * @description component renders title based on the selected dashbaord, filter,period calendar, Create dashboard dropdown and add widget button with  {@link Dashboard} component , calls isAuthenticated hook to check if user has authentication view the screen
 * @param {*} props 
 * @returns {@link ReportHeader}, {@link Dashboard}
 */
const DashboardWrapper = (props) => {
    /**Store */
    const profiles = useSelector(state=>state.profiles);
    const stateProfiles = useSelector(state=>state.stateProfiles);
    const customGroups = useSelector(state=>state.customGroups);
    const profileColumns = useSelector(state=>state.profileColumns);
    const columnProfiles = useSelector(state=>state.columnProfiles);
    const psLinesOptions = useSelector(state=>state.psLinesOptions);
    const quadrantTierOptions = useSelector(state=>state.quadrantTierOptions);

    const userAllowedMenuLinks = props.userAllowedMenuLinks;
    const userSettings = props.userSettings;
    const scenarioState = props.scenarioState;
    const switchClient = props.switchClient;

    const loading = useLoading();

    /**References & react utility */
    const dispatch = useDispatch();
    const params = useParams();
    const history = useHistory();
    
    const manageColsOverlay = useRef();
    const dimensionDropdownsRef = useRef();
    const reportRef = useRef();
    const manageColsRef = useRef();
    const appliedFiltersRef = useRef();
    const filterDialRef = useRef();
    const manageDashboardsRef = useRef();
    const filterByRef = useRef();
    const profitStackSelectedEntities = useRef();
    const dropDownRef = useRef();
    const exportRef = useRef();
    const exportRefVal = useRef();
    const confirmPassRef = useRef();
    const countRef = useRef(0);

    /**Custom Hooks */
    const isAuthenticated = useAuthenticated(userAllowedMenuLinks);
    const { toggleCalendar } = useCalendarToggle();

    /**State */
    const [periodsStatusState, setPeriodsStatusState] = useState({});
    const [vectorState, setVectorState] = useState({});
    const [datasetState, setDatasetState] = useState({});
    const [PSViewsState, setPSViewsState] = useState({}); 
    const [clientPeriodsState, setClientPeriodsState] = useState([]);
    const [bridgeOptionsState, setBridgeOptionsState] = useState(false);
    const [currScenario, setCurrScenario] = useState(scenarioState.scenarios[0]);

    /**Filter Related States */
    const [filterFinal, setFilterFinal] = useState(props.history.location.state ? props.history.location.state.outsideFilter ? JSON.stringify(tryParse(props.history.location.state.outsideFilter).filter) || [] : [] : []);
    const [filterFinalBasic, setFilterFinalBasic] = useState(props.history.location.state ? props.history.location.state.mainFilterFinalBasic ? tryParse(props.history.location.state.mainFilterFinalBasic) || [] : [] : []);
    const [basicFilterEntities, setBasicFilterEntities] = useState([]);
    const [exportQueryFilter, setExportQueryFilter] = useState("");
    const [displayFilter, setDisplayFilter] = useState();
    const [isFilterDisabled, setIsFilterDisabled] = useState(false);
    const [isAdvancedFilterApplied, setIsAdvancedFilterApplied] = useState();
    const [tempFilter, setTempFilter] = useState();
    const [basicFilterEntitiesState, setBasicFilterEntitiesState] = useState([]);
    const [count, setCount] = useState(0);
    const [basicFilterItem, setBasicFilterItem] = useState();
    const [drillFilter, setDrillFilter] = useState();

    /**Header Related States */
    const [headerChangedCounter, setHeaderChangedCounter] = useState(0); 
    const [headerElements, setHeaderElements] = useState([]);
    const [profitFormat, setProfitFormat] = useState(ALL_WIDGETS.TITLES.DASHBOARDS);
    const [reportTitle, setReportTitle] = useState(ALL_WIDGETS.TITLES.DASHBOARDS);
    const [manageColumnsProfile, setManageColumnsProfile] = useState();
    const [openLimitAccessDialog, setOpenLimitAccessDialog] = useState(false);
    const [limitAccessMsg, setLimitAccessMsg] = useState('');
    const [viewStackOpen, setViewStackOpen] = useState(false);
    const [showGreyOverLay, setShowGreyOverLay] = useState(false);
    const [isScenarioBannerVisible, setScenarioBannerVisible] = useState(false);
    const [callGo, setCallGo] = useState(false);
    const [exportOptions, setExportOptions] = useState([]);
    const [exportValue, setExportValue] = useState();
    const [headerChanged, setHeaderChanged] = useState(false);
    const [error, setError] = useState("");
    const [message, setMessage] = useState(lang.header.custom_text.download_start);
    const [dataLength, setDataLength] = useState(0);
    const [isDrilling, setIsDrilling] = useState(false);
    const [openInfoDialog, setOpenInfoDilog] = useState();
    const [infoDialogMsg, setInfoDialogMsg] = useState();
    const [openFilterDialog, setOpenFilterDialog] = useState();
    const [selectedDashboard, setSelectedDashBoard] = useState([]);
    const [cloned, setCloned] = useState();

    const [menuOpen, setMenuOpen] = useState(props.isMenuOpen);
    const useNewComponents = useFeatureFlagEnabled('use_new_components');

    const manageColumnsProps = {
        manageColumnsUpdateSections: [ALL_WIDGETS.FIELDS.LIST, MENU_ITEM.FIELDS.HEATMAP, MENU_ITEM.FIELDS.EROSION_AND_GROWTH, ALL_WIDGETS.FIELDS.COMPARE_SCENARIOS]
    };
    const manageColsAccess = {
        [ALL_WIDGETS.FIELDS.MANAGE_USER_COLUMNS]: useMemo(() => getSectionExists( userAllowedMenuLinks, ALL_WIDGETS.FIELDS.MANAGE_USER_COLUMNS), [userAllowedMenuLinks]),
        [ALL_WIDGETS.FIELDS.VIEW_COMPANY_COLUMNS]: useMemo(() => getSectionExists( userAllowedMenuLinks, ALL_WIDGETS.FIELDS.VIEW_COMPANY_COLUMNS), [userAllowedMenuLinks]),
        [ALL_WIDGETS.FIELDS.EDIT_COMPANY_COLUMNS]: useMemo(() => getSectionExists( userAllowedMenuLinks, ALL_WIDGETS.FIELDS.EDIT_COMPANY_COLUMNS), [userAllowedMenuLinks]),
        [ALL_WIDGETS.FIELDS.VIEW_VECTOR_COLUMNS]: useMemo(() => getSectionExists( userAllowedMenuLinks, ALL_WIDGETS.FIELDS.VIEW_VECTOR_COLUMNS), [userAllowedMenuLinks])
    };

    const isBridgeAllowed = useMemo(() => getSectionExists( userAllowedMenuLinks, MENU_ITEM.FIELDS.BRIDGE_REPORT), [userAllowedMenuLinks]);

    /**on screen initialization if user entered url with no access to screen he gets logged out */
    useEffect(() => {
        if(!isAuthenticated) {
          logout();
        }
      }, [isAuthenticated]);

    useEffect(()=>{
        if (scenarioState) {
          //when changing scenario, clear all states so that it won't run useEffect when new states are still not updated
            setPeriodsStatusState({});
            setVectorState({});
            setDatasetState({});
            setClientPeriodsState({});
            getPeriodsStatus(scenarioState, dispatch, updatePeriodsStatusState, setPeriodsStatusState, props, profitFormat, userSettings, 12);
            getClientPeriods(undefined, undefined, setClientPeriodsState, profitFormat, params, userSettings);
            getVectors(scenarioState, dispatch, updateVectorState, setVectorState, reportTitle, profitFormat, props, false, filterFinal, userSettings, vectorState, filterDialRef);
            getDatasets(scenarioState, dispatch, updateDatasetState,setDatasetState, props, profitFormat, params,false, userSettings, datasetState);
            setScenarioBannerVisible(scenarioState?.scenarioStatus === SCENARIO_STATUS.REVIEW);
            getBridgeOptions(scenarioState, dispatch, updateBridgeOptions, bridgeOptionsState, setBridgeOptionsState)
            
        }
    },[scenarioState]);


    useListener(LISTENER.APPLY, () => {
        if(reportRef.current.state?.accessDenied){
            setPeriodsStatusState({});
            setVectorState({});
            setDatasetState({});
            setClientPeriodsState({});
            getPeriodsStatus(scenarioState, dispatch, updatePeriodsStatusState, setPeriodsStatusState, props, profitFormat, userSettings, 12);
            getClientPeriods(undefined, undefined, setClientPeriodsState, profitFormat, params, userSettings);
            getVectors(scenarioState, dispatch, updateVectorState, setVectorState, reportTitle, profitFormat, props, false, filterFinal, userSettings, vectorState, filterDialRef);
            getDatasets(scenarioState, dispatch, updateDatasetState,setDatasetState, props, profitFormat, params,false, userSettings, datasetState);
            setScenarioBannerVisible(scenarioState?.scenarioStatus === SCENARIO_STATUS.REVIEW);
            getBridgeOptions(scenarioState, dispatch, updateBridgeOptions, bridgeOptionsState, setBridgeOptionsState)
        }else{
            apply();
            setCallGo(false);
        }
    });

    /**
     * After calling getPeriodStatus, we check if the scenario has changed or not. In case it did, we fetch new data.
     */
    useEffect(() => {
      if(areObjectsNotEmpty([scenarioState, periodsStatusState])) {
        if (currScenario !== scenarioState.scenarios[0]) {
          apply();
          setCurrScenario(scenarioState.scenarios[0]);
        }
      }
    }, [periodsStatusState])

    useEffect(()=>{
        if (areObjectsNotEmpty([periodsStatusState, scenarioState, clientPeriodsState, vectorState, PSViewsState]) && userSettings.user) {
            let headerElements = useNewComponents? getNewHeaderElements() : getHeaderElements();
            setHeaderElements(headerElements);
            if (exportValue !== undefined) {
              setHeaderChanged(true);
            }
        }
    },[periodsStatusState, clientPeriodsState, vectorState, PSViewsState, manageColumnsProfile, showGreyOverLay, exportOptions,
         isDrilling, count, basicFilterEntities, filterFinalBasic, headerChangedCounter, manageColumnsProfile, exportValue, reportTitle, selectedDashboard,
         bridgeOptionsState, filterFinal]);

    useEffect(()=>{
        if (vectorState) {
            getCustomViewData(PSViewsState, vectorState, params, filterFinal, userSettings, scenarioState ,dispatch, updatePsViewsState, setPSViewsState, false, props)
        }
    },[vectorState]);

    useEffect(()=>{
        if (cloned) {
            reportRef.current?.fetchWidgets()
        }
    },[cloned]);
    
    useEffect(()=>{
        if(callGo) {
            apply();
            setCallGo(false);
        }
    },[callGo, manageColumnsProfile, filterFinal, filterFinalBasic]);

    useEffect(() => {
        if (props.isMenuOpen !== menuOpen) {
          setMenuOpen(props.isMenuOpen);
        }
      }, [props.isMenuOpen]);

      useEffect(() => {
        reportRef?.current?.checkLoadingWidgets();
    }, [loading]);

    /**HeaderElements Related functions */
    const getHeaderElements = () => {
        let sectionsData = getFilterBySectionsData();
       
        let isDropDownDisabled = false; 
        let headerElements = [];
        let canSaveCompanyFilters = getSectionExists(userAllowedMenuLinks, SAVE_COMPANY_FILTERS);

        headerElements.push(
            <div style={{ display: "flex", width: "100%", alignItems: "center" }} className='first_header_row gap_between_buttons'>
                {renderTitle(reportTitle)}
                {renderPeriodCalendar(periodsStatusState, handleElementChange, undefined, clientPeriodsState.allPeriods, toggleCalendar, isDropDownDisabled, scenarioState)}
                {renderButton(lang.modal.buttons.apply, lang.modal.buttons.apply.toLowerCase(), '', '', apply, !showGreyOverLay)}
            </div>
        );
   
        headerElements.push(
          <div
            className="second_header_row gap_between_buttons"
            style={{ display: "flex", width: "100%", padding: "0.41667vw 0" }}
          >
            {userSettings?.useNewQuickFilters ? (
                <QuickFilters
                    vectorOptions={vectorState.vectorOptions}
                    profitFormat={profitFormat}
                    userSettings={userSettings}
                    scenarioState={scenarioState}
                    filterFinal={filterFinal}
                    setQuickFilters={setQuickFilters}
                    switchBasicToAdvanced={switchBasicToAdvanced}
                    clearAdvancedFilters={clearAdvancedFilters}
                    filterDialRef={filterDialRef}
                />) 
                : (
                <FilterByDropdown
                ref={filterByRef}
                sectionsData={copyObjectValues(sectionsData.data)}
                clearClick={clearClick}
                isClearBtnDisabled={sectionsData.isClearBtnDisabled}
                handleCheckboxChange={handleCheckBoxChange}
                basicFilterCountLimit={userSettings.basicFilterCountLimit}
                switchBasicToAdvanced={switchBasicToAdvanced}
                />)
            }
            <FilterDialog
              id={"secondary_header_" + "dashboards"}
              ref={filterDialRef}
              section_id={params.sectionId}
              vectorOptions={vectorState.vectorOptions}
              profitFormat={profitFormat}
              mustHideFilter={false}
              categoryToolkit={false}
              onChangeFilter={onChangeFilter}
              quadTierIsAllowed={true}
              isTempScenario={false}
              scenario_id={scenarioState.scenario}
              mustFetchDatasets={false}
              user={userSettings.user}
              isDrilling={false}
              fromOptions={datasetState.datasetOptions}
              datasetOptions_id={datasetState.datasetOptions_id}
              dataSet={datasetState.dataset}
              useCookies={true}
              parentMessage={""}
              psFilterDisabled={false}
              quadrantsDisabled={false}
              quadrantDefault={false}
              checkIfMonthsValid={checkIfMonthsValid}
              comparisonScenarioId={undefined}
              filterDisplayEditable={true}
              psLinesOptions={psLinesOptions}
              quadrantTierOptions={quadrantTierOptions}
              dispatch={dispatch}
              savedFilterDataState={filterDialRef?.current?.state?.savedFilterData}
              isRedirectionFromStacks={false}
              savedFilter={true}
              fromSecondaryHeader={true}
              filterInDashBoard={true}
              useNewQuickFilters={userSettings.useNewQuickFilters}
              filterFinal={filterFinal}
              canSaveCompanyFilters={canSaveCompanyFilters}
              // handleOverlayAndHeights={this.props.handleOverlayAndHeights}
            />
            <div className="uk-flex" style={{ marginLeft: "auto" }}>
                    <ManageDashboards ref={manageDashboardsRef} key={HEADER_ELEMENT.ADD_DASHBOARDS} scenarioId={scenarioState.scenario} user={userSettings.user}
                        userAllowedMenuLinks={userAllowedMenuLinks} manageCompanyDashboardsAccess={{[MENU_ITEM.FIELDS.CREATE_EDIT_COMPANY_DASHBOARDS]:getSectionExists(userAllowedMenuLinks, MENU_ITEM.FIELDS.CREATE_EDIT_COMPANY_DASHBOARDS)}} 
                        manageUserDashboardsAccess={{[MENU_ITEM.FIELDS.CREATE_EDIT_USER_DASHBOARDS]:getSectionExists(userAllowedMenuLinks, MENU_ITEM.FIELDS.CREATE_EDIT_USER_DASHBOARDS)}}
                        onSelectDashBoard={onSelectDashBoard} manageColsAccess={manageColsAccess}
                        // setSelectedDashBoard={onSelectDashBoard} 
                        setAddWidgetdDialogOpen={reportRef.current?.setAddWidgetdDialogOpen} useNewReorderList={userSettings.useNewReorderList}
                    />
            </div>
          </div>
        );

        if(!userSettings.useNewQuickFilters) {
            headerElements.push(
                <div className='third_row_header' style={{ display: "flex", width: "100%" }}>
                    <div key={Math.random()} className={"noprint " + (displayFilter && ((filterFinalBasic?.length > 0 && filterFinalBasic !== "[]") || (filterFinal?.length > 0 && filterFinal !== "[]")) ? ("border-third-header ") : "") + "uk-display-block"}>
                        <AppliedFilters filterProps={filterFinalBasic} filterFinal={filterFinal} ref={appliedFiltersRef}
                            userAllowedVectors={userSettings.user?.user_allowed_vectors} basicFilterCountLimit={userSettings.basicFilterCountLimit}
                            handleCheckboxChange={handleBasicFilterCheckBoxChange} sectionsData={basicFilterEntities}
                            fetchData={filterOnBasicFilter} clearFilter={filterDialRef?.current?.clearFilter} vectors={vectorState.vectorOptions}
                            quadrantsDisabled={false}
                            psFilterDisabled={false}
                            parentMessage={""/*props.filterWarningMessage*/} saveFilter={filterDialRef?.current?.startSaveFilter}
                            clearBasicFilter={clearClick} clearFilterFromDialog={filterDialRef?.current?.clearFilter} forgetFilter={filterDialRef?.current?.handleOpenForgetFilterDialog}
                            className={isFilterDisabled ? "uk-disabled disabled" : ""} tooltip={"title:" + ("") + ";pos: top-left"} filterIsEditable={filterDialRef?.current?.state?.savedFilterData?.editable}
                        />
                    </div>
                </div>
            );
        }

        return headerElements;
    }

    const renderHeaderFirstRow = () => {
        return renderBreadcrumbsAndDescription(() => props?.goToLandingPage(), undefined, props.reportDescription, reportTitle)
    }

    const renderHeaderSecondRow = (isDropDownDisabled) => {
        return (
            <>
                {renderPeriodCalendar(periodsStatusState, handleElementChange, undefined, clientPeriodsState.allPeriods, toggleCalendar, isDropDownDisabled, scenarioState)}
                {renderButton(lang.modal.buttons.apply, lang.modal.buttons.apply.toLowerCase(), '', '', apply, !showGreyOverLay)}
            </>
        );
    }

    const renderHeaderThirdRow = (sectionsData, canSaveCompanyFilters) => {
        return (
            <>
                {userSettings?.useNewQuickFilters ? (
                        <QuickFilters
                            vectorOptions={vectorState.vectorOptions}
                            profitFormat={profitFormat}
                            userSettings={userSettings}
                            scenarioState={scenarioState}
                            filterFinal={filterFinal}
                            setQuickFilters={setQuickFilters}
                            switchBasicToAdvanced={switchBasicToAdvanced}
                            clearAdvancedFilters={clearAdvancedFilters}
                            filterDialRef={filterDialRef}
                        />)
                    : (
                        <FilterByDropdown
                            ref={filterByRef}
                            sectionsData={copyObjectValues(sectionsData.data)}
                            clearClick={clearClick}
                            isClearBtnDisabled={sectionsData.isClearBtnDisabled}
                            handleCheckboxChange={handleCheckBoxChange}
                            basicFilterCountLimit={userSettings.basicFilterCountLimit}
                            switchBasicToAdvanced={switchBasicToAdvanced}
                        />)
                }
                <FilterDialog
                    id={"secondary_header_" + "dashboards"}
                    ref={filterDialRef}
                    section_id={params.sectionId}
                    vectorOptions={vectorState.vectorOptions}
                    profitFormat={profitFormat}
                    mustHideFilter={false}
                    categoryToolkit={false}
                    onChangeFilter={onChangeFilter}
                    quadTierIsAllowed={true}
                    isTempScenario={false}
                    scenario_id={scenarioState.scenario}
                    mustFetchDatasets={false}
                    user={userSettings.user}
                    isDrilling={false}
                    fromOptions={datasetState.datasetOptions}
                    datasetOptions_id={datasetState.datasetOptions_id}
                    dataSet={datasetState.dataset}
                    useCookies={true}
                    parentMessage={""}
                    psFilterDisabled={false}
                    quadrantsDisabled={false}
                    quadrantDefault={false}
                    checkIfMonthsValid={checkIfMonthsValid}
                    comparisonScenarioId={undefined}
                    filterDisplayEditable={true}
                    psLinesOptions={psLinesOptions}
                    quadrantTierOptions={quadrantTierOptions}
                    dispatch={dispatch}
                    savedFilterDataState={filterDialRef?.current?.state?.savedFilterData}
                    isRedirectionFromStacks={false}
                    savedFilter={true}
                    fromSecondaryHeader={true}
                    filterInDashBoard={true}
                    useNewQuickFilters={userSettings.useNewQuickFilters}
                    filterFinal={filterFinal}
                    canSaveCompanyFilters={canSaveCompanyFilters}
                    // handleOverlayAndHeights={this.props.handleOverlayAndHeights}
                />
                <div className="uk-flex" style={{marginLeft: "auto"}}>
                    <ManageDashboards ref={manageDashboardsRef} key={HEADER_ELEMENT.ADD_DASHBOARDS}
                                      scenarioId={scenarioState.scenario} user={userSettings.user}
                                      userAllowedMenuLinks={userAllowedMenuLinks}
                                      manageCompanyDashboardsAccess={{[MENU_ITEM.FIELDS.CREATE_EDIT_COMPANY_DASHBOARDS]: getSectionExists(userAllowedMenuLinks, MENU_ITEM.FIELDS.CREATE_EDIT_COMPANY_DASHBOARDS)}}
                                      manageUserDashboardsAccess={{[MENU_ITEM.FIELDS.CREATE_EDIT_USER_DASHBOARDS]: getSectionExists(userAllowedMenuLinks, MENU_ITEM.FIELDS.CREATE_EDIT_USER_DASHBOARDS)}}
                                      onSelectDashBoard={onSelectDashBoard} manageColsAccess={manageColsAccess}
                        // setSelectedDashBoard={onSelectDashBoard}
                                      setAddWidgetdDialogOpen={reportRef.current?.setAddWidgetdDialogOpen}
                                      useNewReorderList={userSettings.useNewReorderList}
                    />
                </div>
            </>
        );
    }

    const getNewHeaderElements = () => {
        let sectionsData = getFilterBySectionsData();

        let isDropDownDisabled = false;
        let headerElements = [];
        let canSaveCompanyFilters = getSectionExists(userAllowedMenuLinks, SAVE_COMPANY_FILTERS);

        headerElements.push(
            <div style={{display: "flex", width: "100%", alignItems: "center"}}
                 className='new_header_menu first_header_row gap_between_buttons'>
                {renderHeaderFirstRow()}
            </div>
        );

        headerElements.push(
            <div
                className="new_header_menu second_header_row gap_between_buttons"
                style={{display: "flex", width: "100%", padding: "0.41667vw 0"}}
            >
                {renderHeaderSecondRow(isDropDownDisabled)}
            </div>
        );

        headerElements.push(
            <div className="new_header_menu third_row_header gap_between_buttons"
                 style={{display: "flex", width: "100%"}}>
                {renderHeaderThirdRow(sectionsData, canSaveCompanyFilters)}
            </div>
        );

        return headerElements;
    }


    const onSelectDashBoard=(dashboard, cloned)=> {
        setReportTitle(dashboard.label);
        setSelectedDashBoard(dashboard);
        setCloned(cloned);
        // reportRef.current?.setSelectedDashBoardInfo(dashboard, cloned);
        // reportRef.current?.setCloned(cloned);
    }


    const handleElementChange = (name, e, fromMount = false, dimIndex=0)=> {
        switch(name){
            case HEADER_ELEMENT.SELECTION_RANGE: {
                let selectedYears = e.filter(year => year.isSelected);
                let tempState = copyObjectValues(periodsStatusState);
                if (selectedYears.length > 0) {
                    let periods = getLocalStorageValueByParameter("periods")? JSON.parse(getLocalStorageValueByParameter("periods")): [];
                    let sortedSelectedYears = selectedYears.map(p => p.year + p.value).sort();
                    let startPeriod = sortedSelectedYears[0];
                    let endPeriod = sortedSelectedYears[sortedSelectedYears.length - 1];
                    let startDate = new Date(periods.filter(e=>e.value === startPeriod)[0].start_date);
                    let endDate = new Date(periods.filter(e=>e.value === endPeriod)[0].end_date);
                    tempState.nextCustomStartDate = startDate;
                    tempState.nextCustomEndDate = endDate;
                    tempState.customStartDate = startDate;
                    tempState.customEndDate = endDate;
                    tempState.startPeriod = startPeriod;
                    tempState.endPeriod = endPeriod;
                    
                    saveCookie("nextCustomStartDate", startPeriod);
                    saveCookie("nextCustomEndDate", endPeriod);
                    
                    setPeriodsStatusState(tempState);
                    setShowGreyOverLay(true);
                }
                break;
            }
            let headerChangedCounterState = headerChangedCounter;
            setHeaderChangedCounter(headerChangedCounterState+1);
        }
    }

    const setInfoDialogOpen = (isOpen, infoMsg) => {
        setOpenInfoDilog(isOpen);
        setInfoDialogMsg(infoMsg);
    }

    const runApplyValidations = () => {
        if (getPeriodDifference(getPeriodFromDate(periodsStatusState.customStartDate), getPeriodFromDate(periodsStatusState.customEndDate)) > 12) {
            setInfoDialogOpen(true, lang.not_allowed_periods_selection)
            return false;
        }
        return true;
      }

    const apply = ()=>{
        if (!runApplyValidations()) {
            return;
        }
        reportRef?.current?.go();
        setShowGreyOverLay(false);
    }

    const switchBasicToAdvanced = (filters) =>{
        if(filterByRef?.current){
            filterByRef.current.handleClose();
        }
        if(!filterFinal) {
          return;
        }
        
        let filterStr = filterFinal;
        let filter = typeof filterStr === "string" ? JSON.parse(filterStr) : (filterStr.filter && typeof filterStr.filter !== "function"? filterStr.filter : filterStr); 
        
        if(filters && userSettings?.useNewQuickFilters) {
            filter = filters
        }

        filter = addCorrespondingParenthesisToFilter(filter);
        if(filter.find(e=>e.isBasicFilter)){
            filterDialRef?.current?.onChangeSavedFilter(filter, false, true);
        }
    }

    const setQuickFilters = (filters) => {
        if(!reportTitle.startsWith(ALL_WIDGETS.FIELDS.ENTITY_STACKS) && !reportTitle.startsWith(lang.compare_stacks)) { 
          localStorage.removeItem(SAVED_FILTER_ID_COOKIE);
          localStorage.removeItem(CURRENT_FILTER_COOKIE);
          setLocalStorageValueByParameter(CURRENT_FILTER_COOKIE, JSON.stringify(filters));
        }
        
        setFilterFinal(JSON.stringify(filters));
        setExportQueryFilter(formatBasicFilter(filters, userSettings.user?.user_allowed_vectors));
        if (!showGreyOverLay) {
            setCallGo(true);
        }
    }
    
    const clearAdvancedFilters = (isClearAll) => {
        if(isClearAll) {
            filterDialRef?.current?.clearFilter();
            setFilterFinal([]);
        }
        if(!reportTitle.startsWith(ALL_WIDGETS.FIELDS.ENTITY_STACKS) && !reportTitle.startsWith(lang.compare_stacks)) {
            localStorage.removeItem(SAVED_FILTER_ID_COOKIE);
            localStorage.removeItem(CURRENT_FILTER_COOKIE);
            let exportQueryFilter = formatBasicFilter([], userSettings.user?.user_allowed_vectors);
            setExportQueryFilter(exportQueryFilter);
        }
    }

    const onChangeFilter=(filterStr, afterDrilling, applyFilter=false, isClear, isSwitchBasic, filterDisplayName)=> {
        let filter = typeof filterStr === "string" ? JSON.parse(filterStr): filterStr;
        // on changing advanced saved filter its name should be removed if cookie has no saved filter since we remove the saved filter from cookie when we do a change on it
        if (!filterDisplayName && (getLocalStorageValueByParameter(SAVED_FILTER_ID_COOKIE) === null || !getLocalStorageValueByParameter(SAVED_FILTER_ID_COOKIE) || getLocalStorageValueByParameter(SAVED_FILTER_ID_COOKIE) === "undefined")){
            filter.map((item)=>{item.filter_display_name = undefined });
        }

        if (isSwitchBasic) {
            filter.map(function(item){item.isBasicFilter = false});
        } 
        let tempState = {};
        
        tempState.filterFinal = typeof filter !== "string" ? JSON.stringify(filter) : filter;

        if(isClear){
            filter="[]";
            tempState.filterFinalBasic =  [];
        }else if (!filter.find(f => !f.isBasicFilter)){
            tempState.filterFinalBasic =  copyObjectValues(filter).filter(e=>e.field === "number"); //from selecting a saved basic filter
            let filterObjs = copyObjectValues(filter);
            const unique = [...new Set(filterObjs.map(item => item.vector))]; // [ 'A', 'B']
            for (var e in unique) {
                getFilterEntities({value:unique[e]}, vectorState, setBasicFilterEntities, basicFilterEntitiesState, profitFormat, userSettings, scenarioState, tempState.filterFinal , getCountRef, setCount, setCountRef)
            }
        }else if(applyFilter){ // from apply in advanced
            tempState.filterFinalBasic =  [];
        }
        tempState.tempFilter = typeof filter !== "string" ? JSON.stringify(filter) : filter;
        let currState = history.state;
        if(currState && currState.state){
            currState.state.outsideFilter = typeof filter !== "string" ? JSON.stringify(filter) : filter;
            currState.state.mainFilter = typeof filter !== "string" ? JSON.stringify(filter) : filter;
            currState.state.filter = typeof filter !== "string" ? JSON.stringify(filter) : filter;
            currState.state.filterFinal = typeof filter !== "string" ? JSON.stringify(filter) : filter;
            currState.state.originalMainFilter = typeof filter !== "string" ? JSON.stringify(filter) : filter;
            history.replaceState(currState,'');
        }

        if(!applyFilter) {
            let tempState2 = {tempFilter: filterStr};    //this state represents the filter displayed in the header but not necessarily applied on the data (pending apply())
            if(!isSwitchBasic){
                tempState2.filterFinalBasic = tempState.filterFinalBasic;
                setFilterFinalBasic(tempState2.filterFinalBasic);
            }
            setTempFilter(filterStr);
            return;
        }
        // _this.resetLimit();
        //check if filter contains quadrant or quadrant/tier filter
        tempState.isAdvancedFilterApplied = isClear? false : true;
        tempState.exportQueryFilter = filterStr === "[]" ? undefined : formatAdvancedFilter(JSON.parse(filterStr), userSettings.user?.user_allowed_vectors, datasetState.datasetOptions, vectorState.vectorOptions, filterDialRef?.current?.state?.psLinesOptions);
        setFilterFinal(tempState.filterFinal);
        setFilterFinalBasic(tempState.filterFinalBasic);
        setExportQueryFilter(tempState.exportQueryFilter);
        setIsAdvancedFilterApplied(tempState.isAdvancedFilterApplied);
        setTempFilter(tempState.tempFilter);
        if(isClear){
            filterStr="[]";
        }
        if(props.history && props.history.location && props.history.location.state){
            props.history.location.state.outsideFilter = filterStr;
            props.history.location.state.mainFilter = filterStr;
            props.history.location.state.filter = filterStr;
            props.history.location.state.filterFinal = filterStr;
            props.history.location.state.originalMainFilter = filterStr;
        }
        if(currState && currState.state){
            currState.state.outsideFilter = filterStr;
            currState.state.mainFilter = filterStr;
            currState.state.filter = filterStr;
            currState.state.filterFinal = filterStr;
            currState.state.originalMainFilter = filterStr;
            props.history.replaceState(currState,'');
        }
        if (!showGreyOverLay) {
            setCallGo(true);

        }
    }

    const setCountRef= (count) => {
        countRef.current = count;
    }

    const getCountRef = () => {
        return countRef.current;
    }

    const getFilterBySectionsData = () => {
        let isClearBtnDisabled = false;
        let filterFinalBasicState = filterFinalBasic || [];
        let vectors  = vectorState.vectorOptions?.filter(f => f.entityCount <= userSettings.filterVectorCountThreshold || f.isGroupTitle) // remove vectors with entityCount <= threshold and that are not group title;
        if (vectors && filterFinalBasicState) {
            filterFinalBasicState = typeof filterFinalBasicState === "object" ? filterFinalBasicState : JSON.parse(filterFinalBasicState);
            filterFinalBasicState = Array.isArray(filterFinalBasicState) ? filterFinalBasicState : filterFinalBasicState.filter;
            isClearBtnDisabled = filterFinalBasicState.length === 0;
            vectors = vectors.map((item) => ({
                ...item,
                checked: !item.isGroupTitle && filterFinalBasicState.some((e) => e.vector === item.value) // .some() checks if any of the element.vector === item.value
            }));
        }

        // Add suggested section if we have nextVectors and nextVectors' entity count < threshold
        let sectionsJson = [];
        let quarters = [];
        var startQuarter = datasetState.nextStartQuarter?.split("Q")[1];
        var endQuarter =  datasetState.nextEndQuarter?.split("Q")[1];
        var startYear =  datasetState.nextStartQuarter?.split("Q")[0];
        var endYear =  datasetState.nextEndQuarter?.split("Q")[0];
        quarters = generateQuarter(Number(startQuarter), startYear, Number(endQuarter), endYear, true);
        vectors =  vectors; // add isDisabled to vectors that are not generated

        sectionsJson.push({
            sectionTitle: lang.all,
            itemsList: vectors,
        })
        return {data: sectionsJson, isClearBtnDisabled: isClearBtnDisabled};
    }

    const handleCheckBoxChange=(item)=>{
        let filterFinalArr = filterFinal !== "[]" && typeof filterFinal === "string" ? JSON.parse(filterFinal) : filterFinal; // when filterFinal = ""[]"", remove the extra ""
        let isAdvancedFilterApplied = filterFinalArr && filterFinalArr !=="[]" && (typeof filterFinalArr === "object"? filterFinalArr.find(e=>!e.isBasicFilter): JSON.parse(filterFinalArr).find(e=>!e.isBasicFilter));
        if(isAdvancedFilterApplied){
            setBasicFilterItem(item);
            openFilterModal();
        }else{
            handleBasicFilterCheckBoxChange(item);
        }
    }

    const filterOnBasicFilter=(basicAppliedFilter)=>{
        let filters = copyObjectValues(basicAppliedFilter.filter(e=>e.entities.length));
        filters?.forEach(e=>{
            e.isBasicFilter = true;
            e.filter_display_name = undefined;
            e.entities = e.entities.map(ent=>{ent.value = ent.number; ent.label = ent.number; return ent})
        });
        let filtersByName = copyObjectValues(filters);
        filtersByName?.forEach(e=>{
            e.field = "name";
            e.entities = e.entities.map(ent=>{ent.value = ent.name; ent.label = ent.name; return ent})
            filters.push(e);
        });

        if(!reportTitle.startsWith(ALL_WIDGETS.FIELDS.ENTITY_STACKS) && !reportTitle.startsWith(lang.compare_stacks)) { 
            localStorage.removeItem(SAVED_FILTER_ID_COOKIE);
            localStorage.removeItem(CURRENT_FILTER_COOKIE);
            setLocalStorageValueByParameter(CURRENT_FILTER_COOKIE, JSON.stringify(filters));
        }
        let filterFinalBasic = copyObjectValues(basicAppliedFilter);//typeof _this.state.filterFinalBasic === "string" ? JSON.parse(_this.state.filterFinalBasic) : _this.state.filterFinalBasic;
        if(filterDialRef?.current) {
            filterDialRef?.current?.setState({
                savedFilterData: {}
            })
        }
       
        if(filterDialRef?.current?.savedFilterNameInput){
            filterDialRef.current.savedFilterNameInput.value = ""
        }
        filterFinalBasic?.forEach(e=>{
            e.filter_display_name = undefined;
        });

        filters = addCorrespondingParenthesisToFilter(filters);
        if(!reportTitle.startsWith(ALL_WIDGETS.FIELDS.ENTITY_STACKS) && !reportTitle.startsWith(lang.compare_stacks)) { 
            localStorage.removeItem(SAVED_FILTER_ID_COOKIE);
            localStorage.removeItem(CURRENT_FILTER_COOKIE);
            setLocalStorageValueByParameter(CURRENT_FILTER_COOKIE, JSON.stringify(filters));
        }
        setFilterFinal(JSON.stringify(filters));
        setFilterFinalBasic(filterFinalBasic)
        setExportQueryFilter(formatBasicFilter(filters, userSettings.user?.user_allowed_vectors));
        if (!showGreyOverLay) {
            setCallGo(true);
        }
    }

    const handleBasicFilterCheckBoxChange=(item,clear,entity,allEntities)=>{
        let tempState = {};
        let filter = typeof filterFinalBasic === "string" ? JSON.parse(filterFinalBasic) : filterFinalBasic;
        let initialFilter = copyObjectValues(filter);

        filter = filter || [];
        let removedLine = [];
        let isClear = false;
        let isUnCheck = false;
        if (entity && item) {
            for (let e in filter) {
                if (filter[e].vector === item.vector) {
                    if (entity.checked === true){
                        filter[e].entities.push(entity)
                    }else{
                        filter[e].entities = filter[e].entities.filter(f => f.value !== entity.value)
                    }
                }
            }
        } else if(item && allEntities){
            for (let e in filter) {
                if (filter[e].vector === item.vector) {
                    for(let j in allEntities){
                        filter[e].entities.push(allEntities[j]);
                    }
                }
            }
        }else if (item) {
            if(filter?.find(e=>e.vector === item.value)){
                removedLine = filter.find(e=>e.vector === item.value);
                filter = filter.filter(e=>e.vector !== item.value);
                delete basicFilterEntities[item.value]
                isUnCheck = true;
            }else{
                let selectedEmptyFilter = getNewEntityFilter(item.value, FILTER.VALUES.FIELD.NUMBER, [], FILTER.VALUES.FILTER_ROW_TYPE.FILTER, lang.ui_filter.dropdowns.and);
                selectedEmptyFilter.vectorLabel = item.label;
                filter?.push(selectedEmptyFilter);
            }
           
        } else if (clear) {
            filter = [];
            tempState.filterFinal = [];
            isClear = true;
            if(!reportTitle.startsWith(ALL_WIDGETS.FIELDS.ENTITY_STACKS) && !reportTitle.startsWith(lang.compare_stacks)) {
                localStorage.removeItem(SAVED_FILTER_ID_COOKIE);
                localStorage.removeItem(CURRENT_FILTER_COOKIE);
                tempState.exportQueryFilter = formatBasicFilter(filter, userSettings.user?.user_allowed_vectors);
                setExportQueryFilter(tempState.exportQueryFilter);
            }
        } 
        filter = addCorrespondingParenthesisToFilter(filter);
        if(isUnCheck && removedLine.entities.length>0){
            tempState.filterFinalBasic = filter;
            let copyFilter = copyObjectValues(filter);
            copyFilter.forEach(e=>{
                if(e.entities){
                    e.isBasicFilter = true;
                    e.entities = e.entities.map(ent=>{ent.value = ent.number; ent.label = ent.number; return ent})
                }
            });
            let filtersByName = copyObjectValues(copyFilter);
            filtersByName.forEach(e=>{
                e.field = "name";
                e.entities = e.entities.map(ent=>{ent.value = ent.name; ent.label = ent.name; return ent})
                copyFilter.push(e);
            });
            tempState.filterFinal = JSON.stringify(copyFilter);
            tempState.exportQueryFilter = formatBasicFilter(copyFilter, userSettings.user?.user_allowed_vectors)
        }
        filter.map((item)=>{item.filter_display_name = undefined });
        tempState.filterFinalBasic = isUnCheck? JSON.stringify(filter): filter;
        setFilterFinal(tempState.filterFinal);
        setFilterFinalBasic(tempState.filterFinalBasic);
        
        if(item && Object.keys(basicFilterEntities).length && basicFilterEntities[item.value]) { 
          basicFilterEntities[item.value].map(entity => entity.checked = false); 
        } 

        let countState = countRef.current;
        setCount(countState + 1);
        countRef.current = countState+1;
        // when we add the first dropdown, the initial filter will be 0 and the new filter will be 1.
        // when we remove the first dropdown, the filter will be 0 and the initial will be 1
        // this condition is to prevent resizing the tables when we add/remove more than 1 dropdown
        // if(isClear || (initialFilter.length <= 1 && filter.length === 0) || (initialFilter.length ===0 && filter.length <= 1)) {
        //     _this.handleOverlayAndHeights(filter.length > 0);
        // }
        if(filterDialRef?.current) {
            filterDialRef?.current?.setState({
                savedFilterData: {}
            })
        }

        if(filterDialRef?.current?.savedFilterNameInput){
            filterDialRef.current.savedFilterNameInput.value = ""
        }

        if(isUnCheck && removedLine.entities.length>0 && !showGreyOverLay){
            if(!reportTitle.startsWith(ALL_WIDGETS.FIELDS.ENTITY_STACKS) && !reportTitle.startsWith(lang.compare_stacks)) {
                localStorage.removeItem(SAVED_FILTER_ID_COOKIE);
                localStorage.removeItem(CURRENT_FILTER_COOKIE);
                setLocalStorageValueByParameter(CURRENT_FILTER_COOKIE, JSON.stringify(tempState.filterFinal));
            }
            let countstate = countRef.current;
            setCount(countstate+1);
            countRef.current = countState+1;
            setCallGo(true);

        }else if(!isClear){
            getFilterEntities(item, vectorState, setBasicFilterEntities, basicFilterEntitiesState, profitFormat, userSettings, scenarioState, tempState.filterFinal, getCountRef, setCount, setCountRef);
        }
    }

    const clearClick=()=>{
        handleBasicFilterCheckBoxChange(undefined, true);
        if(!showGreyOverLay) {
          setCallGo(true);
        }
    } 

    const openFilterModal = () => {
        setOpenFilterDialog(true);
    }

    const closeFilterModal = () => {
        setOpenFilterDialog(false);
    }

    const filterModalBody = () => {
        return (
            <h4>{lang.clear_advanced_filter}<br />{lang.switching_to_quick_filter}</h4>
        )
    }

    const handleBasicFilterAndClose = () =>{
        closeFilterModal();
        clearAdvancedFilter(basicFilterItem)
    }

    const clearAdvancedFilter = (basicFilterItem) => {
        filterDialRef?.current?.clearFilter();
        setFilterFinal([]);
        handleBasicFilterCheckBoxChange(basicFilterItem);
    }

    const saveDialogActions = () => {
        return (
            <>
                <Button
                    label={lang.modal.buttons.clear_filter}
                    variant={BUTTON_VARIANT.PRIMARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    onBtnClick={handleBasicFilterAndClose}
                />
                <Button
                    label={lang.modal.buttons.cancel}
                    variant={BUTTON_VARIANT.SECONDARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    aria-label="Close"
                    onBtnClick={closeFilterModal}
                />
            </>
        )
    }

    /**Limit Access Related Functions */
    const checkForLimitAccessMessage = (data, showModal)=> {
        let noAccessMessage = data.no_access_message;
        if(!!noAccessMessage && showModal && !props.checkedPsLines) {
            setLimitAccessDialogOpen(true, noAccessMessage);
        }
        return !!noAccessMessage;
    }

    const setLimitAccessDialogOpen = (isOpen, msg)=> {
        setLimitAccessMsg(msg)
        setOpenLimitAccessDialog(isOpen);
    }

    const checkIfAtLeastOnePeriodIsGeneratedFunc = () => {
      return checkIfAtLeastOnePeriodIsGenerated(periodsStatusState?.builtPeriods , 12);
    }
    /**
     * called from @Dashboards component on savewidget(add/edit) and deleteWidget
     * @param {*} dashBoardWidgets 
     */
    const updateDashboardWidgets = (dashBoardWidgets) => {
        manageDashboardsRef?.current?.updateDashboardWidgets(dashBoardWidgets);
    }

    let trackingData = window._pi_getTrackingDataObj();
    trackingData = trackingData === null ? "" : JSON.stringify(trackingData);
    let scenarioLabel = scenarioState?.scenarioObjects?.length > 0 ? scenarioState?.scenarioObjects[0].label : "";
    
    let showSkeleton = !(
      areObjectsNotEmpty([periodsStatusState, vectorState, scenarioState, datasetState]) &&
    //   profileHasColumnsAccess(manageColumnsProfile, manageColsAccess) &&
      headerElements.length > 0
    );
    let notEmpty = areObjectsNotEmpty([periodsStatusState, vectorState, scenarioState, datasetState]);

    useEffect(() => {
      updateWindowLoading(showSkeleton, "skeleton-loader");
    }, [showSkeleton])

    return (
      <Suspense fallback={<LoaderSkeleton />}>
        {showSkeleton && <LoaderSkeleton />}

        <Modal
          id={"check-limit-access-dialog"}
          openDialog={openLimitAccessDialog}
          closeClick={() => setLimitAccessDialogOpen(false, "")}
          bodyContent={() => messageDialogContent(limitAccessMsg)}
          dialogActions={() => (
            <Button
              label={lang.modal.buttons.ok}
              variant={BUTTON_VARIANT.PRIMARY}
              size={SIZES.DEFAULT}
              type={BUTTON_TYPE.DEFAULT}
              aria-label="Close"
              onBtnClick={() => setLimitAccessDialogOpen(false, "")}
            />
          )}
          size={DIALOG_SIZE.MEDIUM}
        />
        <Modal
          id={"header-info-dialog"}
          openDialog={openInfoDialog}
          bodyContent={() => <span className="uk-text-large">{infoDialogMsg}</span>}
          dialogActions={() => (
            <Button
              label={lang.modal.buttons.ok}
              variant={BUTTON_VARIANT.PRIMARY}
              size={SIZES.DEFAULT}
              type={BUTTON_TYPE.DEFAULT}
              onBtnClick={() => setInfoDialogOpen(false, "")}
            />
          )}
          closeClick={() => setInfoDialogOpen(false, "")}
          size={DIALOG_SIZE.MEDIUM}
        />
        <Modal
          id={"switch-basic-to-advanced-filter"}
          openDialog={openFilterDialog}
          bodyContent={filterModalBody}
          dialogActions={saveDialogActions}
          size={DIALOG_SIZE.MEDIUM}
          closeClick={closeFilterModal}
        />
        <div id="toastExport" className={!!error ? "toast toast-fail" : "toast toast-success"}>
          <div id="desc">
            <i
              className={"fa-lg fas uk-margin-small-right " + (!!error ? "fa-minus-circle uk-text-primary" : "fa-check-circle greenText")}
              aria-hidden="true"
            ></i>
            {message}
            <span></span>
          </div>
        </div>
        <iframe name="frame" id="frame_export" style={{ display: "none" }}></iframe>
        <div
          id={"main-component-container"}
          className={"main-component-container dashboards " + (showSkeleton ? "hidden" : "")}
          style={{ "--banner-height": isScenarioBannerVisible ? "6%" : 0 }}
        >
          <Loader newLoader />
          <div className={isScenarioBannerVisible ? "header-banner-div" : "header-banner-div-hidden"}>
            {isScenarioBannerVisible && <NewBanner
              bannerClassName={"header-banner scenario-banner"}
              labelClassName={"header-banner-label scenario-banner-label"}
              icon={<RoundInfoIcon className="info-banner-icon" />}
              label={lang.under_review_scenario_banner + " (" + scenarioLabel + ")"}
              body={
                <Button
                  id={"banner-dismiss-btn"}
                  label={lang.modal.buttons.dismiss}
                  variant={BUTTON_VARIANT.TERTIARY}
                  size={SIZES.DEFAULT}
                  type={BUTTON_TYPE.DEFAULT}
                  onBtnClick={() => setScenarioBannerVisible(false)}
                />
              }
            />}
          </div>

          <div className="main-report-header">
            <ReportHeader headerElements={headerElements} />
          </div>
          <div ref={manageColsOverlay} id="overlay-manage-columns" className="uk-hidden"></div>
          <div className="main_report">
            {headerElements.length > 0 && showGreyOverLay && <div className="overlay-div">{Object.keys(window._loading)?.length > 0 ? "" : lang.overlay_go_msg}</div>}
            <div id="main_report_container" className="main_report_container dashboards">
              {areObjectsNotEmpty([periodsStatusState, vectorState, scenarioState, datasetState, selectedDashboard]) &&
              headerElements.length > 0 ? (
                <Dashboards
                  ref={reportRef}
                  userSettingsState={userSettings}
                  periodsStatusState={periodsStatusState}
                  vectorState={vectorState}
                  PSViewsState={PSViewsState}
                  userAllowedMenuLinks={userAllowedMenuLinks}
                  filterFinal={filterFinal}
                  scenarioState={scenarioState}
                  checkForLimitAccessMessage={checkForLimitAccessMessage}
                  manageColumnsProfile={manageColumnsProfile}
                  datasetState={datasetState}
                  clientPeriodsState={clientPeriodsState}
                  profitFormat={profitFormat}
                  setDataLength={setDataLength}
                  manageColsAccess={manageColsAccess}
                  history={props.history}
                  setHeaderChanged={setHeaderChanged}
                  setShowGreyOverLay={setShowGreyOverLay}
                  showGreyOverLay={showGreyOverLay}
                  profiles={profiles}
                  section_id={params.sectionId}
                  selectedDashboard={selectedDashboard}
                  cloned = {cloned}
                  bridgeOptionsState={bridgeOptionsState}
                  setSwitchClientDisabled={props.setSwitchClientDisabled}
                  isMenuOpen={menuOpen}
                  filterDialRef={filterDialRef}
                  checkIfAtLeastOnePeriodIsGenerated={checkIfAtLeastOnePeriodIsGeneratedFunc}
                  useNewReorderList={userSettings.useNewReorderList}
                  updateDashboardWidgets= {updateDashboardWidgets}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </Suspense>
    );
};

export { DashboardWrapper };

