import React, { Component } from 'react';
import Popup from 'react-popup';

import { findIndexOfValue } from '../../class/array';
import { getReadableRuleText } from '../../class/common';
import { API_URL, BUTTON_TYPE, BUTTON_VARIANT, CALCULATED_COLUMNS, costtype, DROPDOWN_TYPE, FORMULA_ELEMENT_TYPE, RULE_ENTITIES, SCENARIO_STATUS, SIZES, VIEWPORT_WIDTH } from "../../class/constants.js";
import { convertPxToViewport } from '../../class/formatting';
import { reTranslateRule, translateRule } from '../../class/jqueries';
import { FETCH_METHOD, fetchAPI, FETCHAPI_PARAMS } from "../../class/networkUtils.js";
import {
    copyObjectValues,
    excludeOptions, findOptionByKey, getTranslationFile,
    tryParse
} from '../../class/utils';
import { SkeletonLoader } from '../../form/elements.js';
import { lang } from "../../language/messages_en.js";
import Button from "../../newComponents/Button";
import Dropdown from "../../newComponents/DropDown";
import Input from "../../newComponents/Input";

// import RuleRow from './RuleRow';
const MESSAGES = getTranslationFile();
const UIkit = require('uikit');
const $= require('jquery');
const COUNT = RULE_ENTITIES.COUNT;
const INVOICELINE = RULE_ENTITIES.INVOICE_LINE;
const COUNT_OPTION = [{label: COUNT, value: COUNT}];
const DEFAULT_INVOICELINE_CONDITIONS = [{label: "Count", value: "CountLines", type: "numeric"}];
const _AND = MESSAGES.ui_filter.dropdowns.buttons.AND;
const _OR = MESSAGES.ui_filter.dropdowns.buttons.OR;

const ORDERS = MESSAGES.order;
/**
 *
 * Rules Drop Down in configure screen
 * @author [Sarah Farjallah]
 */

class Rules extends Component {
    constructor(props) {
        super(props);
        var translatedRule = JSON.parse(reTranslateRule(this.props.rule, this.props.costCenter).replaceAll("'", "\"")).rule;
        this.state = {
            chosenVectors: [],
            vectorOptions: [],
            filterRowRefs: [],
            conditions: [],
            pslFormula:{formula:[]},
            rowNum: this.props.rule ? translatedRule.length : 1,
            vectors: this.sortVectors(this.props.vectors, translatedRule),
            showOverlay:true,
            vector:this.props.headerVectors[0],
            period:this.props.periods[0],
            order:ORDERS[0],
            logicalOperator: _AND,
            x:[],
            y:[],
            customData:[[]],
            data:[],
            ac_marks:{},
            psl_marks:{},
            sd_psl:0,
            tempFilter: undefined,
            tempFilterText: undefined,
            id:undefined,
            returnedTranslatedRule:this.props.clickedRow?.translatedRule,
            ruleEdited: false
        };
        this.displayRuleRows = this.displayRuleRows.bind(this);
        this.collectAndSaveRule = this.collectAndSaveRule.bind(this);
        this.resetModal = this.resetModal.bind(this);
        this.clearRule = this.clearRule.bind(this);
        this.deleteRuleRow = this.deleteRuleRow.bind(this);
        this.getRowsRule = this.getRowsRule.bind(this);
        this.incrementRows = this.incrementRows.bind(this);
        this.updateVectorOptions = this.updateVectorOptions.bind(this);
        this.setChosenVector = this.setChosenVector.bind(this);
        this.forceUpdateState = this.forceUpdateState.bind(this);
        this.setConditions = this.setConditions.bind(this);
        this.sortVectors = this.sortVectors.bind(this);
        this.switchOperator = this.switchOperator.bind(this);
       
        
        $("#rulesModal").remove();
    }

    setReturnedTranslatedRule=()=>{
        this.setState({
            returnedTranslatedRule: this.props.clickedRow?.translatedRule
        })
    }

    componentDidMount(){
        this.updateVectorOptions(false);
        $(document).on("click", this.hideConfigureDialog);
        const modalElement = $("#rulesModal");
        
        // Check if modal container is not already set to root
        // This is needed for React 17 since event listeners are bound to root element
        // Get current container ID from UIkit modal instance
        const currentContainer = UIkit.modal(modalElement[0])?.container?.id;

        // If container is not already set to root, update it
        if (currentContainer !== 'root') {
            UIkit.modal(modalElement[0], {
                container: "#root" // Set container to root element
            });
        }
    }

    componentDidUpdate(prevProps,prevState){
        let _this = this;
        if(this.props.transactionAttributeLeaves && (prevProps.transactionAttributeLeaves !== this.props.transactionAttributeLeaves || _this.state.pslFormula.formula.length === 0)){
            let transactionAttributeLeaves = this.props.transactionAttributeLeaves.filter(e=>e.returnName === "lines");
            let returnName = "";
            if(transactionAttributeLeaves.length > 0){
                returnName = "lines";
            } else {
                returnName = this.props.transactionAttributeLeaves.filter(e=>e.costType === costtype.attribute)[0].returnName;
            }
            this.setState({
                pslFormula:{formula:[{  display_name:_this.props.transactionAttributeLeaves.filter(e=>e.returnName === returnName)[0][CALCULATED_COLUMNS.FIELDS.COLUMN_FIELDS.TYPE] === FORMULA_ELEMENT_TYPE.COLUMN?
                    _this.props.transactionAttributeLeaves.filter(e=>e.returnName === returnName)[0][CALCULATED_COLUMNS.FIELDS.COLUMN_FIELDS.COLUMN_INDEX] || _this.props.transactionAttributeLeaves.filter(e=>e.returnName === returnName)[0][CALC_COLS.FIELDS.COLUMN_FIELDS.NAME]
                    : _this.props.transactionAttributeLeaves.filter(e=>e.returnName === returnName)[0][CALCULATED_COLUMNS.FIELDS.COLUMN_FIELDS.NAME],
                fomrula: FORMULA_ELEMENT_TYPE.PS_LINE_FORMULA,
                type: FORMULA_ELEMENT_TYPE.PSS_LINE, 
                value: _this.props.transactionAttributeLeaves? _this.props.transactionAttributeLeaves.indexOf(_this.props.transactionAttributeLeaves.filter(e=>e.returnName === returnName)[0])+1:""
                }]}
            })
        }
        
        if(prevState.id !== this.state.id) {
            this.updateVectorOptions(false);
        }
       
    }

    translateRuleWithAI=(rule, filter)=>{
        var query = { 
            action: "translateRule",
            rule: rule,
            filter: filter
        };
        let onThenCallback = (data) => {
            if(data.ERROR || data.result === 'ERROR') {
                Popup.alert('Something went wrong. Please try again');
            } else if(data) {             
                this.setState({
                    returnedTranslatedRule: data.translatedRule,
                    isTranslating: false
                })
            } 
        } 
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "translateRule",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: false,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.showCustomizedLoader]: true,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]:lang.observability.configure_build.configure_cost_function.screen_name,
            [FETCHAPI_PARAMS.requestDescription]:lang.observability.configure_build.configure_cost_function.requests_description.translate_rule_with_ai
            
        };
        fetchAPI(fetchOptions);
    }

    sortVectors(vectors, ruleArr) {
        vectors = copyObjectValues(vectors);
        let returnVectors = [];

        for (var i in vectors) {
            let vector = vectors[i]["value"];
            let ruleVectorIndex = ruleArr.findIndex(r => r["vector"] === vector);

            if (ruleVectorIndex > -1) {
                returnVectors[ruleVectorIndex] = vectors[i];
            }
        }

        //remove used vectors from list of all vectors
        returnVectors.forEach(v => {
            let index = findIndexOfValue(vectors, "value", v["value"]);
            if (index > -1) {
                vectors.splice(index, 1);
            }
        })

        //if there are still any remaining vectors, or rule hasnt been set, append them
        if (vectors.length) {
            returnVectors = returnVectors.concat(vectors);
        }
        return returnVectors;
    }

    forceUpdateState() {
        this.forceUpdate()
    }

    displayRuleRows() {
        var temp = [];
        var obj = this;
        var rowsRule = this.getRowsRule();
        var parsedRule = this.props.rule && this.props.rule.length ? JSON.parse(reTranslateRule(this.props.rule, this.props.costCenter).replaceAll("'", "\"")).rule : [];
        var displayedRule = rowsRule.length ? rowsRule : parsedRule;
        if (displayedRule && displayedRule.length > this.state.rowNum) {
            this.state.rowNum = displayedRule.length;
        }

        if (this.state.rowNum < 1) {
            this.state.rowNum = 1;
        }
        for (var key = 0; key < this.state.rowNum; key++) {
            if(this.state.rowNum < obj.state.chosenVectors.length && obj.state.chosenVectors.length) {
                obj.state.chosenVectors.splice(this.state.rowNum,  obj.state.chosenVectors.length - this.state.rowNum)
            }
            let vector = displayedRule && displayedRule[key] ? displayedRule[key].vector : key === 0 ? INVOICELINE : this.state.vectorOptions[key][0].value;
            var fields = this.props.fields && this.props.fields.length > 0 ? this.props.fields : DEFAULT_INVOICELINE_CONDITIONS;
            let condition = displayedRule && displayedRule[key] && displayedRule[key].condition ? displayedRule[key].condition : key === 0 ? fields[0].value : COUNT;
            var conditions = key === 0 ? fields : obj.state.conditions[key] ? obj.state.conditions[key] : COUNT_OPTION;
            obj.state.filterRowRefs[key] = obj.state.filterRowRefs[key] || React.createRef();
            obj.state.chosenVectors[key] = vector;
            temp.unshift(
                <RuleRow deleteRuleRow={obj.deleteRuleRow} key={key} level={key} addRow={this.incrementRows} vector={vector}
                         conditions={conditions} costCenter={this.props.costCenter} condition={condition}
                         rowsNum={this.state.rowNum}
                         ref={obj.state.filterRowRefs[key]} vectors={this.state.vectorOptions[key]}
                         scenarioId={this.props.scenarioId}
                         user={this.props.user} tableName={this.state.tableName} timePeriod={this.props.timePeriod}
                         setChosenVector={this.setChosenVector}
                         forceUpdate={this.forceUpdateState} setConditions={this.setConditions}
                         isCostCenter={this.props.isCostCenter} filter={obj.props.filter} updateVectorOptions={this.updateVectorOptions}
                         resetReturnedTranslatedRule = {this.resetReturnedTranslatedRule}
                />
            );
        }
        return temp;
    }

    resetReturnedTranslatedRule=()=>{
        this.setState({returnedTranslatedRule:""})
    }

    setChosenVector(chosenVectorValue, level) {
        this.state.chosenVectors[level] = chosenVectorValue;
        this.resetReturnedTranslatedRule();
        return this.updateVectorOptions(true);
    }

    updateVectorOptions(doNotUpdate) {
        var vectorOptions = [];
        for (var i = 1; i < this.state.rowNum; i++) {  //i=0 is InvoiceLine
            //remove the vectors selected in other rows from the options of the current one
            var otherVectorValues = this.state.chosenVectors.filter((item, index) => index !== i);
            vectorOptions[i] = excludeOptions(this.state.vectors, otherVectorValues);

            if (this.state.chosenVectors[i] === undefined) {  //enters condition if adding a new row
                //remove the vector that has just been chosen from options of all the other rows,
                //so we let it call updateVectorOptions another time inside setChosenVector
                vectorOptions = this.setChosenVector(vectorOptions[i][0]["value"], i);
            }
        }

        if (doNotUpdate) {
            return vectorOptions;
        } else {
            this.setState({
                vectorOptions: vectorOptions
            });
        }
    }

    deleteRuleRow(rowNumber) {
        var obj = this;
        this.state.filterRowRefs.forEach(function (item, key) {
            let tempRowNumber = Number(key);//.replace(FILTER_ROW_REF_PREFIX, ""));
            if (key === 0) {
                return;
            }
            if (tempRowNumber === Number(rowNumber)) {
                obj.state.filterRowRefs.splice(key, 1);
                var rule = [];
                obj.state.filterRowRefs.forEach(row => {  //fill rule from remaining rows
                    row = row.current.rowValue;
                    rule.push({vector: row.vector, condition: row.condition});
                })
                obj.state.rowNum--;
                obj.state.chosenVectors.splice(key, 1);     //remove the vector occupated by the removed row from the reserved vectors list
                obj.state.vectorOptions.splice(key, 1);     //remove the vector options of the removed row
            }
        });
        this.resetReturnedTranslatedRule();
        this.updateVectorOptions();
    }

    getRowsRule() {
        var obj = this;
        var rowsFilter = [];
        this.state.filterRowRefs.forEach(function (item, key) {
            let row = obj.state.filterRowRefs[key].current.rowValue;
            rowsFilter.push({level: row.level, vector: row.vector, condition: row.condition});
        });

        return rowsFilter;
    }

    resetModal() {
        var obj = this;
        var parsedRule = obj.props.rule && obj.props.rule.length ? JSON.parse(reTranslateRule(this.props.rule, this.props.costCenter).replaceAll("'", "\"")).rule : [];

        if (parsedRule.length === 0) {
            this.state.filterRowRefs.forEach(function (item, key) {
                obj.state.filterRowRefs[key].current.onResetState();
            });
        } else {
            this.state.filterRowRefs.map(function (item, key) {
                //for each filter row, get the corresponding saved filter, if there is none, delete it
                let rowValue = obj.state.filterRowRefs[key].current.rowValue;
                let savedRule = parsedRule.filter(row => row.vector === rowValue.vector && row.condition === rowValue.condition);

                if (!savedRule || !savedRule.length) {
                    //remove the unsaved row
                    delete obj.state.filterRowRefs[key];
                }
            });
        }
        UIkit.modal("#rulesModal").hide();
        obj.setState({
            showOverlay:true,
            tempFilter: undefined,
            tempFilterText: "",
            returnedTranslatedRule: "",
            isTranslating: false,
            ruleEdited: false
        });

        obj.props.onCancelRule();
        // obj.props.discardFilter();
    }

    collectAndSaveRule() {
        var obj = this;
        var ruleFinal = [];
        //for all the children added, request the data of each one and save it
        obj.state.filterRowRefs.forEach(function (item, key) {
            if (obj.state.filterRowRefs[key].current.rowValue.vector !== "") {
                ruleFinal.push(obj.state.filterRowRefs[key].current.rowValue);
            }
        });
        UIkit.modal("#rulesModal").hide();
        obj.props.saveRuleAndFilter(obj.state.tempFilter,ruleFinal,obj.state.returnedTranslatedRule);
        this.setState({
            returnedTranslatedRule:"",
            isTranslating: false,
            ruleEdited: false
        })
    }

    clearRule() {
        this.state.filterRowRefs = [];
        this.collectAndSaveFilter();
    }

    /**
     * when pressing on add filter
     */
    onFilter=()=>{
        let _this = this;
        _this.props.showFilterPopup(_this.state.tempFilter);
    }


    incrementRows() {
        if (this.state.rowNum === this.props.vectors.length + 1) {   //+1 to account for the row taken by InvoiceLine
            Popup.alert(MESSAGES.configure_cf_no_more_vectors);
            return;
        }

        this.state.rowNum++;
        this.resetReturnedTranslatedRule();
        this.updateVectorOptions();
    }

    setConditions(conditions, level) {
        var arr = [];
        arr[level] = conditions;
        this.state.conditions = arr;
    }


    hideConfigureDialog(e){
        if (!$("#configure_dialog").is(e.target) && $("#configure_dialog").has(e.target).length === 0
        && !$(".thresh-div").is(e.target) && $(".thresh-div").has(e.target).length === 0
        && !$(e.target).hasClass("configure-option-no-hide") && $(e.target).children(".configure-option-no-hide").length === 0
        && $("#configure_dialog").is(":visible")) {
            $("#configure_dialog").hide();
            $(".thresh-div").removeClass("background-dark-grey");
        }
    }

    switchOperator() {
        var tempState= {};
        if(this.state.logicalOperator === _AND){
            tempState = {logicalOperator:_OR}
        }  else {
            tempState = {logicalOperator:_AND}
        }
        this.setState(tempState);
        
       
    }

    translateRule = (usedRuleText, filterText) =>{
        let _this = this;
        this.setState({
            isTranslating: true,
            ruleEdited: false
        },()=>{
            _this.translateRuleWithAI(usedRuleText, filterText);
        })
    }

    renderCostFunctionSection = (data) => {
        let rules = tryParse(data);

        if(!rules) { return <></>; }

        return (
            <div className="configure-build-rules cost-function-section-container">
              {rules.map((rule) => (
                <div className="inner-section-container content-section-div" key={rule.title}>
                    <div className="stage-title-container">
                        <span className="stage-title-label">{rule.title}</span>
                        <span className="stage-title">{rule.value}</span>
                    </div>

                    <div className="contents-container content-section-div">
                        {rule?.content?.map((section) => (
                            <div key={section.question} >
                            <span className="question-text">{section.question}</span>
                            <ul>
                                {section?.response?.map((answer) => 
                                    <li className="answer-text" key={answer}>
                                        {answer}
                                    </li>
                                )}
                            </ul>
                            </div>
                        ))}
                    </div>
                </div>
              ))}
            </div>
        );
    };
   
    render() {
        var obj = this;
        var ruleFinal = [];
        var value = "";
        let index = this.props.index;
        let config = this.props.clickedRow.config;
        let clickedRow = config[index];
        if (this.state.pslFormula.formula.length > 0) {
            this.state.pslFormula.formula.map(function(item){value += item.display_name;});
        }
        this.state.filterRowRefs.forEach(function (item, key) {
            if (obj.state.filterRowRefs[key].current.rowValue.vector !== "") {
                ruleFinal.push(obj.state.filterRowRefs[key].current.rowValue);
            }
        });
        var ruleText = translateRule(JSON.parse(JSON.stringify({rule: ruleFinal}).replaceAll("'", "\"")).rule, this.props.costCenter);
        var usedRuleText = getReadableRuleText(ruleText, this.props.headerVectors, this.props.metricFields).includes('undefined') ? "" : getReadableRuleText(ruleText, this.props.headerVectors,this.props.metricFields);
        var temp = this.displayRuleRows();
        let isScenarioNonEditable = this.props.scenarioStatus !== SCENARIO_STATUS.SANDBOX;
        
        let filterFilled = this.state.tempFilter !== undefined 
            ? this.state.tempFilter.length > 0 
            : (clickedRow.filter !== "" || (this.props.filter !== MESSAGES.no_description && this.props.filter!=="")) ;
        
        let filterTxt = (obj.state.tempFilterText !== undefined && obj.state.tempFilterText !== "" 
            ? obj.state.tempFilterText 
            : (obj.props.filter && obj.props.filter !== "" ? obj.props.filter 
            : (clickedRow.filter !== "" ? clickedRow.filter
            : MESSAGES.no_description)));

        let filterText = MESSAGES.filter + filterTxt;
        return (
            <div className="uk-modal-full" id="rulesModal" uk-modal="">
                 <div className="uk-modal-dialog define-rule-modal uk-background-default" id="ruleUKModal" style={{height:"100vh"}}>
                    <div id="engine_filter_scenario" className="uk-padding-container-small uk-flex uk-flex-between uk-flex-middle uk-border-bottom uk-background-default">
                        <span className="fs-20">{this.props.label}</span>
                        <div className="uk-flex uk-flex-between">
                            <Button  
                                label={MESSAGES.modal.buttons.cancel}
                                variant={BUTTON_VARIANT.SECONDARY}
                                size={SIZES.DEFAULT}
                                type={BUTTON_TYPE.DEFAULT}
                                onBtnClick={this.resetModal}
                            />
                            <div uk-tooltip={isScenarioNonEditable ? MESSAGES.non_editable_scenario:undefined}>
                                <Button 
                                    label={MESSAGES.modal.buttons.apply}
                                    variant={BUTTON_VARIANT.PRIMARY}
                                    size={SIZES.DEFAULT}
                                    type={BUTTON_TYPE.DEFAULT}
                                    className="uk-margin-default-left" 
                                    data-dismiss="modal"         
                                    disabled={isScenarioNonEditable /*|| !this.state.returnedTranslatedRule*/}                       
                                    onBtnClick={this.collectAndSaveRule}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row uk-padding-small">
                            <div className="col-md-4 uk-padding-remove-right">
                                <div className="container-full-height uk-border uk-border-rounded uk-background-default rule-definition-width">
                                    <div className="rule-definitions-header uk-flex uk-flex-middle uk-border-bottom row">
                                        <div className="col-md-3 uk-flex uk-flex-middle">
                                            <h4 className="uk-text-nowrap">{MESSAGES.rule_definition}</h4>
                                            <i className="fal fa-info-circle uk-margin-small-left uk-cursor-pointer"
                                            uk-tooltip={MESSAGES.configure_cost_function_rule_message}/>
                                        </div>
                                        <div className="col-md-5">
                                            <span className="red uk-display-block fs-12 uk-margin-default-left">{this.props.message && this.props.message.length ? this.props.message[0] : ""}</span>
                                        </div>
                                        <div className="uk-button-icon width-fit-cont col-md-2 add-tier" onClick={this.incrementRows}>
                                            <i className="far fa-plus-circle uk-margin-small-right rules-filter-icon" onClick={this.incrementRows}></i>
                                            <span>{MESSAGES.add_tier}</span>
                                        </div>
                                        <div className="uk-button-icon width-fit-cont col-md-2 uk-margin-small-left uk-margin-small-right" onClick={this.onFilter}>
                                            <i className={"uk-margin-small-right rules-filter-icon far"+(filterFilled? " fa-edit":" fa-plus-circle")} onClick={this.onFilter}></i>
                                            <span className={filterFilled?"red":""}>{filterFilled? MESSAGES.edit_filter : MESSAGES.add_filter }</span>
                                        </div>
                                    </div>
                                    <div className="rules-table uk-padding-small">
                                        <div>
                                        {temp}
                                        </div>
                                    </div>
                                    <div className="explain-with-ai-button-container">
                                        <Button
                                            label={"Translate"}
                                            variant={BUTTON_VARIANT.PRIMARY}
                                            size={SIZES.LARGE}
                                            type={BUTTON_TYPE.DEFAULT}
                                            onBtnClick={()=>{this.translateRule(usedRuleText, filterTxt)}}
                                        />
                                    </div>
                                    <div id="advanced-rule" className={"advanced-rule"}>
                                        <div className="uk-margin-xsmall-bottom">{MESSAGES.rule + usedRuleText}</div>
                                        <div>{filterText}</div>
                                    </div>
                                    {this.state.isTranslating?
                                        <div className="uk-margin-large-top uk-margin-small-left width-90-prcnt">
                                            <SkeletonLoader />
                                        </div>
                                    :
                                    this.state.returnedTranslatedRule && this.renderCostFunctionSection(this.state.returnedTranslatedRule) 
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>    
            </div>
        );
    }
}

const VECTOR_ATTR_NAME = "vector";
const CONDITION_ATTR_NAME = "condition";
const DEFAULT_INVOICELINE_VECTORS = [{label: INVOICELINE, value: INVOICELINE}];


class RuleRow extends Component {
    constructor(props) {
        super(props)
        this.state = {
            rowNumber: this.props.rowNumber
        };

        this.rowValue = {
            level: this.props.level,
            vector: this.props.vector,
            condition: this.props.condition,
            is_cost_center: this.props.is_cost_center
        }

        this.operators = this.props.operators;
        this.setValue = this.setValue.bind(this);
        this.getVectorMetrics = this.getVectorMetrics.bind(this);
        this.setDropDownsOptions = this.setDropDownsOptions.bind(this);

    }

    onResetState(deleteRow) {
        this.rowValue = {vector: '', condition: ''};

        if (deleteRow) {
            this.props.deleteRuleRow(this.props.level);
        }
    }

    getVectorMetrics(vector) {
        var obj = this;
        var dataBody = {
                action: "getScenarioVectorMetrics",
                scenario_id: this.props.scenarioId,
                vectorName: vector === undefined ? this.props.vectors[0].value : vector,
                costCenter: obj.props.isCostCenter && obj.props.isCostCenter === "ALL" ? "false" : "true"
        };
       
        let onThenCallback = (data) => {
            if (data.data !== null) {
                var metrics = [];
                metrics.push({label: "Count", value: "Count"})
                if (data.data.length){
                    metrics.push({isGroup:true, label: "Metrics", isDisabled:true, value: "metrics",type:'numeric'})
                }
                for (var e in data.data) {
                    metrics.push({
                        label: data.data[e].metric_display_name,
                        value: data.data[e].metric_name,
                        is_cost_center: data.data[e].is_cost_center,
                        vector: vector
                    });
                    if (data.data[e].metric_name === obj.rowValue.condition) {
                        this.rowValue.is_cost_center = data.data[e].is_cost_center; 
                    }
                }
                obj.setState({
                    conditions: obj.props.level === 0 ? DEFAULT_INVOICELINE_CONDITIONS : metrics,
                    vectors: obj.props.level === 0 ? DEFAULT_INVOICELINE_VECTORS : obj.state.vectors
                });
                obj.props.setConditions(obj.props.level === 0 ? DEFAULT_INVOICELINE_CONDITIONS : metrics, obj.props.level);
            }
        }

        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "getVectorMetrics",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: dataBody,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]:lang.observability.configure_build.configure_cost_function.screen_name,
            [FETCHAPI_PARAMS.requestDescription]:lang.observability.configure_build.configure_cost_function.requests_description.get_scenario_vector_metrics
        };

        fetchAPI(fetchOptions)
    }

    setValue(attrName, e) {
        var tempRowVal = copyObjectValues(this.rowValue);
        if (e !== null) {
            if (attrName === CONDITION_ATTR_NAME) {
                tempRowVal.condition = e.value;
                if (this.props.level !== 0) {
                    if (e.is_cost_center === true) {
                        tempRowVal.condition = e.value;
                    }
                    tempRowVal.is_cost_center = e.is_cost_center ? true : false;
                }
            } else if (attrName === VECTOR_ATTR_NAME) {
                tempRowVal.vector = e.value;
                tempRowVal.condition = findOptionByKey(this.state.conditions, this.state.conditions[0]).value;
                if (this.props.level !== 0) {
                    this.getVectorMetrics(e.value);
                }
                this.props.setChosenVector(e.value, this.props.level);
            }
            tempRowVal.level = this.props.level;
            this.rowValue = tempRowVal;
            this.forceUpdate();
            this.props.forceUpdate();
            this.props.updateVectorOptions(false);
            this.props.resetReturnedTranslatedRule();
            this.dateUpdated = Date.now();
        }
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props) !== JSON.stringify(prevProps))
           this.setDropDownsOptions()
        
        let vector = this.rowValue.vector;
        let condition = this.rowValue.condition;
        let level = this.props.level;
        let isCostCenter = this.rowValue.is_cost_center ? this.rowValue.is_cost_center : false;

        this.rowValue = {
            vector: vector,
            condition: condition,
            level: level,
            is_cost_center: isCostCenter
        }
    }

    setDropDownsOptions(nextProps) {
        var props = nextProps || this.props;
        var obj = this;
        this.rowValue.vector = props.vector;
        this.rowValue.condition = props.condition;

        if (props.level === 0) {
            var conditions = props.conditions;
            this.setState({
                vectors: DEFAULT_INVOICELINE_VECTORS,
                conditions: conditions,
            });
        } else {
            this.getVectorMetrics(props.vector);
            this.setState({
                vectors: props.vectors,
                conditions: obj.state.conditions && obj.state.conditions[1] && props.vector === obj.state.conditions[1]["vector"] && obj.state.conditions.length > props.conditions.length && obj.state.conditions[0]["value"] !== "CountLines" ? obj.state.conditions : props.conditions,
            });
        }
    }

    componentDidMount() {
        this.setDropDownsOptions();
        this.props.forceUpdate();
    }

    render() {
        const optionLabel = ({ label, isGroup }) => (
            <div title={label} className={(isGroup ? "option-group-header" : "")+ " option-padding"}>{label}</div>
        );
        return (
            <div className={"div-filter-container uk-display-inline"} key={this.props.level}>
                <div className="uk-flex uk-flex-middle">
                    {this.props.level !== this.props.rowsNum - 1 ? //if this row is NOT the last one, do add span
                        <span
                            style={{marginLeft:( (this.props.rowsNum === 1 ? 30 : 0 + (this.props.rowsNum - this.props.level - 1) * 45)/VIEWPORT_WIDTH)*100 + "vw"}}
                            className="corner-span"/>
                        : ""
                    }
                    
                    {this.props.level === 0 ?
                        <Input className="input-width-150 uk-input" type="text" value="Invoice Line" disabled={true} style={{marginTop: convertPxToViewport(25)}}/> 
                        : <div>
                            <label className="fs-14 uk-margin-xsmall-bottom">{MESSAGES.ui_filter.col_names.vector}</label>
                            <Dropdown
                                name="vector"
                                placeholder="vector"
                                onChange={(e) => this.setValue(VECTOR_ATTR_NAME, e)}
                                options={this.state.vectors}
                                className="input-width-150 input__dropdown"
                                value={findOptionByKey(this.state.vectors, this.rowValue.vector)}
                                id="ruleVectorDropDownList"
                                type={DROPDOWN_TYPE.INPUT}
                            />
                         </div>}
                </div>
                <div className="uk-flex uk-flex-bottom">
                    <div className="uk-margin-default-left">
                        <label className="fs-14 uk-margin-xsmall-bottom">{MESSAGES.calc_cols.condition}</label>
                        <Dropdown
                            name="condition"
                            placeholder="condition"
                            onChange={(e) => this.setValue(CONDITION_ATTR_NAME, e)}
                            options={this.state.conditions}
                            className="input-width-150 input__dropdown"
                            value={findOptionByKey(this.state.conditions, this.rowValue.condition || this.props.condition)}
                            id="ruleConditionDropDownList"
                            formatOptionLabel={optionLabel}
                            type={DROPDOWN_TYPE.INPUT}
                        />
                    </div>
                    {this.props.level !== 0 ?
                        <div onClick={() => this.onResetState(true)} className="uk-button-icon transparent-bg uk-margin-default-left">
                            <i className="fal fa-trash-alt fa-lg" />
                        </div>
                        :
                        <div className="width-50"></div>
                    }
                </div>

            </div>
        );
    }
}


export default Rules;