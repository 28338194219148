import React, { Component } from 'react';
import shortid from 'shortid';
import { linearizeHierarchy } from "../../class/array";
import { getClientSetting } from "../../class/commonRequests";
import {
    ALL_WIDGETS,
    API_URL,
    COLUMN_PROFILE,
    costtype,
    HEADER_ELEMENT,
    PROFILE_COLUMN,
    SCENARIOS,
    PS_MAPPING,
    BUTTON_VARIANT,
    SIZES,
    BUTTON_TYPE,
    DIALOG_SIZE,
    MENU_ITEM,
    DASHBOARDS,
    MANAGE_COLUMNS
} from "../../class/constants";
import { fetchAPI, FETCHAPI_PARAMS, FETCH_METHOD } from '../../class/networkUtils';
import { copyObjectValues, getSectionExists, getTranslationFile, tryParse } from '../../class/utils.js';
import StackConfiguration from "./StackConfiguration";
import StacksTabulator from "./StacksTabulator";
import SessionTimeout from "../../SessionTimeout";
import { connect } from "react-redux";
import Button from "../../newComponents/Button";
import Modal from "../../newComponents/Modal";
import "../../styles/profitStack.css";
import { updateProfitstackHierarchyByStack, updatePsViewsState } from '../../actions/scenariosActions.js';
import { getProfitStackHierarchy } from '../../templateLayout/api/api.js';
const headerElements = [HEADER_ELEMENT.STACKS_INFO,HEADER_ELEMENT.ADD_STACK]
const $ = require('jquery');
const _parentCostKey = "parentCostKey";
const _isInSearchResult = PROFILE_COLUMN.IS_IN_SEARCH_RESULT;
const _isChecked = PROFILE_COLUMN.IS_CHECKED;
const _isExpandable = PROFILE_COLUMN.IS_EXPANDABLE;
const showExpandFlag = "isExpandable";
const _level = "level";
const _children ="children";
const _checked = "checked";
const _costKey = "costKey";
const _indeterminate = "indeterminate"
const _customStackId = "custom_stack_id";
const _isExpanded = PROFILE_COLUMN.IS_EXPANDED;
const _visibilityOptions = COLUMN_PROFILE.VISIBILITY_OPTIONS;
const lang = getTranslationFile();
/** @author Bassem Arnaout 
 *  Landing page of Manage Stacks screen
 *  Component renders the main stacks tables and the Stack Configuration component 
 */
class ManageStacks extends Component {
    constructor(props){
        super(props);
        this.state = {
            profitFormat: ALL_WIDGETS.TITLES.PROFIT_LANDSCAPE.MANAGE_STACKS,
            addStack: false,
            editStack:false,
            tableData:[],
            compatibilityObj:[],
            openSaveDialog:false,
            force_visibility: undefined,
            incompatibleScenarios:[]
        }
        this.isMainReport = { isManageStacks: true }
        this.newStackTabulator = React.createRef();
        this.stackConfRef = React.createRef();
        this.getClientSetting = getClientSetting.bind(this);
        this.stackConfig = React.createRef();
        this.stacksRef = React.createRef();
        this.originalTabulatorData = "";
    }

    componentDidMount(){
        this.fetchPublishedStack();
        this.fetchStacks();
        this.fetchGetCompatibility();  
        getProfitStackHierarchy(0, this.props.scenarioState.scenario, this);
        this.fetchFormats();
    }


    // shouldComponentUpdate(nextProps, nextState) {
    //     if (JSON.stringify(this.props) !== JSON.stringify(nextProps) || JSON.stringify(nextState) !== JSON.stringify(this.state)) {
    //         return true;
    //     }
    //     return false;
    // }

    /**
     * function calls vaidate stack and based on the result it disables or enables the save button
     */
    toggleSaveButton = () => {
        let _this = this;
        let isChanged = false;
        if(_this.stackConfig.current != null) {
            isChanged = _this.stackConfig.current.validateStack();
        }

        this.props.toggleSaveButton(isChanged);
    }

    fetchStacks() {
        const _this = this;
        var query = {
            action: "fetchStacks"
        }
        let onThenCallback = (data)=>{
            if (data && data.data) {
                _this.setState({
                    stacks:data.data,
                    columns:data.columns,
                    id:shortid.generate()
                },() => {
                    let user_stacks = this.state.stacks.filter(e=>e[COLUMN_PROFILE.VISIBILITY] === COLUMN_PROFILE.VISIBILITY_OPTIONS.USER).length;
                    let company_stacks = this.state.stacks.filter(e=>e[COLUMN_PROFILE.VISIBILITY] === COLUMN_PROFILE.VISIBILITY_OPTIONS.COMPANY).length;
                    this.props.setUserStacks(user_stacks);
                    this.props.setCompanyStacks(company_stacks);
                    _this.stacksRef?.current?.replaceData();
                })
            }  
        }
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "fetchStacks",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: true,
            [FETCHAPI_PARAMS.path]: API_URL.MANAGE_STACKS,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]: lang.observability.output.manage_stacks.screen_name,
			[FETCHAPI_PARAMS.requestDescription]: lang.observability.output.manage_stacks.request_description.stacks,
        }
        fetchAPI(fetchOptions);
    }

    fetchGetCompatibility=(fromSave, returnNames, fromBack, callback)=> {
        const _this = this;
        var query = {
            action: "getCompatibility",
            fromSave: fromSave,
            returnNames: returnNames
        }
        let onThenCallback = (data) => {
            if (data && data.data) {
                if (fromSave || fromBack) {
                    if (data.data.length == 0) {
                        _this.setState({
                            incompatibleScenarios: ""
                        }, () => {
                            if (!fromBack) {
                                _this.saveStacks();
                            } else if (callback && typeof callback === "function") {
                                callback();
                            }
                        })
                    } else {
                        let scenarios = [];
                        data.data.map(sc => {
                            let tempNumber = sc.invalid_scenarios_numbers;
                            let tempName = sc.invalid_scenarios;
                            let tempStatus = sc.invalid_scenarios_status;
                            scenarios.push(tempNumber + " - " + tempName + " (" + tempStatus + ")")
                        });
                        _this.setState({
                            incompatibleScenarios: scenarios
                        }, () => {
                            if (!fromBack) {
                                _this.openStacksDialogSave();
                            } else if (callback && typeof callback === "function") {
                                callback();
                            }
                        })
                    }
                } else {
                    let invalidDetails = [];
                    let validDetails = [];
                    let invalidScenarios = [];
                    let validScenarios = [];
                    let compatibilityObj = [];
                    data.data.forEach(function (stack) {
                        invalidScenarios = [];
                        validScenarios = [];
                        invalidDetails = [];
                        validDetails = [];
                        let isPublishedIncompatible = false;
                        if (stack.invalid_scenarios_numbers) {
                            stack.invalid_scenarios_numbers.split(",").map(sc => {
                                let scenarioObj = _this.props.scenarioList.filter(scen => Number(scen[SCENARIOS.F.NUMBER]) === Number(sc))[0];
                                let tempNumber = !!scenarioObj ? scenarioObj[SCENARIOS.F.NUMBER] : "";
                                let tempName = !!scenarioObj ? scenarioObj[SCENARIOS.F.NAME] : "";
                                let tempStatus = !!scenarioObj ? scenarioObj[SCENARIOS.F.STATUS] : "";
                                if (tempName && !invalidScenarios.includes(tempName)) {
                                    if (tempStatus === lang.SCENARIOS.STATUS.PUBLISHED) {
                                        isPublishedIncompatible = true;
                                    }
                                    invalidScenarios.push(tempName);
                                    invalidDetails.push(tempNumber + " - " + tempName + " (" + tempStatus + ")")
                                }
                            });
                        }
                        if (stack.valid_scenarios_numbers) {
                            stack.valid_scenarios_numbers.split(",").map(sc => {
                                let scenarioObj = _this.props.scenarioList.filter(scen => Number(scen[SCENARIOS.F.NUMBER]) === Number(sc))[0];
                                let tempNumber = !!scenarioObj ? scenarioObj[SCENARIOS.F.NUMBER] : "";
                                let tempName = !!scenarioObj ? scenarioObj[SCENARIOS.F.NAME] : "";
                                let tempStatus = !!scenarioObj ? scenarioObj[SCENARIOS.F.STATUS] : "";
                                if (tempName && !validScenarios.includes(tempName)) {
                                    validScenarios.push(tempName);
                                    validDetails.push(tempNumber + " - " + tempName + " (" + tempStatus + ")")
                                }
                            });
                        }
                        let allScenariosDetails = [];
                        invalidDetails.forEach(function (scenario) {
                            let item = {};
                            item.scenario = scenario;
                            item.isValid = false;
                            allScenariosDetails.push(item);
                        });
                        validDetails.forEach(function (scenario) {
                            let item = {};
                            item.scenario = scenario;
                            item.isValid = true
                            allScenariosDetails.push(item);
                        });
                        compatibilityObj.push({
                            stack_id: stack.custom_stack_id,
                            invalidScenarios: invalidScenarios,
                            invalidDetails: invalidDetails,
                            validScenarios: validScenarios,
                            validDetails: validDetails,
                            isPublishedIncompatible: isPublishedIncompatible,
                            allScenariosDetails: allScenariosDetails
                        })
                    });
                    _this.setState({
                        compatibilityObj: compatibilityObj,
                    },()=>{
                        if (callback && typeof callback === "function") {
                            callback();
                        }
                    })
                }
            }
        }
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "getCompatibility",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: true,
            [FETCHAPI_PARAMS.path]: API_URL.MANAGE_STACKS,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]: lang.observability.output.manage_stacks.screen_name,
			[FETCHAPI_PARAMS.requestDescription]: lang.observability.output.manage_stacks.request_description.compatibility,
        }
        fetchAPI(fetchOptions);
    }

    /**
     * this function takes the data array and adds some needed parameters
     * to each element, like "is_expanded, is_checked, level, etc."
     * -- average ~ 8-14 ms
     * @param {*} data 
     * @param {*} level 
     */
    setupData(data, profileCols=undefined, level=0) {
        for (let index in data) {
            let element = data[index];

            if(profileCols) {
                //used when resetting changes
                element[_isChecked] = true;//profileCols.returnNames.includes(element[_returnName]);
            } else {
                //only update these keys when called from API callback
                element[_level] = level;
                element.column_level = level;
                element[_isExpandable] = element.is_expandable && !!element[_children] && element[_children].length > 0;
                element[_isExpanded] = false;   //all parents are collapsed by default
                element[_isInSearchResult] = true;      //show all elements by default
                element.isMoved = false;
                element.isApplicableformove = true;
                element[_indeterminate] = false;
                element[_checked] = false;

            }

            if(!!element[_children]) {
                this.setupData(element[_children], profileCols, level + 1);   //increase the level for children
            }
        }

        return data;
    }

    changeType = (data) => { //Parents should have type standard-group and not standard.
        for(let e in data){
            if(data[e].children && data[e].costtype === costtype.standard){
                data[e].costtype = costtype.standard;
                this.changeType(data[e].children);
            } else {
               continue;
            }
        }
        return data;
    }

    fetchPublishedStack() {
        const _this = this;
        var query = {
            action: "fetchPublishedStack"
        }
        let onThenCallback = (data)=>{
            if (data && data.data) {
                let dataModified = tryParse(data.data);
                dataModified = this.setupData(dataModified);
                dataModified = this.changeType(dataModified);
                dataModified = JSON.stringify(_this.removeChildrenAttribute(dataModified));
                let columnObj ={};
                columnObj.publishedStackColumns = data.publishedColumns;
                columnObj.stackColumns = data.stackColumns;
                _this.setState({
                    publishedStack:dataModified,
                    publishedStackColumns:columnObj,
                    originalPublishStackData:copyObjectValues(dataModified)
                 })
            }  
        }
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "fetchPublishedStack",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: true,
            [FETCHAPI_PARAMS.path]: API_URL.MANAGE_STACKS,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]: lang.observability.output.manage_stacks.screen_name,
			[FETCHAPI_PARAMS.requestDescription]: lang.observability.output.manage_stacks.request_description.published_stack,
        }

        fetchAPI(fetchOptions);
    }

    formatFormats(formats){
        formats.map(f=>{
            f.value = f[MANAGE_COLUMNS.MACHINE_NAME];
            f.label = f[MANAGE_COLUMNS.DISPLAY_NAME];
            f.unit_sample = f[MANAGE_COLUMNS.UNIT_SAMPLE]
            return f;
        });
        return formats;
    }

    fetchFormats() {
        const _this = this;
        var query = {
            action: "fetchFormats",
            scenario_id: _this.props.scenarioState?.scenario
        }
        let onThenCallback = (data)=>{
            if (data && data.formats) {
                let formats = this.formatFormats(data.formats);
                _this.setState({
                    formats: formats
                })
            }  
        }
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "fetchFormats",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: true,
            [FETCHAPI_PARAMS.path]: API_URL.MANAGE_COLUMNS,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]: lang.observability.output.manage_stacks.screen_name,
			[FETCHAPI_PARAMS.requestDescription]: lang.observability.output.manage_stacks.request_description.fetch_formats,
        }

        fetchAPI(fetchOptions);
    }

    removeChildrenAttribute(data){
        for(let e in data){
            if(!data[e][showExpandFlag] && data[e].children){
                delete data[e].children
            }
            else if(data[e][showExpandFlag] && data[e].children){
                this.removeChildrenAttribute(data[e].children);
            }
            else {
                continue;
            }
        }
        return data;
    }

    updateCostKeys = (data) => {
        for(let e in data){
            if(data[e].children){
                data[e][_costKey] =  data[e][_costKey].replace("Insert","")
                this.updateCostKeys(data[e].children);
            } else {
                data[e][_costKey] =  data[e][_costKey].replace("Insert","")
            }
        }
        return data;
    }

    updateOrder = (input) => { 
        var counter = 0;
        function rec(data) {
          data.forEach(function(e) {
            if (typeof e == 'object' && !Array.isArray(e))  {
              e.rank = ++counter;
              for (var p in e) {
                if (typeof e[p] == 'object' && p === _children){
                    rec(e[p]);
                } 
              }
            } 
          })
        }
        rec(input);
    }

    fetchStackInfo = (custom_stack_id) => {
        let _this = this; 
        let query = {
            action:"editStack",
            custom_stack_id:custom_stack_id
        }
        let onThenCallback = (data) => {
                if(data) {
                    let editedProfitStackFields = tryParse(data.data.profitstackfields);
                    let stackInfo = data.data.stack_info;
                    _this.setState({
                        editedProfitStackFields:editedProfitStackFields,
                        editedStackInfo:stackInfo,
                        originalProfitStackFields:copyObjectValues(editedProfitStackFields),
                        originalStackInfo:copyObjectValues(stackInfo),
                        custom_stack_id:custom_stack_id,
                        publishedStack:_this.state.originalPublishStackData
                    })
                }
        }
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "fetchStackInfo",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: true,
            [FETCHAPI_PARAMS.path]: API_URL.MANAGE_STACKS,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]: lang.observability.output.manage_stacks.screen_name,
			[FETCHAPI_PARAMS.requestDescription]: lang.observability.output.manage_stacks.request_description.stack_info,
            
        }
        fetchAPI(fetchOptions);
    }

    onBackValidation = (fromRender) => {
        let _this = this;
        if(!fromRender) {
            _this.stacksRef.current?.replaceData();

            _this.setState({
                editedProfitStackFields:undefined,
                editedStackInfo:undefined,
                // originalStackInfo:undefined,
                // custom_stack_id:undefined,
            },()=>{
                if (_this.stackConfig && _this.stackConfig.current !== null) {
                    return _this.stackConfig.current.onBackValidation(fromRender);
                }
            })
        }else{
            if (_this.stackConfig && _this.stackConfig.current !== null) {
                return _this.stackConfig.current.onBackValidation(fromRender);
            }
        }
        return;
    }

    onResetStacksClick = () => {
        let _this = this;
        if (_this.stackConfig && _this.stackConfig.current !== null) {
            return _this.stackConfig.current.onResetStacksClick();
        }
        return;
    }

    checkCompatibilityBeforeSave = (fromBack, callback)=> {
        let _this = this;
        let data = linearizeHierarchy(_this.newStackTabulator.current.getData(), _children);
        let returnNameObj = []
        data.map(function(item){
            if (item.costtype !== costtype.calculated && !item.returnName.endsWith(PS_MAPPING.FIELDS.CREATED_GROUP_SUFFIX) && !item[showExpandFlag]){
                returnNameObj.push({return_name:item.returnName})
            }
        })
        _this.fetchGetCompatibility(true, returnNameObj, fromBack, callback);
    }

    setOriginalTabulatorData = (data)=>{
        this.originalTabulatorData = data;
    }

    saveStacks = (callback)=> {
        let _this = this;
        if(!_this.state.currentManageStack || _this.newStackTabulator.current.getData().length === 0){
            return;
        }
        // _this.props.dispatch(updatePsViews([], undefined, true));
        // _this.props.dispatch(updateProfitstackHierarchyByStack([], undefined, true));
        let newStackData = _this.newStackTabulator.current.getData();
        newStackData = this.updateCostKeys(newStackData);
        this.updateOrder(newStackData);
        let manageStack = _this.state.currentManageStack;
        var query = {
            action: "saveStack",
            stack: JSON.stringify(manageStack),
            stackGroups:JSON.stringify(newStackData),
            isAddStack:_this.props.stackStatus.addStack,
        }
        if(this.props.stackStatus.editStack){
            query.custom_stack_id = _this.state.custom_stack_id;
            query.isTreeChanged = JSON.stringify(_this.newStackTabulator.current.getData()) !== JSON.stringify(_this.originalTabulatorData)
        }
        let onThenCallback = (data) => {
            if(data.success) {
                _this.setState({
                    message : lang.manage_stacks.stack_saved_successfully,
                    isError : false,
                    addStack: false,
                    editStack:false,
                    editedProfitStackFields:[],
                    editedStackInfo:{},
                    currentManageStack:{},
                    reportTitle: ALL_WIDGETS.TITLES.PROFIT_LANDSCAPE.MANAGE_STACKS
                },function(){
                    _this.launchToast();
                    _this.closeStacksDialogSave();
                    let cb = () => {
                        _this.fetchStacks();
                        _this.props.changeAddStack(false,false,false)
                    }
                    _this.fetchGetCompatibility(undefined, undefined, undefined, cb);
                    _this.props.dispatch(updatePsViewsState([], undefined, true));
                    _this.props.dispatch(updateProfitstackHierarchyByStack([], undefined, true));
                });
                if (callback && typeof callback === "function") {
                    callback();
                }
                _this.stacksRef?.current?.replaceData();
            } else{
                _this.setState({
                    message: "Error message",
                    isError: true
                },function(){
                    this.launchToast();
                });
            }
        }
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "saveStack",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: true,
            [FETCHAPI_PARAMS.path]: API_URL.MANAGE_STACKS,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]: lang.observability.output.manage_stacks.screen_name,
			[FETCHAPI_PARAMS.requestDescription]: lang.observability.output.manage_stacks.request_description.save,
        }
        fetchAPI(fetchOptions);
    }

    launchToast() {
        $("#toastAddStack").addClass("show");
        setTimeout(function(){
            $("#toastAddStack").removeClass("show");
        }, 4000);
    }

    setManageStack=(currentManageStack)=>{
        this.setState({
            currentManageStack:currentManageStack
        })
    }

    setTabulator = (tabulator) => {
        this.newStackTabulator.current = tabulator;
    }

    editStack = (cell) => {
        let rowData = cell.getRow().getData();
        let custom_stack_id = rowData.custom_stack_id;
        
        let tempState = {};
        tempState.editStack = true;
        tempState.addStack = false;
        this.setState(tempState,()=>{
            this.fetchStackInfo(custom_stack_id);
            this.props.toggleStackConfig(false,true,false);
        });
    }

    deleteStack = (custom_stack_id) => {
        let _this = this; 
        // _this.props.dispatch(updatePsViews([], undefined, true));
        // _this.props.dispatch(updateProfitstackHierarchyByStack([], undefined, true));
        let query = {
            action:"deleteStack",
            custom_stack_id:custom_stack_id
        }
        let onThenCallback = (data) => {
                if(data.data) {
                    let stacks = data.data;
                    _this.setState({
                        stacks:stacks,
                        openConfirmDeleteStackDialog: false,
                        message : lang.manage_stacks.stack_deleted_successfully,
                    },()=>{
                        let user_stacks = this.state.stacks.filter(e=>e[COLUMN_PROFILE.VISIBILITY] === COLUMN_PROFILE.VISIBILITY_OPTIONS.USER).length;
                        let company_stacks = this.state.stacks.filter(e=>e[COLUMN_PROFILE.VISIBILITY] === COLUMN_PROFILE.VISIBILITY_OPTIONS.COMPANY).length;
                        this.props.setUserStacks(user_stacks);
                        this.props.setCompanyStacks(company_stacks);
                        _this.launchToast();
                        _this.props.dispatch(updatePsViewsState([], undefined, true))
                    })
                }
        }
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "deleteStack",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: true,
            [FETCHAPI_PARAMS.path]: API_URL.MANAGE_STACKS,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]: lang.observability.output.manage_stacks.screen_name,
			[FETCHAPI_PARAMS.requestDescription]: lang.observability.output.manage_stacks.request_description.delete,
        }
        fetchAPI(fetchOptions);
    }

    startCloneStack = (cell)=>{
        let _this = this;
        let isLimitReachedObj =  _this.isLimitReached();
        let isVisibiltyAllowedObj =  _this.isVisibiltyAllowed();
        let isCompanyValid = !isLimitReachedObj.isCompanyLimitReached && isVisibiltyAllowedObj.isCompanyAllowed ? true : false;
        let isUserValid = !isLimitReachedObj.isUserLimitReached && isVisibiltyAllowedObj.isUserAllowed ? true : false;
        let rowData = cell.getRow().getData();
        let force_visibility = rowData.visibility;
        let openDialog = false;
        if (isVisibiltyAllowedObj.isCompanyAllowed && isVisibiltyAllowedObj.isUserAllowed) {
            if (isLimitReachedObj.isCompanyLimitReached) {
                force_visibility = _visibilityOptions.USER
                if (rowData.visibility.toLowerCase() === _visibilityOptions.COMPANY.toLowerCase()) {
                    openDialog = true;
                }
            }
            if (isLimitReachedObj.isUserLimitReached) {
                force_visibility = _visibilityOptions.COMPANY
                if (rowData.visibility.toLowerCase() === _visibilityOptions.USER.toLowerCase()) {
                    openDialog = true;
                }
            }
            if (!isLimitReachedObj.isUserLimitReached && !isLimitReachedObj.isCompanyLimitReached) {
                force_visibility = rowData.visibility
            }

        } else if (isVisibiltyAllowedObj.isCompanyAllowed && force_visibility === _visibilityOptions.USER) {
            force_visibility = _visibilityOptions.COMPANY
            openDialog = true;
        } else if (isVisibiltyAllowedObj.isUserAllowed && force_visibility === _visibilityOptions.COMPANY) {
            force_visibility = _visibilityOptions.USER
            openDialog = true;
        }
        _this.setState({
            force_visibility:force_visibility,
            cell:cell
        },function(){
            if(openDialog){
                _this.openStacksDialogSave();
            }else{
                _this.cloneStack();
            }
        })
    }

    cloneStack = () => {
        let _this = this;
        // _this.props.dispatch(updatePsViews([], undefined, true));
        // _this.props.dispatch(updateProfitstackHierarchyByStack([], undefined, true));
        let cell = _this.state.cell;
        let rowData = cell.getRow().getData();
        let custom_stack_id = rowData.custom_stack_id;
        let name = rowData.name;
        let visibility = rowData.visibility;
        let stacks = copyObjectValues(this.state.stacks)

        let count = 0;
        let splittedName = name.split("(");
        let nameToUse = splittedName[0];

        // If we have name=test(sd32d), we check if the value in () contains any letter.
        // If we have name=test(44)(ssf), we check if the index of splittedName < splittedName.length even if it contains only numbers to make sure we loop over all the splitted values
        for(var i=1; i < splittedName.length; i++) {
            if(/^(?=.*[a-zA-Z])/.test(splittedName[i]) || i < splittedName.length - 1) { // check if it contains any letter
                nameToUse += "(" + splittedName[i];
            } else {
                count = parseInt(splittedName[i].replace(/\)/g, ""))
                break;
            }
        }

        let duplicateNames = stacks.filter(e=> e.name.startsWith(nameToUse));
        let counter = 0;
        if(duplicateNames.length > 0){
            let greatestCustomStackIdObj = duplicateNames.reduce(function(prev, current) {
                return (prev[_customStackId] > current[_customStackId]) ? prev : current
            }) // take latest customStackId

            // get the count from the latest customStackId name and check if count === counter:
            // if we clone test(1) and we already have test(2) so count=1 and counter will be 2 so we increment counter
            let splittedGreatestFoundName = greatestCustomStackIdObj["name"].split("(");
            for(var i=1; i < splittedGreatestFoundName.length; i++) {
                if(!(/^(?=.*[a-zA-Z])/.test(splittedGreatestFoundName[i]) || i < splittedGreatestFoundName.length - 1)) { // check if it does not contains any letter
                    counter = parseInt(splittedGreatestFoundName[i].replace(/\)/g, ""))
                    break;
                }
            }
            // counter = count >= counter ? count : counter;
            // if(!isNaN(counter)){
            //     counter = Number(counter) + 1; // increment counter by 1
            // } else {
            //     counter = 1;
            // }

            let originalName = name.substr(0, name.indexOf('(')) !== "" ? name.substr(0, name.indexOf('(')).trim() : name;
            let greatestIdObj = duplicateNames.reduce((prev, current) =>
                (Number(prev[DASHBOARDS.NAME].replace(originalName,"").replace("(","").replace(")","").replace(/ /g,'')) > Number(current[DASHBOARDS.NAME].replace(originalName,"").replace("(","").replace(")","").replace(/ /g,''))) ? prev : current);
            counter = greatestIdObj[DASHBOARDS.NAME].replaceAll(/\)/g, "").split("(")[1];
            counter = !isNaN(counter) ? Number(counter) + 1 : 1;
        }  
        let query = {
            action:"cloneStack",
            custom_stack_id:custom_stack_id,
            name:nameToUse.trim() + (duplicateNames.length  > 0 ? " ("+(counter)+")" : "("+1+")"),
            force_visibility: _this.state.force_visibility || visibility
        }
        let onThenCallback = (data) => {
                if(data.data) {
                    let stacks = data.data;
                    _this.setState({
                        stacks:stacks,
                        message : lang.manage_stacks.stack_cloned_successfully,
                        force_visibility: undefined
                    },()=>{
                        let user_stacks = this.state.stacks.filter(e=>e[COLUMN_PROFILE.VISIBILITY] === COLUMN_PROFILE.VISIBILITY_OPTIONS.USER).length;
                        let company_stacks = this.state.stacks.filter(e=>e[COLUMN_PROFILE.VISIBILITY] === COLUMN_PROFILE.VISIBILITY_OPTIONS.COMPANY).length;
                        this.props.setUserStacks(user_stacks);
                        this.props.setCompanyStacks(company_stacks);
                        _this.fetchGetCompatibility();  
                        _this.launchToast();
                        _this.closeStacksDialogSave();
                        _this.props.dispatch(updatePsViewsState([], undefined, true))
                    })
                }
        }
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "cloneStack",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: true,
            [FETCHAPI_PARAMS.path]: API_URL.MANAGE_STACKS,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]: lang.observability.output.manage_stacks.screen_name,
			[FETCHAPI_PARAMS.requestDescription]: lang.observability.output.manage_stacks.request_description.clone,
            
        }
        fetchAPI(fetchOptions);
    }

    confirmDeleteStackContent = () => {
        let _this = this;
        let rowData = _this.state.selectedRowData;
        let name = rowData?.name;
        let created_by = rowData?.created_by;
        let visibility = rowData?.visibility;

        return (
            <div>
                <div className="uk-display-flex uk-flex-middle pi-warning-background uk-border-rounded uk-padding-xsmall">
                    <i className="fa-2x fal fa-exclamation-triangle uk-margin-default-right" />
                    <div className="fs-16">{lang.manage_stacks.confirm_delete_stack}</div>
                </div>
                <div key="b" className="uk-flex-center uk-padding-large-top fs-14">
                    <div className="uk-margin-default-bottom">
                        <span className="text-grey col-md-3">{lang.manage_stacks.titles.name}</span>
                        <span>{name}</span>
                    </div>
                    <div className="uk-margin-default-bottom">
                        <span className="text-grey col-md-3">{lang.manage_stacks.titles.created_by}</span>
                        <span>{created_by}</span>
                    </div>
                    <div className="uk-margin-default-bottom">
                        <span className="text-grey col-md-3">{lang.manage_stacks.titles.visibility}</span>
                        <span>
                            {visibility === _visibilityOptions.COMPANY ? 
                                <i title="By Company" className="fa-lg fal fa-users uk-margin-small-right"></i>
                                : visibility === _visibilityOptions.USER ?
                                <i title="You" className="fa-lg fal fa-user uk-margin-small-right"></i> 
                            :""}
                        </span>
                    </div>
                </div>
            </div>
        )
    }

    setConfirmDeleteStackDialogOpen = (isOpen, rowData) => {
        let _this = this;
        _this.setState({
            openConfirmDeleteStackDialog: isOpen,
            selectedRowData: rowData
        })
    }

    confirmDeleteStackDialogActions = () => {
        return (
            <>
                <Button 
                    label={lang.modal.buttons.delete}
                    variant={BUTTON_VARIANT.PRIMARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    onBtnClick={() => this.deleteStack(this.state.selectedRowData.custom_stack_id)}
                />
                <Button 
                    label={lang.modal.buttons.cancel}
                    variant={BUTTON_VARIANT.SECONDARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    onBtnClick={() => this.setConfirmDeleteStackDialogOpen(false, [])}
                />
            </>
        )
    }

    emptyCurrentStackState = () => {
        if(this.stackConfRef && this.stackConfRef.current!==null){
            this.stackConfRef.current.emptyCurrentManageStacks()
        }
    }

    isLimitReached = () => {
        let _this = this;
        let obj ={"isCompanyLimitReached":true, "isUserLimitReached":true}

        if(!_this.state.stacks) {
            return obj;
        }
        let user_stacks = _this.state.stacks.filter(e=>e[COLUMN_PROFILE.VISIBILITY] === COLUMN_PROFILE.VISIBILITY_OPTIONS.USER).length;
        let company_stacks = _this.state.stacks.filter(e=>e[COLUMN_PROFILE.VISIBILITY] === COLUMN_PROFILE.VISIBILITY_OPTIONS.COMPANY).length;

        if(company_stacks < _this.props.userSettingsState.companyStackLimit){
            obj.isCompanyLimitReached = false;
        }
        if(user_stacks  < _this.props.userSettingsState.userStackLimit){
            obj.isUserLimitReached = false;
        }
        return obj;
    }

    isVisibiltyAllowed = () => {
        let _this = this;
        let obj ={"isCompanyAllowed":false, "isUserAllowed":false}

        if(!_this.props.userAllowedMenuLinks) {
            return obj;
        }
        if(this.props.userAllowedMenuLinks){
            obj.isCompanyAllowed = getSectionExists(this.props.userAllowedMenuLinks, MENU_ITEM.FIELDS.CREATE_EDIT_COMPANY_STACKS) || getSectionExists(this.props.userAllowedMenuLinks, "manage_company_stacks");
            obj.isUserAllowed = getSectionExists(this.props.userAllowedMenuLinks, MENU_ITEM.FIELDS.MANAGE_STACKS) || getSectionExists(this.props.userAllowedMenuLinks, "manage_user_stacks");
        }
        this.props.setIsUserAllowed(obj.isUserAllowed);
        this.props.setIsCompanyAllowed(obj.isCompanyAllowed);


        return obj;
    }

    openStacksDialogSave = () => {
        let _this = this;
        _this.setState({
            openSaveDialog: true
        });
    }

    closeStacksDialogSave = () => {
        let _this = this;
        _this.setState({
            openSaveDialog: false,
            force_visibility: undefined
        });
    }

    manageStacksDialogBodySave = (getArray) => {
        let _this = this;
        if(this.state.force_visibility){
            return (
                <div className='uk-border-rounded uk-padding-xsmall text' >
                    <div className='fs-16'>{this.state.force_visibility === "company"? lang.manage_stacks.user_limit_reached: lang.manage_stacks.company_limit_reached}</div>
                    <div className='fs-16'>{lang.COMMON.sure_proceed}</div>
                </div>
            )
        }else{
            let incompatibleScenarios = _this.state.incompatibleScenarios;
            if (incompatibleScenarios) {
                if (getArray) {
                    return incompatibleScenarios;
                } else {
                    return (
                        <div className='uk-border-rounded uk-padding-xsmall text' >
                            <div className='fs-16'>{lang.manage_stacks.invalid_scenarios}</div>
                            <div className='fs-16 uk-margin-bottom'>{incompatibleScenarios.map(e => { return (<h5 key={e}>{e}</h5>)})}</div>
                            <div className='fs-16'>{lang.COMMON.sure_continue}</div>
                        </div>
                    )
                }
            }
        }
    }
    saveDialogActions = () => {
        return (
            <>
            {this.state.force_visibility?
                <>
                    <Button 
                        label={lang.modal.buttons.proceed}
                        variant={BUTTON_VARIANT.PRIMARY}
                        size={SIZES.DEFAULT}
                        type={BUTTON_TYPE.DEFAULT}
                        aria-label="Close"
                        className={"uk-padding-small-right"}
                        onBtnClick={this.cloneStack}
                    />
                    <Button 
                        label={lang.modal.buttons.cancel}
                        variant={BUTTON_VARIANT.SECONDARY}
                        size={SIZES.DEFAULT}
                        type={BUTTON_TYPE.DEFAULT}
                        aria-label="Close"
                        className="uk-padding-small-right"
                        onBtnClick={this.closeStacksDialogSave}
                    />
                </>
                :
                <>
                    <Button 
                        label={lang.modal.buttons.continue}
                        variant={BUTTON_VARIANT.PRIMARY}
                        size={SIZES.DEFAULT}
                        type={BUTTON_TYPE.DEFAULT}
                        aria-label="Close"
                        className={"uk-padding-small-right"}
                        onBtnClick={this.saveStacks}
                    />
                    <Button 
                        label={lang.modal.buttons.cancel}
                        variant={BUTTON_VARIANT.SECONDARY}
                        size={SIZES.DEFAULT}
                        type={BUTTON_TYPE.DEFAULT}
                        aria-label="Close"
                        className="uk-padding-small-right"
                        onBtnClick={this.closeStacksDialogSave}
                    />
                </>
            }
            </>
        )
    }

    getUnsavedChanges = () => {
        let _this = this;
        return _this.stackConfig.current.getStackIsChanged();
    }

    replaceManageStacksTableData = () => {
      this.stacksRef?.current?.replaceData();
    }

    render(){

        let isLimitReachedObj =  this.isLimitReached();
        let isVisibiltyAllowedObj =  this.isVisibiltyAllowed();
        let isCompanyAllowed = isVisibiltyAllowedObj.isCompanyAllowed;
        let isUserAllowed = isVisibiltyAllowedObj.isUserAllowed;
        let isUserLimitReached = isLimitReachedObj.isUserLimitReached;
        let isCompanyLimitReached = isLimitReachedObj.isCompanyLimitReached;

        let comp = <StacksTabulator ref={this.stacksRef} data = {this.state.stacks} columns = {this.state.columns} id={this.state.id} 
                        index={1} editStack={this.editStack} userAllowedMenuLinks={this.props.userAllowedMenuLinks}
                        totalScenarios={this.props.scenarioList ?this.props.scenarioList.length:0} compatibilityObj={this.state.compatibilityObj} 
                        setConfirmDeleteStackDialogOpen={this.setConfirmDeleteStackDialogOpen}
                        cloneStack={this.startCloneStack} history={this.props.history}
                        isUserLimitReached={isUserLimitReached} isCompanyLimitReached={isCompanyLimitReached} isLimitReached = {this.isLimitReached}
                        filterFinal={this.props.filterFinal} customStartDate={this.props.periodsStatusState.customStartDate} customEndDate={this.props.periodsStatusState.customEndDate} />
        return(
            <div>
                <Modal 
                    id={"confirm-delete-stack-dialog"}
                    openDialog={this.state.openConfirmDeleteStackDialog}
                    bodyContent={this.confirmDeleteStackContent}
                    dialogActions={this.confirmDeleteStackDialogActions}
                    title={lang.modal.buttons.delete_stack}
                    closeClick={() => this.setConfirmDeleteStackDialogOpen(false, [])}
                    size={DIALOG_SIZE.LARGE}
                />
                <div id="toastAddStack" className="toast toast-success">
                    <div id="desc"><i className={"fa-lg fas uk-margin-small-right " + (this.state.isError ? "fa-minus-circle uk-text-primary" : "fa-check-circle greenText")} aria-hidden="true"></i>{this.state.message}</div>
                </div>
                <div className={this.props.stackStatus.addStack || this.props.stackStatus.editStack? "uk-hidden": ""}>
                    {comp}
                </div>
                {this.props.stackStatus.addStack || this.props.stackStatus.editStack?
                    <StackConfiguration ref={this.stackConfig} setManageStack={this.setManageStack} data={this.state.publishedStack} columns={this.state.publishedStackColumns} isCompanyAllowed={isCompanyAllowed}
                        isUserAllowed={isUserAllowed} tableData={this.state.stacks} toggleSaveButton={this.toggleSaveButton} setTabulator={this.setTabulator} editedStackData = {this.state.editedProfitStackFields} 
                        editedStackInfo={this.state.editedStackInfo} editStack={this.props.stackStatus.editStack} changeAddStack={this.props.changeAddStack} saveStacks = {this.saveStacks} manageStacksDialogBodySave = {this.manageStacksDialogBodySave}
                        addStack={this.props.stackStatus.addStack} isCompanyLimitReached={isCompanyLimitReached} isUserLimitReached={isUserLimitReached} originalStackInfo={this.state.originalStackInfo} originalProfitStackFields = {this.state.originalProfitStackFields}
                        checkCompatibilityBeforeSave={this.checkCompatibilityBeforeSave} setOriginalTabulatorData={this.setOriginalTabulatorData} originalTabulatorData={this.originalTabulatorData}
                        scenarioState={this.props.scenarioState} formats={this.state.formats} replaceManageStacksTableData={this.replaceManageStacksTableData}/> 
                    : ""}

                    <Modal 
                        id={"save-stack-dialog"}
                        openDialog={this.state.openSaveDialog}
                        closeClick={this.closeStacksDialogSave}
                        dialogActions={this.saveDialogActions}
                        bodyContent={this.manageStacksDialogBodySave}
                        size={DIALOG_SIZE.LARGE}
                    />

            </div>
        )
    }
}




  export default ManageStacks;