import { Suspense, useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useParams } from 'react-router-dom/cjs/react-router-dom';

import { ReportHeader } from "../ReportHeader";
import { Loader } from "../../form/elements";
import NewBanner from "../../components/NewBanner";
import Button from "../../newComponents/Button";
import TableauHolder from "../../sections/tableauHolder/TableauHolder";

import "../styles/reportBody.css";

import { lang } from "../../language/messages_en";
import { ALL_WIDGETS, BUTTON_TYPE, BUTTON_VARIANT, CUSTOM_REPORTS, DIALOG_SIZE, FY_VALUES, HEADER_ELEMENT, SCENARIO_STATUS, SIZES } from "../../class/constants";

import {getLocalStorageValueByParameter, logout, updateWindowLoading} from "../../class/common";
import { capitalizeFirstLetter, copyObjectValues } from "../../class/utils";
import { useAuthenticated } from "../CustomHooks";
import {
    renderBreadcrumbsAndDescription,
    renderButton,
    renderPeriodCalendar,
    renderTitle
} from "../functions/componentFunctions";
import { updatePeriodsStatusState } from '../../actions/scenariosActions';
import { getActualYear, getPeriodDifference, getPeriodFromDate, getPeriodTextFromMonth } from "../../class/date";
import { getClientPeriods, getPeriodsStatus } from "../api/api";

import { ReactComponent as RoundInfoIcon } from "../../styles/images/menu-svgs/info.svg";
import { ReactComponent as CustomReportImage } from "../../styles/images/report-images/custom_report_img.svg";
import { saveCookie } from "../../class/jqueries";
import Modal from "../../newComponents/Modal";
import { LoaderSkeleton } from "../LoaderSkeleton";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { useFeatureFlagEnabled } from "posthog-js/react";


/**
 * @author Ralph Bejjani
 * @description component renders title,period calendar and apply button based on the customReport if it has period in its parameters when defined in ManageCustomReports screen with  {@link TableauHolder} or {@link CustomReportImage} component , calls isAuthenticated hook to check if user has authentication view the screen
 * @param {*} props 
 * @returns {@link ReportHeader}, {@link TableauHolder} if isTableau from props is true and {@link CustomReport} if props.isCustomReport is false
 */
const CustomReportsWrapper = (props) => {
  const userAllowedMenuLinks = props.userAllowedMenuLinks;
  const userSettings = props.userSettings;
  const propsScenarioState = props.scenarioState;
  const url = props.url;
  const key = props.location.key;

  /** References & react utility */
  const dispatch = useDispatch();
  const params = useParams();
  const reportRef = useRef();


  /**Custom Hooks */
  const isAuthenticated = useAuthenticated(userAllowedMenuLinks);

  /** State */

  /** Header Related States */
  const [headerElements, setHeaderElements] = useState([]);
  const [profitFormat, setProfitFormat] = useState(lang.profit_stack_line_definitions.profit_stack_line_definitions_title);
  const [reportTitle, setReportTitle] = useState(capitalizeFirstLetter(props.customReport.menu_item_display_name));
  const [periodsStatusState, setPeriodsStatusState] = useState({});
  const [clientPeriodsState, setClientPeriodsState] = useState([]);
  const [headerChanged, setHeaderChanged] = useState(false);
  const [calendarVisible, showCalendar] = useState(false);
  const [headerChangedCounter, setHeaderChangedCounter] = useState(0); 
  const [openInfoDialog, setOpenInfoDilog] = useState();
  const [infoDialogMsg, setInfoDialogMsg] = useState();
  const [showGreyOverLay, setShowGreyOverLay] = useState(true);
  const useNewComponents = useFeatureFlagEnabled('use_new_components');



  const [isScenarioBannerVisible, setScenarioBannerVisible] = useState(false);
  const [isExpanded, setExpanded] = useState(false);

  /**on screen initialization if user entered url with no access to screen he gets logged out */
  useEffect(() => {
    if (!isAuthenticated) {
      logout();
    }
  }, [isAuthenticated]);


  useEffect(() => {
    if (Object.keys(periodsStatusState).length > 0 && Object.keys(propsScenarioState).length > 0 && Object.keys(clientPeriodsState).length > 0  && userSettings.user) {
      let headerElements = useNewComponents ? getNewHeaderElements() : getHeaderElements();
      setHeaderElements(headerElements);
    }
  }, [propsScenarioState, isExpanded, calendarVisible, clientPeriodsState, periodsStatusState]);

  useEffect(()=>{
    if (propsScenarioState) {
      //when changing scenario, clear all states so that it won't run useEffect when new states are still not updated
        setPeriodsStatusState({});
        setScenarioBannerVisible(propsScenarioState?.scenarioStatus === SCENARIO_STATUS.REVIEW);
        getClientPeriods(undefined, undefined, setClientPeriodsState, profitFormat, params, userSettings);
        getPeriodsStatus(propsScenarioState, dispatch, updatePeriodsStatusState, setPeriodsStatusState, props, profitFormat, userSettings, 12);

  }
},[propsScenarioState]);

  useEffect(()=>{
    if(!props.isTableau){
      setTimeout(() => {
        window.open(url, "_blank")    
      }, "1000");
    }
    if (Object.keys(periodsStatusState).length > 0){
      let headerElements = useNewComponents ? getNewHeaderElements() : getHeaderElements();
      setHeaderElements(headerElements);
    }
  },[props.isTableau, key])

  useEffect(() => {
    $(document).on("click", hideCalendar);
    return () => {
      $(document).off("click", hideCalendar);
    }
  }, []);

  /**Calendar Related functions */
  const toggleCalendar = () => {
    $("#selection-range-container").addClass("active-selection-range");
    $("#period_range_table").show();
  }

  const hideCalendar = (e) => {
    if ($(e.target).parents().length !== 0 && $(e.target).parents("button.calendar-quarter").length === 0
      && $(e.target).parents("#selection-range-container").length === 0 && $("#period_table_container #period_range_table").length > 0
      && $(e.target).parents("button.arrows-container-quarter").length === 0 &&
      !e.target.classList.contains("arrows-container-quarter") && !e.target.classList.contains("calendar-quarter")) {
      // showCalendar(false);
      $("#selection-range-container").removeClass("active-selection-range");
      $("#period_range_table").hide();
    }
  };

  const handleElementChange = (name, e, fromMount = false, dimIndex=0)=> {
    switch(name){
        case HEADER_ELEMENT.SELECTION_RANGE: {
            let selectedYears = e.filter(year => year.isSelected);
            let tempState = copyObjectValues(periodsStatusState);
            if (selectedYears.length > 0) {
                let periods = getLocalStorageValueByParameter("periods")? JSON.parse(getLocalStorageValueByParameter("periods")):"";
                let sortedSelectedYears = selectedYears.map(p => p.year + p.value).sort();
                let startPeriod = sortedSelectedYears[0];
                let endPeriod = sortedSelectedYears[sortedSelectedYears.length - 1];
                let startDate = new Date(periods.filter(e=>e.value === startPeriod)[0].start_date);
                let endDate = new Date(periods.filter(e=>e.value === endPeriod)[0].end_date);
                
                tempState.nextCustomStartDate = startDate;
                tempState.nextCustomEndDate = endDate;
                tempState.customStartDate = startDate;
                tempState.customEndDate = endDate;
                tempState.startPeriod = startPeriod;
                tempState.endPeriod = endPeriod;
                
                saveCookie("nextCustomStartDate", startPeriod);
                saveCookie("nextCustomEndDate", endPeriod);
                
                setPeriodsStatusState(tempState);
                setShowGreyOverLay(true);
            }
            break;
        }

        default: break;
    }
    let headerChangedCounterState = headerChangedCounter;
    setHeaderChangedCounter(headerChangedCounterState+1);
}

  const setInfoDialogOpen = (isOpen, infoMsg) => {
    setOpenInfoDilog(isOpen);
    setInfoDialogMsg(infoMsg);
  }

  const runApplyValidations = () => {
    if (getPeriodDifference(getPeriodFromDate(periodsStatusState.customStartDate), getPeriodFromDate(periodsStatusState.customEndDate)) > 12) {
      setInfoDialogOpen(true, lang.not_allowed_periods_selection)
      return false;
    }
    return true;
  }

  const apply = () => {
    if (!runApplyValidations()) {
      return;
    }
    reportRef?.current.go();
  }

  /** Header elements */
  const getHeaderElements = () => {
    let headerElements = []; 
    let isDropDownDisabled = false; 
    headerElements.push(
      <div style={{ display: "flex", width: "100%", alignItems: "center" }} className="first_header_row gap_between_buttons">
        {renderTitle(capitalizeFirstLetter(props.customReport.menu_item_display_name))}
        {(props.customReport?.allowed_params && JSON.parse(props.customReport?.allowed_params)?.period_selection) ?
        <>
            {renderPeriodCalendar(periodsStatusState, handleElementChange, undefined, clientPeriodsState.allPeriods, toggleCalendar, isDropDownDisabled, propsScenarioState)}
            {<div id="apply_div">
              {renderButton(lang.modal.buttons.apply, lang.modal.buttons.apply.toLowerCase(), '', '', apply, !showGreyOverLay)}
            </div>
            }
          </>
          :
          ""}
      </div>
    );

    return headerElements;
  };

    const renderHeaderFirstRow = () => {
        return renderBreadcrumbsAndDescription(() => props?.goToLandingPage(), undefined, props.reportDescription)
    }

    const renderHeaderSecondRow = (isDropDownDisabled) => {
        return (
            <>
                {renderPeriodCalendar(periodsStatusState, handleElementChange, undefined, clientPeriodsState.allPeriods, toggleCalendar, isDropDownDisabled, propsScenarioState)}
                {<div id="apply_div">
                    {renderButton(lang.modal.buttons.apply, lang.modal.buttons.apply.toLowerCase(), '', '', apply, !showGreyOverLay)}
                </div>
                }
            </>);
    }

    const getNewHeaderElements = () => {
        let headerElements = [];
        let isDropDownDisabled = false;
        headerElements.push(
            <div style={{display: "flex", width: "100%", alignItems: "center"}}
                 className="new_header_menu first_header_row gap_between_buttons">
                {renderHeaderFirstRow()}
            </div>
        );

        headerElements.push(
            <>
                {(props.customReport?.allowed_params && JSON.parse(props.customReport?.allowed_params)?.period_selection) ?

                    <div className={"new_header_menu second_header_row gap_between_buttons "}
                         style={{display: "flex", width: "100%", padding: "0.41667vw 0"}}>
                        {renderHeaderSecondRow(isDropDownDisabled)}
                    </div>
                    : ""}
            </>
        );

        return headerElements;
    };


  let trackingData = window._pi_getTrackingDataObj();
  trackingData = trackingData === null ? "" : JSON.stringify(trackingData);
  let scenarioLabel = propsScenarioState?.scenarioObjects?.length > 0 ? propsScenarioState?.scenarioObjects[0].label : "";

  let showSkeleton = headerElements.length === 0;

  useEffect(() => {
    updateWindowLoading(showSkeleton, "skeleton-loader");
  }, [showSkeleton])

  return (
    <>
      {showSkeleton && <LoaderSkeleton />}
      <Suspense fallback={<p id="loading">Loading...</p>}>
        <Modal
          id={"header-info-dialog"}
          openDialog={openInfoDialog}
          bodyContent={() => <span className="uk-text-large">{infoDialogMsg}</span>}
          dialogActions={() => (
            <Button
              label={lang.modal.buttons.ok}
              variant={BUTTON_VARIANT.PRIMARY}
              size={SIZES.DEFAULT}
              type={BUTTON_TYPE.DEFAULT}
              onBtnClick={() => setInfoDialogOpen(false, "")}
            />
          )}
          closeClick={() => setInfoDialogOpen(false, "")}
          size={DIALOG_SIZE.MEDIUM}
        />
        <div id={"main-component-container"} className={"main-component-container"} style={{ "--banner-height": isScenarioBannerVisible ? "6%" : 0 }}>
          <div className={isScenarioBannerVisible ? "header-banner-div" : "header-banner-div-hidden"}>
            <Loader newLoader />

            {isScenarioBannerVisible && <NewBanner
              bannerClassName={"header-banner scenario-banner"}
              labelClassName={"header-banner-label scenario-banner-label"}
              icon={<RoundInfoIcon className="info-banner-icon" />}
              label={lang.under_review_scenario_banner + " (" + scenarioLabel + ")"}
              body={
                <Button
                  id={"banner-dismiss-btn"}
                  label={lang.modal.buttons.dismiss}
                  variant={BUTTON_VARIANT.TERTIARY}
                  size={SIZES.DEFAULT}
                  type={BUTTON_TYPE.DEFAULT}
                  onBtnClick={() => setScenarioBannerVisible(false)}
                />
              }
            />}
          </div>
          <div className="main-report-header">
            <ReportHeader headerElements={headerElements} />
          </div>
          <div className="main_report">
            <div
              id="main_report_container"
              className={"main_report_container " + (showSkeleton ? "hidden" : "")}
              style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", overflow: "auto" }}
            >
              {!props.isTableau ? (
                <>
                  <div className="empty_custom_report_text">
                    <div>{CUSTOM_REPORTS.CUSTOM_REPORT_FIRST_TEXT}</div>
                    <br />
                    <div>{CUSTOM_REPORTS.CUSTOM_REPORT_SECOND_TEXT}</div>
                  </div>
                  <div>
                    <CustomReportImage />
                  </div>
                </>
              ) : (
                <>
                  <TableauHolder
                    ref={reportRef}
                    userAllowedMenuLinks={userAllowedMenuLinks}
                    history={props.history}
                    match={props.match}
                    url={props.url}
                    customReport={props.customReport}
                    periodsStatusState={periodsStatusState}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </Suspense>
    </>
  );
};

export { CustomReportsWrapper };

