import { Skeleton } from "@mui/material";

const costTooltipDataArray = ["table", "associated_profit_lines", "cost_function"];

const PSLPanelSkeleton = () => {
  return (
    <div className="psl-panel-skeleton-loader">
      <div className="psl-tooltip-section-container">
        <div className="psl-tooltip-section-main-text">
          <Skeleton variant="text" animation="wave" sx={{ bgcolor: "#d0cece" }} />
          <Skeleton variant="text" animation="wave" sx={{ bgcolor: "#d0cece" }} width={"50%"} />
        </div>
        <Skeleton variant="text" animation="wave" sx={{ bgcolor: "#d0cece", fontSize: "1vw", lineHeight: "140%" }} width={"20%"} />
        <div className="psl-tooltip-section-main-text">
          <Skeleton variant="text" animation="wave" sx={{ bgcolor: "#d0cece" }} />
          <Skeleton variant="text" animation="wave" sx={{ bgcolor: "#d0cece" }} width={"50%"} />
        </div>

        {costTooltipDataArray.map((item) => (
          <div key={item} className="psl-tooltip-section-wrapper">
            {item === "table" && (
              <div className="psl-tooltip-section">
                <div className="psl-tooltip-content">
                  <div className="psl-tooltip-content-title" style={{ display: "flex", justifyContent: "space-between" }}>
                    <Skeleton variant="text" animation="wave" sx={{ bgcolor: "#d0cece", fontSize: "1vw", lineHeight: "140%" }} width={"20%"} />
                    <Skeleton variant="text" animation="wave" sx={{ bgcolor: "#d0cece", fontSize: "1vw", lineHeight: "140%" }} width={"20%"} />
                  </div>
                  <Skeleton variant="rounded" animation="wave" sx={{ bgcolor: "#d0cece" }} height={"20vw"} />
                </div>
              </div>
            )}
            {item === "cost_function" && (
              <>
                <Skeleton variant="text" animation="wave" sx={{ bgcolor: "#d0cece", fontSize: "1vw", lineHeight: "140%" }} width={"20%"} />
                <Skeleton variant="text" animation="wave" sx={{ bgcolor: "#d0cece", marginTop: "-1.2vw"}} />
                <div className="psl-tooltip-section">
                  <div className="psl-tooltip-content">
                    <Skeleton variant="text" animation="wave" sx={{ bgcolor: "#d0cece", fontSize: "1vw", lineHeight: "120%" }} width={"30%"} />
                    <div style={{ display: "flex", gap: "0.72917vw", flexDirection: "column" }}>
                      <Skeleton variant="text" animation="wave" sx={{ bgcolor: "#d0cece" }} width={"50%"} />
                      <Skeleton variant="text" animation="wave" sx={{ bgcolor: "#d0cece" }} width={"50%"} />
                      <Skeleton variant="text" animation="wave" sx={{ bgcolor: "#d0cece" }} width={"50%"} />
                      <Skeleton variant="text" animation="wave" sx={{ bgcolor: "#d0cece" }} width={"50%"} />
                    </div>
                  </div>
                </div>
              </>
            )}
            {item === "cost_function" && (
              <>
                <Skeleton variant="text" animation="wave" sx={{ bgcolor: "#d0cece", fontSize: "1vw", lineHeight: "140%" }} width={"20%"} />
                <Skeleton variant="text" animation="wave" sx={{ bgcolor: "#d0cece", marginTop: "-1.2vw" }} />
                <div className="psl-tooltip-section">
                  <div className="psl-tooltip-content">
                    <Skeleton variant="text" animation="wave" sx={{ bgcolor: "#d0cece", fontSize: "1vw", lineHeight: "120%" }} width={"30%"} />
                    <div style={{ display: "flex", gap: "0.72917vw", flexDirection: "column" }}>
                      <Skeleton variant="rounded" animation="wave" sx={{ bgcolor: "#d0cece" }} height={"10vw"} />
                      <Skeleton variant="rounded" animation="wave" sx={{ bgcolor: "#d0cece" }} height={"10vw"} />
                      <Skeleton variant="rounded" animation="wave" sx={{ bgcolor: "#d0cece" }} height={"10vw"} />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export { PSLPanelSkeleton };
