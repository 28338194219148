import React, { Suspense, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import ErrorBoundary from "../../ErrorBoundary";
import { updateVectorState } from "../../actions/scenariosActions";
import {
  ALL_WIDGETS,
  API_URL,
  BUTTON_TYPE,
  BUTTON_VARIANT,
  DIALOG_SIZE,
  DROPDOWN_TYPE,
  HEADER_ELEMENT,
  MAPPING_TITLES,
  PS_MAPPING,
  SCENARIO_STATUS,
  SIZES,
  STAGING_SECTIONS,
  period_calendar_type,
} from "../../class/constants";
import { FETCHAPI_PARAMS, FETCH_METHOD, fetchAPI } from "../../class/networkUtils";
import { lang as MESSAGES, lang } from "../../language/messages_en";
import Button from "../../newComponents/Button";
import StageData from "../../sections/StageData";
import { useAuthenticated, useCalendarToggle } from "../CustomHooks";
import { LoaderSkeleton } from "../LoaderSkeleton";
import { ReportHeader } from "../ReportHeader";
import { getVectors, logout } from "../api/api";
import { getClientPeriods, fetchUsedAttributes, getAllFileTypeColumns, getClientCostTerms, getEngineScenarios } from "../api/apiDataModeling";
import {
  renderBreadcrumbsAndDescription,
  renderCalendar,
  renderTitle
} from "../functions/componentFunctions";
import DropDown from "../../newComponents/DropDown";
import { capitaliseFirstLetterAfterChar, copyObjectValues, findOptionByKey } from "../../class/utils";
import Modal from "../../newComponents/Modal";
import { getLocalStorageValueByParameter } from "../../class/common";
import { generatePeriods, getPeriodDifference, monthDiff } from "../../class/date";
import { saveCookie } from "../../class/jqueries";
import NewBanner from "../../components/NewBanner";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { useFeatureFlagEnabled } from "posthog-js/react";

/**
 * @author Sarah Farjallah
 * @description This component holds the the header of the stage screen along with 4 tables for each   PRofit Stack, Vectors, Invoiceline and metrics
 * @param {*} props
 * @returns Report Header, {@link StageData}
 */
const StageWrapper = (props) => {
  const { userAllowedMenuLinks } = props;
  const { userSettings } = props;
  const { checkingForUnsavedChanges } = props;

  const profitFormat = ALL_WIDGETS.FIELDS.STAGE;

  /** References & react utility */
  const reportRef = useRef();
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  /** Custom Hooks */
  const isAuthenticated = useAuthenticated(userAllowedMenuLinks);
  const { toggleCalendar, hideCalendar } = useCalendarToggle();

  const [reportTitle, setReportTitle] = useState(ALL_WIDGETS.TITLES.DATA_MODELING.STAGE);
  const [clientPeriodsState, setClientPeriodsState] = useState([]);
  const [fileTypeColumns, setFileTypeColumns] = useState([]);

  const [vectorState, setVectorState] = useState({});
  const [scenariosState, setScenariosState] = useState([]);
  const [headerElements, setHeaderElements] = useState([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [divIdState, setDivIdState] = useState();
  const [destinationClientIdState, setDestinationClientIdState] = useState();

  const [isScenarioDisabled, setScenarioDisabled] = useState(false);
  const [clientCostTerms, setClientCostTerms] = useState([]);
  const [usedAttrs, setUsedAttrs] = useState([]);

  /** PS mapping states */
  const initialPeriodRangeState = { startPeriod: "", endPeriod: "", inputValue: "Choose Period" };
  const [selectedRange, setSelectedRange] = useState(initialPeriodRangeState); // sets the periods selected in PSMapping screen
  const [chosenRange, setChosenRange] = useState(initialPeriodRangeState); // sets the periods selected in PSMapping screen
  const [actionToken, setActionToken] = useState("-"); //this token is for profit stack mapping screen (displayed in header)
  const [showBanner, setShowBanner] = useState(false);
  const [bannerConfig, setBannerConfig] = useState();
  const [openDiscardChangesDialog, setOpenDiscardChangesDialog] = useState(false);
  const [shouldFetchNewData, setShouldFetchNewData] = useState(false); // this state is set true when we select periods and want to refetch data

  /** Dialogs States */
  const [isInfoDialogOpen, setInfoDialogOpen] = useState(false);
  const [infoDialogMsg, setInfoDialogMsg] = useState("");
  // const [isResetDialogOpen, setResetDialogOpen] = useState(false);
  const [isCheckUnsavedChangesDialogOpen, setCheckUnsavedChangesDialogOpen] = useState(false);

  const [report, setReport] = useState(profitFormat);
  const [selectedPeriod, setSelectedPeriod] = useState();
  const [isSaveDisabled, setSavedDisabled] = useState(false);
  const [isBackDisabled, setBackDisabled] = useState(false);
  const [openOverwriteScenarioConfirmDialog, setOverwriteScenarioConfirmDialogOpen] = useState(false);
  const [cloneScenarioDialogOpen, setCloneScenarioDialogOpen] = useState(false);
  const [confirmCancelDialogOpen, setConfirmCancelDialogOpen] = useState(false);
  const useNewComponents = useFeatureFlagEnabled('use_new_components');

  const fetchUsedAttributesFunc = (scenarioId = scenariosState.scenario.value, section = lang.observability.stage.key) => {
    fetchUsedAttributes(scenarioId, setUsedAttrs, profitFormat,section);
  };

  /**
   * @function useEffect()
   * @description on screen initialization if isAuthenticated is false user gets logged out
   * isAuthenticated is a boolean read from a custom hook named useAuthenticated
   **/
  useEffect(() => {
    if (!isAuthenticated) {
      logout();
    }
  }, [isAuthenticated]);

  /**
   * @function useEffect()
   * @description On component mount, fetch client periods & engine scenarios
   */
  useEffect(() => {
    getClientPeriods(undefined, undefined, setClientPeriodsState, profitFormat, params, userSettings);
    getEngineScenarios(userAllowedMenuLinks, reportTitle, scenariosState, undefined, false, setScenariosState, true, history,lang.observability.stage.key);
    getClientCostTerms(setClientCostTerms, profitFormat,lang.observability.stage.key);
  }, []);

  useEffect(() => {
    if(Object.keys(scenariosState).length > 0) {
      getVectors(
        scenariosState,
        dispatch,
        updateVectorState,
        setVectorState,
        reportTitle,
        profitFormat,
        props,
        false,
        [],
        userSettings,
        vectorState,
        undefined
      );
      getAllFileTypeColumns(scenariosState?.scenario?.scenario_type, scenariosState, setFileTypeColumns,lang.observability.stage.key);
      fetchUsedAttributesFunc();
    }
  }, [scenariosState]);

  /**
   * @function useEffect()
   * @description When clientPeriods are fetched and whenever the user makes a change, we rerender the header elements
   */
  useEffect(() => {
    if (Object.keys(scenariosState).length > 0 && Object.keys(clientPeriodsState).length > 0) {
      let headerElements = [];
      headerElements = useNewComponents? getNewHeaderElements() : getHeaderElements();
      setHeaderElements(headerElements);

    }
  }, [hasUnsavedChanges, scenariosState, clientPeriodsState, isScenarioDisabled, reportTitle, selectedPeriod, selectedRange, actionToken, isBackDisabled, isSaveDisabled]);

  const handleScenarioChange = (newScenario) => {
    var tempState = copyObjectValues(scenariosState);
    tempState.scenario = newScenario;
    tempState.scenarios = [newScenario.value];
    tempState.scenarioObjects = [newScenario];
    tempState.nextScenarios = [newScenario];
    tempState.nextScenariosObjects = [newScenario];
    tempState.scenarioStatus = newScenario.scenario_status;
    // tempState.scenarioList = tempState.scenarioList;

    reportRef.current?.onChangeScenario(newScenario.value);
    setScenariosState(tempState);
    if(report !== profitFormat){
        setStagingReport(profitFormat)
    }
  };

  const onBackClick = () => {
    reportRef.current?.onBackClick(report);
  };

  const handlePeriodChange = (newPeriod) => {
    let newSelectedPeriod = copyObjectValues(newPeriod);
    setSelectedPeriod(newSelectedPeriod);
    reportRef.current.setChosenPeriod(newPeriod);
    saveCookie("period", newPeriod.value);
  };

  const loadExclusionData = () => {
    reportRef.current.loadExclusionData();
  }
  const loadVectorsEntityCount = () => {
    reportRef.current.getVectorsEntityCount();
  }

  const loadCalculatedColumnsTotalAmounts = () => {
    reportRef.current.loadCalculatedColumnsTotalAmounts();
  }

  const loadMetricValues = () => {
    reportRef.current.getMetricsData();
  }
  

  /**
   * @function getHeaderElements()
   * @description This function renders reportTile, save button and reset button in case the user makes any change
   * @returns array of components to be rendered in {@link ReportHeader} component
   */
  const getHeaderElements = () => {
    let headerElements = [];

    headerElements.push(
      <div style={{ display: "flex", alignItems: "center", columnGap: "0.42vw", width: "100%" }}>
        {renderTitle(reportTitle)}
        <DropDown
          id="stage-select-set"
          className="width-200 input__dropdown"
          name={HEADER_ELEMENT.SCENARIO}
          value={findOptionByKey(scenariosState.scenarioList, scenariosState.scenario)}
          onChange={handleScenarioChange}
          options={scenariosState.scenarioList}
          disabled={isScenarioDisabled}
          type={DROPDOWN_TYPE.INPUT}
        />
        {STAGING_SECTIONS.PROFIT_STACK_MAPPING_NEW === report && (
          <div style={{ display: "flex", alignItems: "center", columnGap: "0.42vw" }}>
            {renderPSPeriodCalendar()}
            <label htmlFor="select-set" className="fs-default">
              {lang.header.titles.last_generated_token}:
            </label>
            {<p className="fs-14">{actionToken}</p>}
          </div>
        )}
        {![STAGING_SECTIONS.PROFIT_STACK_MAPPING_NEW, profitFormat].includes(report) && (
          <DropDown
            id="PeriodRange"
            className="uk-width-small input__dropdown"
            name="PeriodRange"
            value={findOptionByKey(clientPeriodsState.periods, selectedPeriod ?? clientPeriodsState.nextPeriod)}
            onChange={(e) => handlePeriodChange(e)}
            options={clientPeriodsState.periods}
            type={DROPDOWN_TYPE.INPUT}
          />
        )}
         {[STAGING_SECTIONS.MAPPING_TIE_OFF].includes(report) && (
            <Button
            id="load_total_values"
            label={lang.load_total_values}
            variant={BUTTON_VARIANT.PRIMARY}
            size={SIZES.DEFAULT}
            type={BUTTON_TYPE.DEFAULT}
            onBtnClick={loadExclusionData}
          />
        )}
        {[STAGING_SECTIONS.VECTOR_MAPPING].includes(report) && (
            <Button
            id="load_entity_count"
            label={lang.load_entity_count}
            variant={BUTTON_VARIANT.PRIMARY}
            size={SIZES.DEFAULT}
            type={BUTTON_TYPE.DEFAULT}
            onBtnClick={loadVectorsEntityCount}
          />
        )}
         {[STAGING_SECTIONS.CALCULATED_COLUMNS].includes(report) && (
            <Button
            id="load_total_amount"
            label={lang.load_total_amount}
            variant={BUTTON_VARIANT.PRIMARY}
            size={SIZES.DEFAULT}
            type={BUTTON_TYPE.DEFAULT}
            onBtnClick={loadCalculatedColumnsTotalAmounts}
          />
        )}

        {[STAGING_SECTIONS.METRICS_MAPPING].includes(report) && (
            <Button
            id="load-metric-value"
            label={lang.load_metric_value}
            variant={BUTTON_VARIANT.PRIMARY}
            size={SIZES.DEFAULT}
            type={BUTTON_TYPE.DEFAULT}
            onBtnClick={loadMetricValues}
          />
        )}

        {report !== profitFormat && (
          <div className={"gap_between_buttons"} style={{ display: "flex", flexDirection: "row", marginLeft: "auto", alignItems: "center" }}>
            {STAGING_SECTIONS.PROFIT_STACK_MAPPING_NEW === report && hasUnsavedChanges && (
            <span>
              <span className="fs-12 red italic">{lang.manage_columns.text.changes_not_saved}</span>
              <a
                  className={"uk-margin-default-left"}
                  uk-tooltip={"title:" + lang.discard_unsaved_changes + "; pos: bottom"}
                  onClick={() => setOpenDiscardChangesDialog(true)}
              >
                {lang.modal.buttons.reset}
              </a>
            </span>
            )}
            <div className={"gap_between_buttons"} style={{ display: "flex", flexDirection: "row", marginLeft: "auto"}}>
              <Button
                    key={"back-btn"}
                    id="header-back"
                    label={lang.navigation.btns.back}
                    variant={BUTTON_VARIANT.SECONDARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    disabled={isBackDisabled}
                    onBtnClick={onBackClick}
                />
                <span key={"changes-already-saved"} uk-tooltip={isSaveDisabled ? "title: Changes already saved; pos: bottom" : null}>
                  <Button
                    id="header-next"
                    label={lang.navigation.btns.save}
                    variant={BUTTON_VARIANT.PRIMARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    disabled={isSaveDisabled}
                    onBtnClick={headerSaveClick}
                  />
              </span>
            </div>
          </div>
        )}
      </div>
    );

    return headerElements;
  };

  const renderHeaderFirstRow = () => {
    let additionalTitle = reportTitle.split("-");

    let isStageMainScreen = report === profitFormat;
    let reportURL = props?.location?.pathname?.split("/");
    let reportName = reportURL?.[reportURL?.length - 1];
    let redirectedFromObj = {
      title: reportName,
      onClick: onBackClick
    }
    return renderBreadcrumbsAndDescription(() => props?.goToLandingPage(), undefined, props.reportDescription, additionalTitle[1], isStageMainScreen ? undefined : redirectedFromObj)
  }

  const renderHeaderSecondRow = () => {
    return (
        <div style={{display: "flex", alignItems: "center", columnGap: "0.42vw", width: "100%"}}>
          <DropDown
              id="stage-select-set"
              className="width-200 input__dropdown"
              name={HEADER_ELEMENT.SCENARIO}
              value={findOptionByKey(scenariosState.scenarioList, scenariosState.scenario)}
              onChange={handleScenarioChange}
              options={scenariosState.scenarioList}
              disabled={isScenarioDisabled}
              type={DROPDOWN_TYPE.INPUT}
          />
          {STAGING_SECTIONS.PROFIT_STACK_MAPPING_NEW === report && (
              <div style={{display: "flex", alignItems: "center", columnGap: "0.42vw"}}>
                {renderPSPeriodCalendar()}
                <label htmlFor="select-set" className="fs-default">
                  {lang.header.titles.last_generated_token}:
                </label>
                {<p className="fs-14">{actionToken}</p>}
              </div>
          )}
          {![STAGING_SECTIONS.PROFIT_STACK_MAPPING_NEW, profitFormat].includes(report) && (
              <DropDown
                  id="PeriodRange"
                  className="uk-width-small input__dropdown"
                  name="PeriodRange"
                  value={findOptionByKey(clientPeriodsState.periods, selectedPeriod ?? clientPeriodsState.nextPeriod)}
                  onChange={(e) => handlePeriodChange(e)}
                  options={clientPeriodsState.periods}
                  type={DROPDOWN_TYPE.INPUT}
              />
          )}
          {[STAGING_SECTIONS.MAPPING_TIE_OFF].includes(report) && (
              <Button
                  id="load_total_values"
                  label={lang.load_total_values}
                  variant={BUTTON_VARIANT.PRIMARY}
                  size={SIZES.DEFAULT}
                  type={BUTTON_TYPE.DEFAULT}
                  onBtnClick={loadExclusionData}
              />
          )}
          {[STAGING_SECTIONS.VECTOR_MAPPING].includes(report) && (
              <Button
                  id="load_entity_count"
                  label={lang.load_entity_count}
                  variant={BUTTON_VARIANT.PRIMARY}
                  size={SIZES.DEFAULT}
                  type={BUTTON_TYPE.DEFAULT}
                  onBtnClick={loadVectorsEntityCount}
              />
          )}
          {[STAGING_SECTIONS.CALCULATED_COLUMNS].includes(report) && (
              <Button
                  id="load_total_amount"
                  label={lang.load_total_amount}
                  variant={BUTTON_VARIANT.PRIMARY}
                  size={SIZES.DEFAULT}
                  type={BUTTON_TYPE.DEFAULT}
                  onBtnClick={loadCalculatedColumnsTotalAmounts}
              />
          )}

          {[STAGING_SECTIONS.METRICS_MAPPING].includes(report) && (
              <Button
                  id="load-metric-value"
                  label={lang.load_metric_value}
                  variant={BUTTON_VARIANT.PRIMARY}
                  size={SIZES.DEFAULT}
                  type={BUTTON_TYPE.DEFAULT}
                  onBtnClick={loadMetricValues}
              />
          )}

          {report !== profitFormat && (
              <div className={"gap_between_buttons"}
                   style={{display: "flex", flexDirection: "row", marginLeft: "auto", alignItems: "center"}}>
                {STAGING_SECTIONS.PROFIT_STACK_MAPPING_NEW === report && hasUnsavedChanges && (
                    <span>
              <span className="fs-12 red italic">{lang.manage_columns.text.changes_not_saved}</span>
              <a
                  className={"uk-margin-default-left"}
                  uk-tooltip={"title:" + lang.discard_unsaved_changes + "; pos: bottom"}
                  onClick={() => setOpenDiscardChangesDialog(true)}
              >
                {lang.modal.buttons.reset}
              </a>
            </span>
                )}
                <div className={"gap_between_buttons"}
                     style={{display: "flex", flexDirection: "row", marginLeft: "auto"}}>
                  <Button
                      key={"back-btn"}
                      id="header-back"
                      label={lang.navigation.btns.back}
                      variant={BUTTON_VARIANT.SECONDARY}
                      size={SIZES.DEFAULT}
                      type={BUTTON_TYPE.DEFAULT}
                      disabled={isBackDisabled}
                      onBtnClick={onBackClick}
                  />
                  <span key={"changes-already-saved"}
                        uk-tooltip={isSaveDisabled ? "title: Changes already saved; pos: bottom" : null}>
                  <Button
                      id="header-next"
                      label={lang.navigation.btns.save}
                      variant={BUTTON_VARIANT.PRIMARY}
                      size={SIZES.DEFAULT}
                      type={BUTTON_TYPE.DEFAULT}
                      disabled={isSaveDisabled}
                      onBtnClick={headerSaveClick}
                  />
              </span>
                </div>
              </div>
          )}
        </div>
    )
  }

  const getNewHeaderElements = () => {
    let headerElements = [];

    headerElements.push(
        <div style={{display: "flex", width: "100%", alignItems: "center"}}
             className="new_header_menu first_header_row gap_between_buttons">
          {renderHeaderFirstRow()}
        </div>
    );

    headerElements.push(
        <div className={"new_header_menu second_header_row gap_between_buttons "}
             style={{display: "flex", width: "100%", padding: "0.41667vw 0"}}>
          {renderHeaderSecondRow()}
        </div>
    );

    return headerElements;
  };


  const onSelectCallBack = () => {
    setShouldFetchNewData(true);
    hideCalendar();
  };

  useEffect(() => {
    if (shouldFetchNewData && reportRef && !isInfoDialogOpen) {
      reportRef.current?.profitStackReff.checkForChangesBeforeLoadPeriods();
      setChosenRange(selectedRange);
    }

    if (shouldFetchNewData || isInfoDialogOpen) {
      setShouldFetchNewData(false);
    }
  }, [shouldFetchNewData, reportRef]);

  /**
   * Renders period's calendar in PS mapping screen. When we first access PS mapping, the calendar will not have a value.
   * when selection period1 to period2, onSelectCallback is called to fetch data having the selected range
   * when selecting 1 period, reselect it to refetch the dat aof his period.
   * @returns calendar component
   */
  const renderPSPeriodCalendar = () => {
    let minYear = clientPeriodsState?.periods?.length ? Number(clientPeriodsState?.periods[clientPeriodsState?.periods?.length - 1]?.value.split("P")[0]) : 2010;
    let maxYear = clientPeriodsState?.periods?.length ? Number(clientPeriodsState?.periods[0].value.split("P")[0]) + 1 : 2030;

    return renderCalendar(
        clientPeriodsState.periods,
        selectedRange.startPeriod,
        selectedRange.endPeriod,
        selectedRange.inputValue,
        handlePeriodRangeChange,
        onSelectCallBack,
        clientPeriodsState.allPeriods,
        toggleCalendar,
        false,
        scenariosState.scenario?.value,
        false,
        undefined,
        minYear,
        maxYear,
        period_calendar_type.ACTIVE
    );
  };

  /**
   * @function handlePeriodRangeChange()
   * @param {*} name not used in this function
   * @param {*} e the selected range
   */
  const handlePeriodRangeChange = (name, e) => {
    let selectedYears = e.filter((year) => year.isSelected);
    let tempState = copyObjectValues(selectedRange);

    if (selectedYears.length > 0) {
      let periods = getLocalStorageValueByParameter("periods") ? JSON.parse(getLocalStorageValueByParameter("periods")) : [];
      let sortedSelectedYears = selectedYears.map((p) => p.year + p.value).sort();
      let startPeriod = sortedSelectedYears[0];
      let endPeriod = sortedSelectedYears[sortedSelectedYears.length - 1];
      let startDate = new Date(periods.filter((e) => e.value === startPeriod)[0].start_date);
      let endDate = new Date(periods.filter((e) => e.value === endPeriod)[0].end_date);

      if (getPeriodDifference(startPeriod, endPeriod) > 12) {
        setInfoDialogMsg(lang.not_allowed_periods_selection);
        setInfoDialogOpen(true);
        return;
      }

      tempState.startPeriod = startPeriod;
      tempState.endPeriod = endPeriod;
      tempState.customStartDate = startDate;
      tempState.customEndDate = endDate;

      let inputValue = startPeriod;
      if (startPeriod !== endPeriod) {
        inputValue += " " + lang.COMMON.TO.toLowerCase() + " " + endPeriod;
      }
      tempState.inputValue = inputValue;

      saveCookie("nextCustomStartDate", startPeriod);
      saveCookie("nextCustomEndDate", endPeriod);
      setSelectedRange(tempState);
    }
  };

  /**
   * @function discardChangesAndLoadNewPeriods()
   * @description Closes the dialog of 'You have unsaved changes' and fetch data for selected periods.
   */
  const discardChangesAndLoadNewPeriods = () => {
    setHasUnsavedChanges(false);
    getAllFileTypeColumns(scenariosState?.scenario?.scenario_type, scenariosState, setFileTypeColumns, lang.observability.stage.key);
    fetchUsedAttributesFunc();

    reportRef.current?.profitStackReff?.pslSectionRef?.pslTableRef?.getProfitStackFields();
    if (getSelectedPeriods()) {
      reportRef.current?.profitStackReff?.pslSectionRef?.pslTableRef?.getMappedLines("", true);
    }
    reportRef.current?.profitStackReff?.changePeriod();
  };

  /**
   * @function rollBackPSPeriods()
   * @description This function gets triggered when the user changes period and has unsaved changes in PS mapping.
   * If it was the first time he select a period and then he clicks on cancel button to cancel changing period, period range will return to 'Choose Period'
   * @param {*} fetchAmounts
   */
  const rollBackPSPeriods = (fetchAmounts) => {
    setSelectedRange(!fetchAmounts ? initialPeriodRangeState : chosenRange);
  };

  /**
   * Get the periods selected in Profit stack mapping screen
   * @returns
   */
  const getSelectedPeriods = () => {
    if (selectedRange.customStartDate) {
      let periodsCount = monthDiff(selectedRange.customStartDate, selectedRange.customEndDate) + 1;
      return generatePeriods(selectedRange.customStartDate, periodsCount);
    }
    return [];
  };

  const setStagingReport = (report, reload) => {
    let title = capitaliseFirstLetterAfterChar(report);
    if (report !== profitFormat) {
      title = MAPPING_TITLES[report];
    } else {
      // when redirecting to stage screen, clear some states
      setShowBanner(false);
      setHasUnsavedChanges(false);
      // setActionToken("-");
      setSelectedRange(initialPeriodRangeState);
      setBannerConfig(undefined);
    }

    setReportTitle(title);
    setReport(report);
    // 'Scenario' dropdown is only enabled in Metrics Mappping and in Stage screen
    setScenarioDisabled(report !== STAGING_SECTIONS.METRICS_MAPPING && report !== profitFormat);
    // 'Save' button is disabled when we first acceess PS Mapping & Calculated Columns
    setSavedDisabled(report === STAGING_SECTIONS.PROFIT_STACK_MAPPING_NEW || report === STAGING_SECTIONS.CALCULATED_COLUMNS); 

    if (reload) {
      getEngineScenarios(userAllowedMenuLinks, profitFormat, undefined, undefined, false, setScenariosState, true, history,lang.observability.stage.key);
    }
  };

  /**
   * @function useEffect()
   * @description This function checks if the user has unsaved changes when he chooses to change report or to switch client.
   * In case he doesn't, he will be rerdirected to his chosen report, else a dialog opens to let him save/discard his changes before proceeding.
   * This is only used for profit stack mapping screen and not for other mappings.
   */
  useEffect(() => {
    if (checkingForUnsavedChanges) {
      setDivIdState(props.divId);
      setDestinationClientIdState(props.destinationClientId);
      if (hasUnsavedChanges && report === STAGING_SECTIONS.PROFIT_STACK_MAPPING_NEW) {
        setCheckUnsavedChangesDialogOpen(true);
      } else {
        props.setCheckingForUnsavedChanges(false);
        props.setCheckedUnsavedChangesRef(true, props.divId && Object.keys(props.divId).length > 0, !!props.destinationClientId);
      }
    }
  }, [checkingForUnsavedChanges, props.divId, props.destinationClientId]);

  /**
   * @function closeUnsavedChangesDialog()
   * @description This function closes the unsaved changes' dialog
   */
  const closeUnsavedChangesDialog = () => {
    setCheckUnsavedChangesDialogOpen(false);
    setDestinationClientIdState(undefined);
    setDivIdState(undefined);
    props.setCheckingForUnsavedChanges(false);
    props.setCheckedUnsavedChangesRef(false, false, false);
  };

  /**
   * @function onButtonNo()
   * @description This function is the on click function of the button 'No' in the 'Unsaved changes' dialog.
   * That's when the user decides to proceed without saving his changes.
   * It discards all changes and proceed in changing report/client
   */
  const onButtonNo = () => {
    setCheckUnsavedChangesDialogOpen(false);
    props.setCheckingForUnsavedChanges(false);
    props.setCheckedUnsavedChangesRef(true, divIdState && Object.keys(divIdState).length > 0, !!destinationClientIdState);
  };

  /**
   * @function onButtonYes()
   * @description This function is the on click function of the button 'Yes' in the 'Unsaved changes' dialog.
   * That's when the user decides to save his changes before proceeding.
   * It triggers the save changes fucntion and when the changes are saved successfully, the user will be redirected to his chosen report
   */
  const onButtonYes = () => {
    let cb = () => {
      props.setCheckingForUnsavedChanges(false);
      props.setCheckedUnsavedChangesRef(true, divIdState && Object.keys(divIdState).length > 0, !!destinationClientIdState);
    };
    setCheckUnsavedChangesDialogOpen(false);
    saveChildChanges(cb);

  };

  /**
   * @function unsavedChangesDialogActions()
   * @description This function renders the 'Unsaved changes' dialog's buttons
   * @returns dialog's buttons
   */
  const unsavedChangesDialogActions = () => {
    return (
      <>
        <Button
          label={lang.modal.buttons.yes}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          aria-label="Close"
          onBtnClick={onButtonYes}
        />
        <Button
          label={lang.modal.buttons.no}
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          aria-label="Close"
          onBtnClick={onButtonNo}
        />
        <Button
          label={lang.modal.buttons.cancel}
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          aria-label="Close"
          onBtnClick={closeUnsavedChangesDialog}
        />
      </>
    );
  };

  const openInfoDialog = (infoMsg) => {
    setInfoDialogOpen(true);
    setInfoDialogMsg(infoMsg);
  };

  const infoDialogActions = () => {
    return (
      <Button
        label={lang.modal.buttons.ok}
        variant={BUTTON_VARIANT.PRIMARY}
        size={SIZES.DEFAULT}
        type={BUTTON_TYPE.DEFAULT}
        onBtnClick={() => setInfoDialogOpen(false)}
      />
    );
  };

  const showScenarioPopUp = () => {
    let scenarioStatus = scenariosState.scenarioStatus;
    if (scenarioStatus === SCENARIO_STATUS.SANDBOX) {
      setOverwriteScenarioConfirmDialogOpen(true);
    } else {
      setCloneScenarioDialogOpen(true);
    }
  };

  const headerSaveClick = () => {
    switch (report) {
      case STAGING_SECTIONS.PROFIT_STACK_MAPPING_NEW:
        reportRef.current?.profitStackReff?.pslSectionRef?.pslTableRef?.saveChanges();
        break;
        case STAGING_SECTIONS.METRICS_MAPPING:
        reportRef.current?.profitStackRef?.saveChanges();
        break;
      default:
        showScenarioPopUp();
        break;
    }
  };

  const saveChildChanges = (callback) => {
    if (reportRef.current?.profitStackReff) {
      reportRef.current.profitStackReff.checkScenario(false, callback);
    }
    if (reportRef.current?.excludedLinesRef) {
      reportRef.current.excludedLinesRef.saveExclusions();
    }
    if (reportRef.current?.vectorRef) {
      reportRef.current.vectorRef.checkScenarioAccess();
    }
    if (reportRef.current?.profitStackRef) {
      reportRef.current.profitStackRef.checkScenario();
    }
    if (reportRef.current?.calculatedColumnRef) {
      reportRef.current.calculatedColumnRef.saveCalculatedColumns();
    }

    setCloneScenarioDialogOpen(false);
    setOverwriteScenarioConfirmDialogOpen(false);
  };

  const overwriteScenarioConfirmDialogActions = () => {
    return (
      <>
        <Button
          label={MESSAGES.modal.buttons.yes}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          className="uk-padding-small-right"
          onBtnClick={saveChildChanges}
        />
        <Button
          label={MESSAGES.modal.buttons.no}
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => setOverwriteScenarioConfirmDialogOpen(false)}
        />
      </>
    );
  };

  const openConfirmCancelDialogActions = () => {
    return (
      <>
        <Button
          label={lang.modal.buttons.yes}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => {
            setStagingReport(profitFormat);
            setConfirmCancelDialogOpen(false);
          }}
        />
        <Button
          label={lang.modal.buttons.no}
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => setConfirmCancelDialogOpen(false)}
        />
      </>
    );
  };

  const cloneScenarioDialogContent = () => {
    return (
      <div className="uk-display-flex uk-flex-middle">
        <i className="fa-3x fal fa-exclamation-triangle uk-margin-default-right" />
        <div className="uk-text-xmedium">{MESSAGES.new_scenario_message}</div>
      </div>
    );
  };
  const cloneScenarioDialogActions = () => {
    return (
      <>
        <Button
          id="clone-scenario"
          label={"Clone"}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={saveChildChanges}
        />
        <Button
          label={MESSAGES.modal.buttons.cancel}
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => setCloneScenarioDialogOpen(false)}
        />
      </>
    );
  };

  const openDiscardChangesDialogActions = () => {
    return (
      <>
        <Button
          label={lang.modal.buttons.discard}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => window.location.reload()}
        />
        <Button
          label={lang.modal.buttons.cancel}
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => {
            setScreenHasUnsavedChanges(true);
            setOpenDiscardChangesDialog(false);
          }}
        />
      </>
    );
  };

  const setScreenHasUnsavedChanges = (hasChanges) => {
    setHasUnsavedChanges(hasChanges);
    setSavedDisabled(!hasChanges);
  };

  const showSkeleton = !(Object.keys(scenariosState).length > 0 && Object.keys(clientPeriodsState).length && headerElements?.length > 0);

  return (
    <ErrorBoundary>
      <Suspense fallback={<p id="loading">Loading...</p>}>
        {showSkeleton && <LoaderSkeleton />}
        <div
          id={"main-component-container"}
          className={"main-component-container " + (showSkeleton ? "hidden" : "")}
          style={{ "--banner-height": showBanner ? "6%" : 0 }}
        >
          <div className={showBanner ? "header-banner-div" : "header-banner-div-hidden"}>
            {!!bannerConfig && (
              <NewBanner
                bannerClassName={`header-banner mapping-banner mapping-banner-${bannerConfig.bgColor}`}
                labelClassName={"header-banner-label mapping-banner-label"}
                icon={<i className={`fa-lg fas info-banner-icon ${bannerConfig.icon} ${bannerConfig.iconColor}`} />}
                label={bannerConfig.text}
                body={bannerConfig.body}
              />
            )}
          </div>
          <Modal
            id={"info-dialog"}
            openDialog={isInfoDialogOpen}
            bodyContent={() => <h4>{infoDialogMsg}</h4>}
            dialogActions={infoDialogActions}
            closeClick={() => setInfoDialogOpen(false)}
            size={DIALOG_SIZE.MEDIUM}
          />
          <Modal
            id={"discard-changes-dialog"}
            openDialog={openDiscardChangesDialog}
            bodyContent={() => <h4>{lang.discard_unsaved_changes}</h4>}
            dialogActions={openDiscardChangesDialogActions}
            closeClick={() => setOpenDiscardChangesDialog(false)}
            size={DIALOG_SIZE.MEDIUM}
          />
          {report === STAGING_SECTIONS.PROFIT_STACK_MAPPING_NEW && (
            <Modal
              id={"ps-mapping-discard-changes-dialog"}
              openDialog={isCheckUnsavedChangesDialogOpen}
              closeClick={closeUnsavedChangesDialog}
              bodyContent={() => <span className="fs-16">{lang.dashboards.messages.want_to_save_first}</span>}
              dialogActions={unsavedChangesDialogActions}
              size={DIALOG_SIZE.MEDIUM}
            />
          )}
          <div className="main-report-header">
            <ReportHeader headerElements={headerElements} />
          </div>
          <div className="main_report">
            {Object.keys(scenariosState).length > 0 && Object.keys(clientPeriodsState).length && (
              <StageData
                ref={reportRef}
                allAncillaryColumns={fileTypeColumns.allAncillaryColumns}
                allTypeColumns={fileTypeColumns.allTypeColumns}
                dataColumns={fileTypeColumns.dataColumns}
                clientCostTerms={clientCostTerms}
                clientName={userSettings.clientName}
                costCenter={userSettings.costCenter}
                fetchUsedAttributes={fetchUsedAttributesFunc}
                getSelectedPeriods={getSelectedPeriods}
                isChanged={hasUnsavedChanges}
                onDiscardClick={discardChangesAndLoadNewPeriods}
                periodDetails={clientPeriodsState.periodDetails}
                periodList={clientPeriodsState.periodList}
                periods={clientPeriodsState.periods}
                rollBack={rollBackPSPeriods}
                scenarioId={scenariosState.scenario?.value}
                scenarioObject={scenariosState.scenarioObjects[0]}
                scenarioStatus={scenariosState.scenarioStatus}
                selectedPeriod={selectedPeriod || clientPeriodsState.periods[0]}
                setActionToken={setActionToken}
                setNotSavedWarning={setScreenHasUnsavedChanges}
                setStagingReport={setStagingReport}
                showScenarioPopUp={showScenarioPopUp}
                usedAttrs_vec={usedAttrs.usedAttrs_vec}
                usedAttrs_calc={usedAttrs.usedAttrs_calc}
                user={userSettings.user}
                userAllowedSections={userAllowedMenuLinks}
                vectorOptions={vectorState.vectorOptions}
                setScenarioDisabled={setScenarioDisabled}
                history={history}
                openInfoDialog={openInfoDialog}
                stagingReport={report}
                setConfirmCancelDialogOpen={setConfirmCancelDialogOpen}
                setShowBanner={setShowBanner}
                setBannerConfig={setBannerConfig}
                setSavedDisabled={setSavedDisabled}
                setBackDisabled={setBackDisabled}
              />
            )}
          </div>
        </div>
        <Modal // this dialog will open when saving changes in psmapping on a sandbox scenario
          id={"overwrite-scenario-dialog"}
          openDialog={openOverwriteScenarioConfirmDialog}
          bodyContent={() => <h4>{MESSAGES.overwrite_scenario_message}</h4>}
          dialogActions={overwriteScenarioConfirmDialogActions}
          closeClick={() => setOverwriteScenarioConfirmDialogOpen(false)}
          size={DIALOG_SIZE.MEDIUM}
        />
        <Modal // this dialog will open when saving changes in psmapping on a published scenario
          id={"confim-clone-scenario-dialog"}
          title={"Clone Scenario"}
          openDialog={cloneScenarioDialogOpen}
          bodyContent={cloneScenarioDialogContent}
          dialogActions={cloneScenarioDialogActions}
          closeClick={() => setCloneScenarioDialogOpen(false)}
          size={DIALOG_SIZE.MEDIUM}
        />
        <Modal
          id={"confirm-cancel-dialog"}
          openDialog={confirmCancelDialogOpen}
          bodyContent={() => <h4>{lang.discard_changes_confirmation}</h4>}
          dialogActions={openConfirmCancelDialogActions}
          closeClick={() => setConfirmCancelDialogOpen(false)}
          size={DIALOG_SIZE.MEDIUM}
        />
      </Suspense>
    </ErrorBoundary>
  );
};

export { StageWrapper };
