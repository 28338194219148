import React, { Component } from 'react';
import Popup from 'react-popup';
import Tabulator from "tabulator-tables"; //import Tabulator library

import { getEmbeddedChild } from '../../class/array';
import { getReadableFilterText, getReadableRuleText, toggleLoader, setLocalStorageValueByParameter } from '../../class/common.js';
import { ACCRUALS, API_URL, CONFIGURE_SECTIONS, CONFIGURE_VARS, costtype, COST_FUNCTIONS_FIELDS, COST_FUNCTIONS_TITLES, DEFINE_FIELDS, DM_SET_REPORT_ACTIONS, ENGINE_FILTER, Formats, FormatTypes, INVOICELINETYPE, PSL_RETURN_NAMES, ROW_STATUS, VECTOR_STAGING_ATTRIBUTES,FILTER, DATATYPE_ENUM, COST_ASSIGNMENTS, CALCULATED_COLUMNS, BUTTON_VARIANT, SIZES, BUTTON_TYPE, DIALOG_SIZE } from '../../class/constants.js';
import { formatValHTML, formatValReact } from "../../class/format";
import { convertPxToViewport, convertViewportToPx } from '../../class/formatting';
import { alertAndLogError, getObjectAsArray, translateRule, translateRuleStringToObject } from '../../class/jqueries.js';
import { fetchAPI, FETCHAPI_PARAMS, FETCH_METHOD } from '../../class/networkUtils';
import { advancedFormatCurrency } from '../../class/number.js';
import { copyObjectValues, generateActionToken, getTranslationFile, getUniqueArrayValues, tryParse } from '../../class/utils.js';
import '../../styles/dataModeling.css';
import PeriodsDropDown from '../buildData/PeriodsDropDown.js';
import EngineFilterModal from '../filter/EngineFilterModal';
import Rules from './Rules';
import Button from '../../newComponents/Button';
import { getExpandCollapseButtons, getTableButton, getTableIconButton } from '../../newComponents/tabulatorComponents';
import { createDropdown } from '../../newComponents/DropdownTabulator';
import Modal from '../../newComponents/Modal';

const $ = require('jquery');
const UIkit = require('uikit');

const baseUrl = process.env.REACT_APP_BASE_URL;
const path = "/DataModeling";
const _displayName = "display_name";
const _filterAttribute = "filterAttribute";
var leafNodes = [];
const MESSAGES = getTranslationFile();
var generalData = [];
var troubleshootResult = {};
const _invoiceLine = [{value:"InvoiceLine", label:"InvoiceLine"}];
var clickedRowParent = null;
var elementParent = null;

var fields = [{ label: "CountLines", value: "CountLines", type:"numeric" }];
const _yes = "Yes";
const _no = "No";
const _fileEngine = ENGINE_FILTER.KEYS.FILE;
const _columnEngine = ENGINE_FILTER.KEYS.COLUMN;
const _function = ENGINE_FILTER.KEYS.FUNCTION;
const _valueOptions = ENGINE_FILTER.KEYS.VALUE_OPTIONS;
const _columnOptions = ENGINE_FILTER.KEYS.COLUMN_OPTIONS;
const _fileOptions = ENGINE_FILTER.KEYS.FILE_OPTIONS;
const _functionOptions = ENGINE_FILTER.KEYS.FUNCTION_OPTIONS;
const _fieldDataType = ENGINE_FILTER.KEYS.FIELD_DATA_TYPE;
const _logicalOperator = ENGINE_FILTER.KEYS.LOGICAL_OPERATOR;
const _empty = MESSAGES.ui_filter.dropdowns.engine_filter_functions.empty.value;
const _nempty = MESSAGES.ui_filter.dropdowns.engine_filter_functions.not_empty.value;
const _ct = MESSAGES.ui_filter.dropdowns.engine_filter_functions.contains.value;
const _nct = MESSAGES.ui_filter.dropdowns.engine_filter_functions.not_contains.value;
const _sandbox = 'SANDBOX';
const STATUS ="status";
const _children = "children";
const lang = getTranslationFile();
let globaltop = 0;
const COST_FUNCTIONS_COLUMNS=[
    {
        title: COST_FUNCTIONS_TITLES.EXPAND,
        field: COST_FUNCTIONS_FIELDS.EXPAND,
        width: convertViewportToPx(140),
        headerSort: false,
        cssClass: "ccf-remove-cell-padding"
    },
    {
        title: COST_FUNCTIONS_TITLES.ADD,
        field: COST_FUNCTIONS_FIELDS.ADD,
        width: convertViewportToPx(40),
        headerSort: false,
        cssClass: "uk-padding-remove"
    },
    {
        field: COST_FUNCTIONS_FIELDS.PSS_ID,
        visible: false
    },
    {
        title: COST_FUNCTIONS_TITLES.ID,
        field: COST_FUNCTIONS_FIELDS.ID,
        width: convertViewportToPx(90),
        headerSort: false,
        visible: false
    },
    {
        title: COST_FUNCTIONS_TITLES.NAME,
        field: COST_FUNCTIONS_FIELDS.NAME,
        width: convertViewportToPx(330),
        headerSort: false,
        cssClass: "leftAlign",
        hozAlign: "left",
    },
    {
        title: COST_FUNCTIONS_TITLES.COST_CENTER,
        field: COST_FUNCTIONS_FIELDS.COST_CENTER,
        headerSort: false,
        width: convertViewportToPx(130),
        headerHozAlign:"left"
    },
    {
        title: COST_FUNCTIONS_TITLES.SHOW_CHILDREN,
        field: COST_FUNCTIONS_FIELDS.SHOW_CHILDREN,
        width: convertViewportToPx(100),
        headerHozAlign:"left",
        headerSort: false,
        tooltip: false
    },
    {
        title: COST_FUNCTIONS_TITLES.CHILDREN_ACCESS,
        field: COST_FUNCTIONS_FIELDS.CHILDREN_ACCESS,
        headerHozAlign:"left",
        width: convertViewportToPx(120),
        headerSort: false,
        tooltip: false
    },
    {
        title: COST_FUNCTIONS_TITLES.RULE,
        field: COST_FUNCTIONS_FIELDS.RULE,
        cssClass: "left-align-wrapped",
        hozAlign:"left",
        minWidth: convertViewportToPx(330),
        widthGrow: 2,
        headerSort: false,
        tooltip: false
    },
    {
        title: COST_FUNCTIONS_TITLES.JSON_FILTER,
        field: COST_FUNCTIONS_FIELDS.JSON_FILTER,
        widthGrow: 1,
        headerSort: false,
        visible: false
    },
    {
        title: STATUS,
        field: STATUS,
        widthGrow: 1,
        headerSort: false,
        visible: false
    },
    {
        title: COST_FUNCTIONS_TITLES.FILTER,
        field: COST_FUNCTIONS_FIELDS.FILTER,
        cssClass: "left-align-wrapped",
        hozAlign:"left",
        minWidth: convertViewportToPx(330),
        widthGrow: 2,
        headerSort: false,
        tooltip: false
    },
    {
        title: COST_FUNCTIONS_TITLES.AMOUNT,
        field: COST_FUNCTIONS_FIELDS.AMOUNT,
        width: convertViewportToPx(180),
        headerHozAlign:"left",
        headerSort: false,
    },
    {
        title: ROW_STATUS.TITLE,
        field: ROW_STATUS.FIELD,
        visible: false
    },
    {
        title: COST_FUNCTIONS_TITLES.VALID,
        field: COST_FUNCTIONS_FIELDS.IS_VALID,
        width: convertViewportToPx(155),
        headerSort: false, 
        tooltip: false,
    },
    {
        title: "",
        field: COST_FUNCTIONS_FIELDS.VALIDATION_STATUS,
        headerSort: false,
        visible: false
    },
    {
        title: COST_FUNCTIONS_TITLES.PARENT_COST_KEY,
        field: COST_FUNCTIONS_FIELDS.PARENT_COST_KEY,
        visible: false
    },
    {
        title: COST_FUNCTIONS_TITLES.RETURN_NAME,
        field: COST_FUNCTIONS_FIELDS.RETURN_NAME,
        visible: false
    },
    {
        title: COST_FUNCTIONS_TITLES.ACTUAL_ID,
        field: COST_FUNCTIONS_FIELDS.ACTUAL_ID,
        visible: false
    },
    {
        title: COST_FUNCTIONS_TITLES.CONFIGURED_PERIODS,
        field: COST_FUNCTIONS_FIELDS.CONFIGURED_PERIODS,
        visible: false,
        headerSort: false,
        headerHozAlign:"left",
        hozAlign:"left",
        width: convertViewportToPx(120)
    },
    {
        title: COST_FUNCTIONS_TITLES.DEFAULT,
        field: COST_FUNCTIONS_FIELDS.DEFAULT,
        visible: false,
        headerSort: false,
        width: convertViewportToPx(105)
    },
    {
        title: "",
        width: convertViewportToPx(70),
        field: COST_FUNCTIONS_FIELDS.DELETE,
        visible: false,
        headerSort: false,
        cssClass: "uk-padding-remove"
    }
];

/**
 * configure Cost functions Screen in Engine UI
 * @author [Sarah Farjallah]
 */
class ConfigureCostFunctions extends Component {
    constructor(props) {
    super(props);
		this.state = {
            user: null,
            idToken: null,
            menu: "",
            data: [],
            costCenterData: [],
            costCenterSelected: "",
            selectedRow: "",
            showRule: false,
            expandedRowsCostkeys: [],
            periods: [],
            periodsStatus:[],
            emptyRows:[],
            changed: this.props.changed,
            defaultStatus: false,
            modifiedPeriods:[],
            deselectedPeriods: [],
            invalidatedPeriods: [],
            timePeriod: typeof this.props.selectedPeriod === "object" ?  this.props.selectedPeriod.value : this.props.selectedPeriod,
            build: false
        };

        this.saveCostFunctions = this.saveCostFunctions.bind(this);
        this.formatData = this.formatData.bind(this);
        this.onTabulatorRenderComplete = this.onTabulatorRenderComplete.bind(this);
        this.getColumnFormatter = this.getColumnFormatter.bind(this);
        this.addTooltipIcon = this.addTooltipIcon.bind(this);
        this.onCheckShowChildren = this.onCheckShowChildren.bind(this);
        this.updateChildren = this.updateChildren.bind(this);
        this.setClickedRow = this.setClickedRow.bind(this);
        this.showFilterPopup = this.showFilterPopup.bind(this);
        this.onCancelFilter = this.onCancelFilter.bind(this);
        this.showRulePopUp = this.showRulePopUp.bind(this);
        this.onCancelRule = this.onCancelRule.bind(this);
        this.setChosenPeriod = this.setChosenPeriod.bind(this);
        this.getAmountPerCostKey = this.getAmountPerCostKey.bind(this);
        this.reExpandCollapsedRows  = this.reExpandCollapsedRows.bind(this);
        this.addValidateAllButton = this.addValidateAllButton.bind(this);
        this.validateAll = this.validateAll.bind(this);
        this.saveAndGoToBuild = this.saveAndGoToBuild.bind(this);
        this.createTooltip = this.createTooltip.bind(this);
        this.getAllLeafNodes = this.getAllLeafNodes.bind(this);
        this.isSubChild = this.isSubChild.bind(this);
        this.isGrandChild = this.isGrandChild.bind(this);
        this.getFields = this.getFields.bind(this);
        this.updateData = this.updateData.bind(this);
        this.createDocumentClick = this.createDocumentClick.bind(this);
        this.saveAndGoToNextReport = this.saveAndGoToNextReport.bind(this);
        this.getStagedPeriods = this.getStagedPeriods.bind(this);
        this.getMetricsPeriodStatus = this.getMetricsPeriodStatus.bind(this);
        this.getVectorPeriodStatus = this.getVectorPeriodStatus.bind(this);
        this.setTableColumns = this.setTableColumns.bind(this);
        this.onChangeScenario = this.onChangeScenario.bind(this);
        this.handleFilterRowChange = this.handleFilterRowChange.bind(this);
        this.setFilterAttrOptions = this.setFilterAttrOptions.bind(this);
        this.getConfigureColumnsValues = this.getConfigureColumnsValues.bind(this);
        this.discardFilter = this.discardFilter.bind(this);
        this.disableValidateButton = this.disableValidateButton.bind(this);
        this.updateDataVisibility = this.updateDataVisibility.bind(this);
        this.goToNextReport = this.goToNextReport.bind(this);
        this.expandAll = this.expandAll.bind(this);
        this.deleteRuleAndFilterRow = this.deleteRuleAndFilterRow.bind(this);
        this.setRowToDefault = this.setRowToDefault.bind(this);
        this.showPeriodComp = this.showPeriodComp.bind(this);
        this.addPeriods = this.addPeriods.bind(this);
        this.findRow = this.findRow.bind(this);
        this.hidePeriodDropDown = this.hidePeriodDropDown.bind(this);
        this.deleteIndexRowPeriod = this.deleteIndexRowPeriod.bind(this);
        this.periodExist = this.periodExist.bind(this);
        this.envokeWarning = this.envokeWarning.bind(this);
        this.handleRestrictionChange = this.handleRestrictionChange.bind(this);
        this.fetchAPI = fetchAPI.bind(this);
        this.savedRowNumber = -1;
        this.savedRowIndex = -1;
    }

    onChangeScenario(option) {
        this.props.resetMultipleRequestsData(["getCostFunction", "getVectorList", "getQuadrantConfiguration", "getProfitStackLines"])
        this.getCostFunction();
    }
    
    expandAll(expand, rows) {
        var obj = this;
        var allRows = rows || this.tabulator.getRows();
        allRows.forEach(function(row) {
            if (!expand) {
                row.treeExpand();
                if(row.getData().children){
                    obj.expandAll(expand, row.getTreeChildren())
                }
            } else {
                row.treeCollapse();
                if(row.getData().children){
                    obj.expandAll(expand, row.getTreeChildren())
                }
            }
           
        });

    }

    getStagedPeriods() {
        let _this = this;
        // if(this.state.stagedPeriodsReqSent) {
        //     return;
        // }
        // this.state.stagedPeriodsReqSent = true;

        var query = {
            action: "getPeriodsStatus",
            scenario_id: this.props.scenarioId,
            isEngine:true
        }
        let onThenCallback = (data) => {
            if(data && data.data) {             
                var periods = [];
                    data.data.map(function(item){
                        periods.push({value:item["period_name"], label: item["period_name"], start_date: item["start_date"], end_date: item["end_date"], period_id:item["client_period_id"], status: item["invoiceline_staging_status"]})
                    })
                    var selected = "";
                    var selectedStaged = periods.filter(e=>e["status"] === "STAGED");
                    if (selectedStaged.length > 0) {
                        selected = selectedStaged[0];
                    } 
                    this.setState({
                        periodsStatus: copyObjectValues(periods),
                        periods: periods,
                        statusRefPeriods: data.data
                        // timePeriod: selected !== "" ? selected.value : periods[0].value 
                    },function(){
                        //We need getVectorPeriodStatus request before getCostFunction (PI-28537)
                        _this.getVectorPeriodStatus(()=>{
                            _this.getCostFunction();
                        })
                       
                        _this.setTableColumns(); //called here cz we need the periods
                        _this.props.setTabsData("getStagedPeriods", data)

                    });
                    _this.getStagedPeriodsFetched = false;
            } else if(data.ERROR) {
                Popup.alert("There's something wrong with your query.");
            } 
        } 
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "getPeriodsStatus",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: true,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.showCustomizedLoader]:true,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]:lang.observability.configure_build.configure_cost_function.screen_name,
            [FETCHAPI_PARAMS.requestDescription]:lang.observability.configure_build.configure_cost_function.requests_description.get_periods_status
        };
        let data = _this.props.getTabsData("getStagedPeriods");
        if(data && data.data){
            var periods = [];
            data.data.map(function(item){
                periods.push({value:item["period_name"], label: item["period_name"], start_date: item["start_date"], end_date: item["end_date"], period_id:item["client_period_id"], status: item["invoiceline_staging_status"]})
            })
            this.setState({
                periodsStatus: copyObjectValues(periods),
                periods: periods,
                statusRefPeriods: data.data
            },function(){
                _this.getCostFunction();
                _this.setTableColumns(); //called here cz we need the periods
            });
        }else{
            _this.getStagedPeriodsFetched = true;
            _this.fetchAPI(fetchOptions);
        }
    }

    setTableColumns() {
        var obj = this;
        var tableColumns = copyObjectValues(COST_FUNCTIONS_COLUMNS);
        tableColumns.forEach(col => {
            var columnField = col.field;
            var columnFieldMessage = "configure_cf_"+columnField;
            let validateAllTooltipMessage = "title: " + MESSAGES[columnFieldMessage] + "; pos: left";
            if (col.field === COST_FUNCTIONS_FIELDS.COST_CENTER) {
                col.title = obj.props.costCenter + CONFIGURE_SECTIONS.BASED_PREFIX;
            }
            if(this.state.periodsStatus.length) {
                if (col.field === COST_FUNCTIONS_FIELDS.AMOUNT) {
                    col.titleFormatter = (cell, params) => this.addDropDownTitleFormatter(cell, params, MESSAGES[columnFieldMessage]);
                    col.titleFormatterParams = {comp: this};
                } else if (col.field === COST_FUNCTIONS_FIELDS.IS_VALID) {
                    col.titleFormatter = (cell, params) => this.addValidateAllButton(cell, params, validateAllTooltipMessage);
                    col.titleFormatterParams = {comp: this};
                } else if (col.title !== "") {
                    col.titleFormatter = (cell) => this.addTooltipIcon(MESSAGES[columnFieldMessage], cell, "fa-info-circle");
                }
            }
            if (obj.props.viewMode) {
                if ([COST_FUNCTIONS_FIELDS.AMOUNT, COST_FUNCTIONS_FIELDS.IS_VALID].includes(col.field)) {
                    col.visible = true
                }
                if ([COST_FUNCTIONS_FIELDS.ADD,COST_FUNCTIONS_FIELDS.CONFIGURED_PERIODS,COST_FUNCTIONS_FIELDS.DELETE,COST_FUNCTIONS_FIELDS.DEFAULT].includes(col.field)) {
                    col.visible = false
                }
            } else {
                if ([COST_FUNCTIONS_FIELDS.AMOUNT, COST_FUNCTIONS_FIELDS.IS_VALID].includes(col.field)) {
                    col.visible = false
                }
                if ([COST_FUNCTIONS_FIELDS.ADD,COST_FUNCTIONS_FIELDS.CONFIGURED_PERIODS,COST_FUNCTIONS_FIELDS.DELETE,COST_FUNCTIONS_FIELDS.DEFAULT].includes(col.field)) {
                    col.visible = true
                }
            }

            col.formatter = this.getColumnFormatter(col.field, col.formatter);
        });
        this.tabulator.setColumns(tableColumns);
    }

    disableValidateButton(period, fromRules=false){
       var statusRefPeriods = this.state.statusRefPeriods || [];
        var periodStatus = statusRefPeriods.filter(e=>e["period_name"] === period)[0];
        if(periodStatus) {
            if (periodStatus.invoiceline_staging_status !== 'STAGED' || periodStatus.profit_stack_staging_status !== 'STAGED' 
            || periodStatus.vector_staging_status !== 'STAGED') {
                if(!fromRules){
                    $("#validate-all-icon").addClass("disabled");
                    var el = document.getElementById("validate_icon");
                    if (el !== null) {
                        el.setAttribute("uk-tooltip", "title: " + MESSAGES.configure_validate + "; pos: left");
                    }
                }
                return true;
            } else{
                if(!fromRules) {
                    $("#validate-all-icon").removeClass("disabled");
                    var el = document.getElementById("validate_icon");
                    var columnFieldMessage = "configure_cf_"+COST_FUNCTIONS_FIELDS.IS_VALID;
                    if (el !== null) {
                        el.setAttribute("uk-tooltip","title: " + MESSAGES[columnFieldMessage] + "; pos: left");
                    }
                }
                return false;
            }
        }
    }

    setChosenPeriod(e) {
         this.setState({
            timePeriod: e.value,      
        }, function(){
            sessionStorage.setItem("selectedPeriod",e.value);
            $("#amount_period").html("Amounts of " + e.value);
            this.getFields(e.value);
            this.getCostFunction(this.tabulator.getData(), e.value);
         });
    }

    getLeafNodes(nodes, costKey, reload, result = []) {
        for(var i = 0, length = nodes.length; i < length; i++){
          if(!nodes[i].children && nodes[i][COST_FUNCTIONS_FIELDS.RULE] !== "")
            // && nodes[i][COST_FUNCTIONS_FIELDS.IS_VALID] === COST_FUNCTIONS_FIELDS.VALIDATION_STATUS_ENUM.NOT_VALIDATED // to be uncommented later when is_valid status is retreived from DB
          {
            if (costKey === nodes[i][COST_FUNCTIONS_FIELDS.ID] || costKey === undefined) {
                result.push(nodes[i]);
                nodes[i][COST_FUNCTIONS_FIELDS.IS_VALID] = COST_FUNCTIONS_FIELDS.VALIDATION_STATUS_ENUM.PENDING;
            }
          }else{
            if (nodes[i].children !== undefined) {
                result = this.getLeafNodes(nodes[i].children, costKey, false, result);
            }  
          }
        }
        if (reload) {
            this.setState({
                data: nodes
            },function(){
                this.reExpandCollapsedRows();
            });
            generalData = nodes;
        }

        this.tabulator.replaceData(generalData);     //set is_valid status as pending
        return result;
    }
   
    getAllLeafNodes(nodes, result = []){
        for(var i = 0, length = nodes.length; i < length; i++){
          if(!nodes[i].children){
            result.push(nodes[i]);
          }else{
            if (nodes[i].children !== undefined) {
                result = this.getAllLeafNodes(nodes[i].children, result);
            }  
          }
        }
        
        return result;
    }

    validateAll(costKey){
        var obj = this;
        var timePeriod = this.state.timePeriod;
        var result = this.getLeafNodes(this.tabulator.getData(), costKey, true);
        var costFunctions = {};
        var unstagedMetrics = [];
        var unstagedVectors = [];
        var vectors = obj.state.vectors;
        var metrics =  obj.state.metricFields;
        
        for (var e in result) {
            var staged = true;
            if (result[e][COST_FUNCTIONS_FIELDS.COST_TYPE] !== INVOICELINETYPE) {
                for (var elt in result[e][COST_FUNCTIONS_FIELDS.CONFIG]) {
                    var rule = "";
                    if((result[e][COST_FUNCTIONS_FIELDS.CONFIG][elt][COST_FUNCTIONS_FIELDS.RULE] && result[e][COST_FUNCTIONS_FIELDS.CONFIG][elt].periods.includes(timePeriod)) ||
                        (result[e][COST_FUNCTIONS_FIELDS.CONFIG][elt][COST_FUNCTIONS_FIELDS.RULE] && result[e][COST_FUNCTIONS_FIELDS.CONFIG][elt].periods.length == 0 && obj.periodExist(timePeriod, result[e][COST_FUNCTIONS_FIELDS.CONFIG]))) {
                            rule = result[e][COST_FUNCTIONS_FIELDS.CONFIG][elt][COST_FUNCTIONS_FIELDS.RULE] === undefined || result[e][COST_FUNCTIONS_FIELDS.CONFIG][elt][COST_FUNCTIONS_FIELDS.RULE] === "" ? "" : result[e][COST_FUNCTIONS_FIELDS.CONFIG][elt][COST_FUNCTIONS_FIELDS.RULE];
                            var ruleObj = translateRuleStringToObject(rule);
                            // var filter = result[e][COST_FUNCTIONS_FIELDS.FILTER].replaceAll("'","\"");
                            var filter = result[e][COST_FUNCTIONS_FIELDS.CONFIG][elt][COST_FUNCTIONS_FIELDS.JSON_FILTER];
                            if(filter && filter !== "" && filter.includes("{")) {
                                var updatedFilter = JSON.parse(filter).filter;
                                filter =result[e][COST_FUNCTIONS_FIELDS.CONFIG][elt][COST_FUNCTIONS_FIELDS.FILTER];
                                if (updatedFilter.length > 0) { // checking if filter contains unstaged vectors
                                    for(var f in updatedFilter) {
                                        var vectorFilter =  vectors.filter(e=>e["vector_name"] === updatedFilter[f][_columnEngine]);
                                        if (vectorFilter.length > 0) {
                                            for (var elementFilter in vectors) {
                                                if (vectors[elementFilter]["vector_name"] === vectorFilter[0]["field"]) {
                                                    Object.keys(JSON.parse("["+JSON.stringify(vectors[elementFilter]["period_status"])+"]")[0]).forEach(function eachKey(key){
                                                        if (key === timePeriod) {
                                                            if (JSON.parse("["+JSON.stringify(vectors[elementFilter]["period_status"])+"]")[0][key] !== 'STAGED'){
                                                                staged = false;
                                                                if (!unstagedVectors.includes(vectors[elementFilter]["vector_name"])) {
                                                                    unstagedVectors.push(vectors[elementFilter]["vector_name"]);
                                                                }
                                                            }
                                                        }
                                                    });
                                                }
                                            }
                                        }
                                    }
                                }   
                            }
                            if (ruleObj) {
                                for (var elt in ruleObj) {
                                    var metric = metrics.filter(e=>e["metric"]=== ruleObj[elt]["ruleCondition"]);
                                    var vector = vectors.filter(e=>e["vector_name"]===ruleObj[elt]["ruleVector"]);
                                    if (metric.length > 0) {
                                        for (var element1 in metrics) { // checking if rule contains unstaged metrics
                                            if (metrics[element1]["metric"] === metric[0]["metric"]) {
                                                Object.keys(JSON.parse("["+JSON.stringify(metrics[element1]["period_status"])+"]")[0]).forEach(function eachKey(key){
                                                    if (key === timePeriod) {
                                                        if (JSON.parse("["+JSON.stringify(metrics[element1]["period_status"])+"]")[0][key] !== 'STAGED'){
                                                            staged = false;
                                                            if (!unstagedMetrics.includes(metrics[element1]["metric"])) {
                                                                unstagedMetrics.push(metrics[element1]["metric"]);
                                                            }
                                                        }
                                                    }
                                                });
                                            }
                                        }
                                    }
                                    if (vector.length > 0) { // checking if rule contains unstaged vectors
                                        for (var element in vectors) {
                                            if (vectors[element]["vector_name"] === vector[0]["vector_name"]) {
                                                Object.keys(JSON.parse("["+JSON.stringify(vectors[element]["period_status"])+"]")[0]).forEach(function eachKey(key){
                                                    if (key === timePeriod) {
                                                        if (JSON.parse("["+JSON.stringify(vectors[element]["period_status"])+"]")[0][key] !== 'STAGED'){
                                                            staged = false;
                                                            if (!unstagedVectors.includes(vectors[element]["vector_name"])) {
                                                                unstagedVectors.push(vectors[element]["vector_name"]);
                                                            }
                                                        }
                                                    }
                                                });
                                            }
                                        }
                                    }
                                }
                            }
                            
                             
                        }   
                    }
                    if (staged) {
                        var costFunction = {};
                        costFunction.cost_key = Number(result[e][COST_FUNCTIONS_FIELDS.ID]);
                        costFunction.time_period = this.state.timePeriod;
                        costFunction.rules = rule || CONFIGURE_VARS.default.rule;
                        
                        costFunction.filter = !filter ? "" : filter;
                        costFunction.name = result[e][COST_FUNCTIONS_FIELDS.NAME];
                        if(result[e][COST_FUNCTIONS_FIELDS.COST_CENTER] === undefined)
                            costFunction.cost_center = "";
                        else if(result[e][COST_FUNCTIONS_FIELDS.COST_CENTER] === "ALL")
                            costFunction.cost_center = result[e][COST_FUNCTIONS_FIELDS.COST_CENTER];
                        else 
                            costFunction.cost_center = result[e][COST_FUNCTIONS_FIELDS.COST_CENTER]+"Key";
                            costFunction.pss_id = result[e][COST_FUNCTIONS_FIELDS.PSS_ID];
                            costFunction.period_id = this.state.periodsStatus.filter(ele=>ele.value === obj.state.timePeriod).length > 0 ? this.state.periodsStatus.filter(ele=>ele.value === obj.state.timePeriod)[0].period_id : "";
                            costFunctions[Number(result[e][COST_FUNCTIONS_FIELDS.ID])] = costFunction;
                    }
                }      
        }
        if (unstagedMetrics.length > 0 || unstagedVectors.length > 0) {
            var text = unstagedMetrics.length > 0 ? "The following Metrics are not staged:"+ unstagedMetrics +" \n" : " ";
            text += unstagedVectors.length > 0 ? "The following vectors are not staged:" + unstagedVectors: " ";
            Popup.alert(text);
            var data = obj.tabulator.getData();
            data = obj.setInitialValidationStatus(obj.tabulator.getData());
            obj.tabulator.replaceData(data);
            return;
        }
        obj.validate(costFunctions);
    }

    validate(costFunctions){
        var obj = this;
        $("#validate-all-icon").addClass("disabled");

        const baseUrl = "";
		const path = "/validate";
        var data = {
            "job_type":"Validate",
            "user_email":this.props.user.email,
            "prefix":this.props.tablePrefix.replace("_",""),
            "client_id":this.props.clientId,
            "client_name":this.props.clientName,
            "scenario_id":this.props.scenarioId,
            "project_id":this.props.projectId,
            "cost_functions":costFunctions,
            "preview":"False"
        }
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      fetch(`${baseUrl}${path}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(data)})
        .then((response)=>{
            return response.json();
        })
        .then((data)=>{
            if(data && data.done){
                obj.getCostFunction(obj.tabulator.getData(),undefined,undefined,true);
            } else {
                Popup.alert("Internal error has occured");
            }
        })
        .catch((error)=>{
            alertAndLogError(error);
        }).then(()=>{
            data = obj.setInitialValidationStatus(obj.tabulator.getData());
            generalData = data;
            obj.tabulator.replaceData(generalData);
            obj.reExpandCollapsedRows();
            $("#validate-all-icon").removeClass("disabled");
        });
    }

    enableManageBuilds = (value) => {
        this.props.enableManageBuilds(value);
    }

    getCostFunction(mainData, period, switchAfter,isValidateAll= false,openModalAfter){
        let _this = this;
        var timePeriodVal = typeof this.props.selectedPeriod === 'object' ?  this.props.selectedPeriod.value : this.props.selectedPeriod;
        var mainData = mainData !== undefined ? JSON.stringify(mainData): "";
        var timePeriod = period ? period : this.state.timePeriod && this.state.timePeriod.value ? this.state.timePeriod.value : timePeriodVal;

        var query = { 
            action: "getCostFunction",
            scenario_id: this.props.scenarioId,
            timePeriod: timePeriod,
            data: mainData
        };
        let onThenCallback = (data) => {
            if(data !== null){
                let copyData = copyObjectValues(data);
                var isYearBuilt = data.isYearBuilt === "true" ? true : false;
                var restrictions = data.pssRestrictions;
                var pss_restrictions = [];
                var default_restriction = data.default_restriction;
                restrictions.map(function(item){
                    pss_restrictions.push({value:item.machine_name, label:item.display_name, id:item.restriction_id, type:item.restriction_id});
                })
                data = data.data;
                if(data.length > 0) {
                    data = this.setInitialValidationStatus(data);
                    leafNodes = this.getAllLeafNodes(data);
                    this.setState({
                        data: data,
                        originalData: copyObjectValues(data),
                        isYearBuilt: isYearBuilt,
                        pss_restrictions: pss_restrictions,
                        default_restriction: default_restriction
                    }, function () {
                        _this.tabulator.replaceData(data);
                        _this.disableValidateButton(timePeriod);
                        _this.reExpandCollapsedRows();
                        _this.props.setTabsData("getCostFunction", copyData);
                        // _this.enableManageBuilds(true);
                        toggleLoader(false, 'onChangeScenario');
                        if(switchAfter){
                            _this.props.changeTab(_this.props.getNextTab());
                        }
                        if(openModalAfter){
                            _this.props.drawerToggleClickHandler()
                        }
                    });
                    _this.getCostFunctionFetched  = false;
                }
            } else if(data.ERROR) {
                Popup.alert("There's something wrong with your query.");
            } 
        } 
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "getCostFunction",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: true,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.showCustomizedLoader]: true,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]:lang.observability.configure_build.configure_cost_function.screen_name,
            [FETCHAPI_PARAMS.requestDescription]:lang.observability.configure_build.configure_cost_function.requests_description.get_cost_function

        };
        let data = copyObjectValues(_this.props.getTabsData("getCostFunction"));
        if(data && data.data && !isValidateAll){
            var isYearBuilt = data.isYearBuilt === "true" ? true : false;
            var restrictions = data.pssRestrictions;
            var pss_restrictions = [];
            var default_restriction = data.default_restriction;
            restrictions.map(function(item){
                pss_restrictions.push({value:item.machine_name, label:item.display_name, id:item.restriction_id, type:item.restriction_id});
            })
            data = data.data;
            if(data.length > 0) {
                data = this.setInitialValidationStatus(data);
                leafNodes = this.getAllLeafNodes(data);
                _this.setState({
                    data: data,
                    originalData: copyObjectValues(data),
                    isYearBuilt: isYearBuilt,
                    pss_restrictions: pss_restrictions,
                    default_restriction: default_restriction
                }, function () {
                    _this.tabulator.replaceData(data);
                    _this.disableValidateButton(timePeriod);
                    _this.reExpandCollapsedRows();
                });
            }
        }else{
            _this.fetchAPI(fetchOptions);
            _this.getCostFunctionFetched  = true;
        }
    }

    setInitialValidationStatus(data) {
        var obj = this;
        data.map(function(item) {
            if([PSL_RETURN_NAMES.VARCOGS, PSL_RETURN_NAMES.VARREVENUE].indexOf(item[COST_FUNCTIONS_FIELDS.RETURN_NAME].toLowerCase()) !== -1) {
                item[COST_FUNCTIONS_FIELDS.RULE] = CONFIGURE_VARS[item[COST_FUNCTIONS_FIELDS.RETURN_NAME].toLowerCase()].rule;
                item[COST_FUNCTIONS_FIELDS.FILTER] = JSON.stringify(CONFIGURE_VARS[item[COST_FUNCTIONS_FIELDS.RETURN_NAME].toLowerCase()].filter);
            }
            var value = item[COST_FUNCTIONS_FIELDS.VALIDATION_STATUS] === undefined ? "" : item[COST_FUNCTIONS_FIELDS.VALIDATION_STATUS] ;
            var rule = item[COST_FUNCTIONS_FIELDS.RULE];
            if(rule === "") {
                item[COST_FUNCTIONS_FIELDS.IS_VALID] = COST_FUNCTIONS_FIELDS.VALIDATION_STATUS_ENUM.MISSING_RULE;
            } else if(value === "") {
                item[COST_FUNCTIONS_FIELDS.IS_VALID] = COST_FUNCTIONS_FIELDS.VALIDATION_STATUS_ENUM.NOT_VALIDATED;
            } 
            if(!item[_children] && item[COST_FUNCTIONS_FIELDS.COST_CENTER] && item[COST_FUNCTIONS_FIELDS.COST_TYPE] !== costtype.invoicelinetype && item[COST_FUNCTIONS_FIELDS.COST_TYPE] !== costtype.accrued){
                item[COST_FUNCTIONS_FIELDS.IS_VALID] = COST_FUNCTIONS_FIELDS.VALIDATION_STATUS_ENUM.NOT_VALIDATED;
                if(rule===""){
                    item[COST_FUNCTIONS_FIELDS.RULE] = CONFIGURE_VARS.default.rule;
                }
            }
            if(rule && value) {
                // value = JSON.parse(value.replaceAll("'",'"'));
                value = JSON.parse(value);
                var assignAmount = value["assigned"] ? value["assigned"] : 0;
                var variance = (assignAmount - item["amount"]);
                var variancePerc = item["amount"] !== 0 ? variance / item["amount"] : 0;
                
                if (Math.abs(variance) < 100) {
                    item[COST_FUNCTIONS_FIELDS.IS_VALID] = COST_FUNCTIONS_FIELDS.VALIDATION_STATUS_ENUM.VALID;
                } else {
                    item[COST_FUNCTIONS_FIELDS.IS_VALID] = COST_FUNCTIONS_FIELDS.VALIDATION_STATUS_ENUM.WARNING;
                }
            }

            if(item.children && item.children.length) {
                item.children = obj.setInitialValidationStatus(item.children);
            }
        });

        return data;
    }

    getFields(timePeriod) {
        let _this = this;
        var query = { 
            action: "getFields",
            scenario_id: this.props.scenarioId,
            timePeriod: timePeriod !== undefined ? timePeriod : ""
        };
        let onThenCallback = (data) => {
            if(data.ERROR || data.result === 'ERROR') {
                Popup.alert('Something went wrong. Please try again');
            } else if(data) {             
                if(data.ILEFields) {
                    fields = [{label:'Count', value: 'CountLines', type:"numeric", dontSort:true}];
                    fields.push({isGroup:true, isDisabled:true, label: CALCULATED_COLUMNS.TITLES.FORMULA, value: CALCULATED_COLUMNS.TITLES.FORMULA,type:'numeric', dontSort:true});
                    for(var elt in data.ILEFields) {
                        if(!["REVENUE","QUANTITY SHIPPED","COGS"].includes(data.ILEFields[elt].name)){
                            continue;
                        }
                        if (fields.filter(e=>e.value === data.ILEFields[elt].field).length === 0) { //if already exist do not add
                            fields.push({label:data.ILEFields[elt][DEFINE_FIELDS.DATA_FIELD].replace(/ /g,'').replace(/_/g,'').replace("PRIMARYKEY","INVOICELINEKEY"), value:data.ILEFields[elt][DEFINE_FIELDS.DATA_FIELD].replace(/ /g,'').replace(/_/g,'').replace("PRIMARYKEY","INVOICELINEKEY").replace('COSTCENTER', _this.props.costCenter+"Key"),type:data.ILEFields[elt][DEFINE_FIELDS.DATA_TYPE_FIELD].toLowerCase(),[_fieldDataType]:data.ILEFields[elt][DEFINE_FIELDS.DATA_TYPE_FIELD].toLowerCase()})
                        }
                    }
                } 
                if (data.calculatedFields) {
                    for(var elt in data.calculatedFields) {
                        if (fields.filter(e=>e.value === data.calculatedFields[elt]["column_name"].replace("_cc","")).length === 0) {
                            fields.push({label:data.calculatedFields[elt]["column_name"].replace("_cc",""), value:data.calculatedFields[elt]["column_name"], type:"numeric", [_fieldDataType]:"numeric"})
                        } 
                    }
                }
                fields = fields.sort((a,b) => (a.dontSort || b.dontSort) ? 0 : (a.label.toLowerCase() > b.label.toLowerCase()) ? 1 : ((b.label.toLowerCase() > a.label.toLowerCase()) ? -1 : 0));
                this.setState({
                    fields: fields
                },function(){
                    _this.props.setTabsData("getFields", this.state.fields);
                });
                _this.getFieldsFetched  = false;
            } 
        } 
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "getFields",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: true,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.showCustomizedLoader]: true,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]:lang.observability.configure_build.configure_cost_function.screen_name,
            [FETCHAPI_PARAMS.requestDescription]:lang.observability.configure_build.configure_cost_function.requests_description.get_fields
            
        };
        let data = _this.props.getTabsData("getFields");
        if(data){
            if(data.ILEFields) {
                fields = [{label:'CountLines', value: 'CountLines', type:"numeric", dontSort:true}];
                fields.push({isGroup:true, isDisabled:true, label: CALCULATED_COLUMNS.TITLES.FORMULA, value: CALCULATED_COLUMNS.TITLES.FORMULA,type:'numeric', dontSort:true})
                for(var elt in data.ILEFields) {
                    if(!["REVENUE","QUANTITY SHIPPED","COGS"].includes(data.ILEFields[elt].name)){
                        continue;
                    }
                    if (fields.filter(e=>e.value === data.ILEFields[elt].field).length === 0) { //if already exist do not add
                        fields.push({label:data.ILEFields[elt][DEFINE_FIELDS.DATA_FIELD].replace(/ /g,'').replace(/_/g,'').replace("PRIMARYKEY","INVOICELINEKEY"), value:data.ILEFields[elt][DEFINE_FIELDS.DATA_FIELD].replace(/ /g,'').replace(/_/g,'').replace("PRIMARYKEY","INVOICELINEKEY").replace('COSTCENTER', _this.props.costCenter+"Key"),type:data.ILEFields[elt][DEFINE_FIELDS.DATA_TYPE_FIELD].toLowerCase(),[_fieldDataType]:data.ILEFields[elt][DEFINE_FIELDS.DATA_TYPE_FIELD].toLowerCase()})
                    }
                }
            } 
            if (data.calculatedFields) {
                for(var elt in data.calculatedFields) {
                    if (fields.filter(e=>e.value === data.calculatedFields[elt]["column_name"].replace("_cc","")).length === 0) {
                        fields.push({label:data.calculatedFields[elt]["column_name"].replace("_cc",""), value:data.calculatedFields[elt]["column_name"], type:"numeric", [_fieldDataType]:"numeric"})
                    } 
                }
            }
            fields = fields.sort((a,b) => (a.dontSort || b.dontSort) ? 0 : (a.label.toLowerCase() > b.label.toLowerCase()) ? 1 : ((b.label.toLowerCase() > a.label.toLowerCase()) ? -1 : 0));
            this.setState({
                fields: fields
            })
        }else{
            _this.fetchAPI(fetchOptions);
            _this.getFieldsFetched  = true;
        }
    }

    /**
     * when applying from the filter component, the filter data will be stored in a state to be saved when saving 
     * @param {*} filter 
     */
    saveFilterObjectTemp=(filter)=>{
        let _this = this;
        let vectorOptions = _this.getVectorsForFilter(_this.state.vectorList)
        _this.ruleRef.setState({
            tempFilter: copyObjectValues(filter),
            tempFilterText: getReadableFilterText(filter? filter: "", vectorOptions),
            returnedTranslatedRule:""
        })
    }
    
    getConfigureColumnsValuesNew=(filterObj, columnType = "vector", inputValue, callback, index)=>{
        var dataType = filterObj[_fieldDataType];
        var column = filterObj[_columnEngine];
        let updatedColumn = filterObj[_columnEngine].replace(FILTER.VALUES.FIELD.KEY,"").replace(FILTER.VALUES.FIELD.NAME,"").replace(FILTER.VALUES.FIELD.NUMBER,"");
        var functionValue = filterObj[_function];
        let vectors = copyObjectValues(this.state.vectors)
        var dataBody = { 
            action: "getConfigureColumnsValues",
            scenario_id: this.props.scenarioId,
            // timePeriod: this.state.timePeriod,
            fieldDataType: dataType,
            attribute: column,
            input_value: inputValue ? inputValue : ""
        };
        
        if(dataType === DATATYPE_ENUM.STRING){
           let filteredVector  = vectors.filter(e=>e[VECTOR_STAGING_ATTRIBUTES.NAME].toLowerCase() === updatedColumn.toLowerCase());
           if(filteredVector.length > 0) {
                dataBody[VECTOR_STAGING_ATTRIBUTES.ID] = filteredVector[0][VECTOR_STAGING_ATTRIBUTES.ID];
           } else {
                filteredVector  = vectors.filter(e=>e[VECTOR_STAGING_ATTRIBUTES.MACHINE_NAME].toLowerCase() === updatedColumn.toLowerCase());
                if(filteredVector.length > 0){
                    dataBody[VECTOR_STAGING_ATTRIBUTES.ID] = filteredVector[0][VECTOR_STAGING_ATTRIBUTES.ID];
                }
           }
           if(column.endsWith(FILTER.VALUES.FIELD.KEY)){
                dataBody[_filterAttribute] = FILTER.VALUES.FIELD.KEY;
           } else if (column.endsWith(FILTER.VALUES.FIELD.NAME)){
                dataBody[_filterAttribute] = FILTER.VALUES.FIELD.NAME;
           } else {
                dataBody[_filterAttribute] = FILTER.VALUES.FIELD.NUMBER;
           }
        }
        var isDisabled = [_ct, _nct].indexOf(functionValue) > -1 ? true : false;
     
        let onThenCallback = (data) => {
            if(data.data) {
                var values=[];
                data.data.map(function(item, index){
                    if (![_nempty, _empty].includes(functionValue)) {
                        if (item.attribute !== "" && item.attribute !== undefined) {
                            values.push({label:item.attribute, value:item.attribute, action: "input-value", isDisabled: isDisabled});
                        }
                    } else {
                        values.push({label:item.attribute, value:item.attribute, action: "input-value", isDisabled: isDisabled});
                    }
                });
                if(callback) {
                    callback(values, index);
                }
            }
        } 
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "getConfigureColumnsValues",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: dataBody,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]:lang.observability.configure_build.configure_cost_function.screen_name,
            [FETCHAPI_PARAMS.requestDescription]:lang.observability.configure_build.configure_cost_function.requests_description.get_configure_columns_values
        };
        fetchAPI(fetchOptions)
    }

    getConfigureColumnsValues(filterObj, columnType = "vector", inputValue, callback, index){
        var dataType = filterObj[_fieldDataType];
        var column = filterObj[_columnEngine];
        var functionValue = filterObj[_function];
        var dataBody = { 
            action: "getConfigureColumnsValues",
            scenario_id: this.props.scenarioId,
            timePeriod: this.state.timePeriod,
            fieldDataType: dataType,
            attribute: column,
            input_value: inputValue ? inputValue : "",
        };
        var isDisabled = [_ct, _nct].indexOf(functionValue) > -1 ? true : false;
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      fetch(`${baseUrl}${path}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(dataBody)})
            .then((response)=>{
                return response.json();
            }).then((data)=>{
                if(data.data) {
                    var values=[];
                    data.data.map(function(item, index){
                        if (![_nempty, _empty].includes(functionValue)) {
                            if (item.attribute !== "" && item.attribute !== undefined) {
                                values.push({label:item.attribute, value:item.attribute, action: "input-value", isDisabled: isDisabled});
                            }
                        } else {
                            values.push({label:item.attribute, value:item.attribute, action: "input-value", isDisabled: isDisabled});
                        }
                    });
                    if(callback) {
                        callback(values, index);
                    }
                }
            })
        .catch((error)=>{
            alertAndLogError(error);
        });
    }

    getAmountPerCostKey(cell) {
        toggleLoader(true, "getAmountPerCostKey");
        var dataBody = { 
            action: "getAmountPerCostKey",
            scenario_id: this.props.scenarioId,
            timePeriod: this.state.timePeriod,
            costKey: cell.getRow().getData()[COST_FUNCTIONS_FIELDS.ID]
        };
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      fetch(`${baseUrl}${path}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(dataBody)})
            .then((response)=>{
                return response.json();
            }).then((data)=>{
                if(data.error || data.result === 'ERROR'){
                    Popup.alert('Something went wrong. Please try again');
                } else if(data.data) {
                    this.setState({
                        costCenterData: data.data
                    }, function () {
                        this.reExpandCollapsedRows();
                        this.setOpenDetailedAmountDialog(true)
                    })
                }
            })
        .catch((error)=>{
            alertAndLogError(error);
        }).then(()=>{
            toggleLoader(false, "getAmountPerCostKey");
        });  
    }
 
    getVectorsForFilter(data) {
        data = data || [];
        var vectors = [];

        data.map(function (item) {
            var temp = {};
            temp.label = item[VECTOR_STAGING_ATTRIBUTES.NAME];
            temp.value = item[VECTOR_STAGING_ATTRIBUTES.MACHINE_NAME];
            vectors.push(temp);
        });

        return vectors;
    }

    setRowFilter(filter) {
        var tempRow = this.state.selectedRow;
        tempRow.original.filter = filter;

        this.setState({
            selectedRow: tempRow,
        }, function(){
            this.forceUpdate();
        });
    }

    formatData(data) {
        for(var e in data ) {
            let item = data[e];

            if (item.children !== undefined) {
                item.leaf = false;
                this.formatData(item.children);
            } else{
                item.leaf = true
            }
        }
    }

    validateCostFunctionRules(data) {
        //if has children and rule = ""
        data = data || this.state.data;
        let validation = {
            ruleMissing: false,
            validationPending: false,
            validationNotRun: false
        }

        for (var i = 0; i < data. length; i++) {
            var row = data[i];

            if(!row.children) {
                if((!row.rule || row.rule === "") && this.shouldHaveRuleAndFiter(row)) {
                    validation.ruleMissing = true;
                }

                if(row[COST_FUNCTIONS_FIELDS.IS_VALID] === COST_FUNCTIONS_FIELDS.VALIDATION_STATUS_ENUM.NOT_VALIDATED && this.shouldHaveRuleAndFiter(row)) {
                    validation.validationNotRun = true;
                }

                if(row[COST_FUNCTIONS_FIELDS.IS_VALID] === COST_FUNCTIONS_FIELDS.VALIDATION_STATUS_ENUM.PENDING && this.shouldHaveRuleAndFiter(row)) {
                    validation.validationPending = true;
                }
                // return validation;
            } else {
                validation = this.validateCostFunctionRules(row.children);
            }

            if(validation.ruleMissing || validation.validationNotRun || validation.validationPending) {
                break;
            }
        }

        return validation;
    }

    checkRulesAndFilters =(data, arr) =>{
        let _this = this;
        for (var e in data) {
            if (!data[e].children && data[e][COST_FUNCTIONS_FIELDS.COST_CENTER] && data[e][COST_FUNCTIONS_FIELDS.COST_TYPE] !== costtype.invoicelinetype) {
                if (!this.validateRuleFilterRows(data[e])){
                    arr.push(data[e][COST_FUNCTIONS_FIELDS.NAME]);
                }
            } else {
                _this.checkRulesAndFilters(data[e].children, arr);
            }
        }
        return arr;
    }
/**
 * 
 * @param {*} row 
 * function checks if there's a row with empty added  rule and periods
 * 
 * 
 */
    validateRuleFilterRows(row) {
        if (row[COST_FUNCTIONS_FIELDS.CONFIG] && (row[COST_FUNCTIONS_FIELDS.CONFIG].length > 1)) {
            for (var e in row[COST_FUNCTIONS_FIELDS.CONFIG]) {
                if ((row[COST_FUNCTIONS_FIELDS.CONFIG][e][COST_FUNCTIONS_FIELDS.RULE] === ""|| !row[COST_FUNCTIONS_FIELDS.CONFIG][e][COST_FUNCTIONS_FIELDS.RULE])
                    && (!row[COST_FUNCTIONS_FIELDS.CONFIG][e].periods || row[COST_FUNCTIONS_FIELDS.CONFIG][e].periods.length === 0)){
                    return false;
                }
            }
        }
        return true
    }


    executeSave=(switchAfter,openModalAfter,newScenario)=>{
        let _this = this;
        _this.setOpenCheckBeforeChangeDialog(false)
        _this.props.resetTabsData(CONFIGURE_SECTIONS.TITLES.CONFIG_COST_FUNCTIONS);
        _this.saveCostFunctions(switchAfter,openModalAfter,newScenario);
    }

    onCancel=()=>{
      this.setOpenCheckBeforeChangeDialog(false)
        if(this.state.switchAfter || this.state.openModalAfter){
            this.setState({
                switchAfter:switchAfter,
                openModalAfter:false
            })
        }
    }

    checkBeforeSaveCostFunction(redirectToBuild, type, switchAfter,openModalAfter,newScenario) {
        var emptyRows = [];
        // var validation = this.validateCostFunctionRules();
        var validation = this.checkRulesAndFilters(this.tabulator.getData(), emptyRows);
        if (!this.state.changed) {
            this.props.launchToast(true);
            return;
        }
        if(emptyRows.length > 0) {
            this.setState({
                emptyRows: emptyRows,
                switchAfter: switchAfter,
                openModalAfter: openModalAfter,
                newScenario: newScenario
            }, function() {
              this.setOpenCheckBeforeChangeDialog(true)
            })
        }else{
            this.executeSave(switchAfter,openModalAfter);
        }

        // if(message !== "") {
        //     message += " Are you sure you want to save the changes?";
            
        //     this.setState({
        //         checkBeforeSaveMessage: message,
        //         redirectToBuild: redirectToBuild,
        //         goToNextReport: type !== DM_SET_REPORT_ACTIONS.SAVE
        //     }, function() {
        //         window._profitIsleOpenModal('checkBeforeSaveModal');
        //     })
        // } else if(type !== DM_SET_REPORT_ACTIONS.SAVE) {
        //     // this.saveAndGoToNextReport();
        // } else {
        //     this.saveCostFunctions();
        // }
        // else {
        //     this.saveAndGoToBuild(redirectToBuild);
        // }
    }

    saveAndGoToBuild(redirectToBuild) {
        this.saveCostFunctions();
        if (redirectToBuild) {
            this.props.goToBuild();
        }
    }

    saveAndGoToNextReport() {
        this.saveCostFunctions();
        this.props.setReport(DM_SET_REPORT_ACTIONS.NEXT, true);
    }

    goToNextReport(){
        this.props.setReport(DM_SET_REPORT_ACTIONS.NEXT, true);
    }

    saveCostFunctions(switchAfter,openModalAfter,newScenario) {
        var _this = this;
        var costFunctionData = copyObjectValues(this.tabulator.getData());
        this.formatData(costFunctionData);

        $("#Configure_Next_Button").removeClass("uk-button-disabled");
        $("#Configure_Back_Button").removeClass("uk-button-disabled");
        $("#configure_back").removeAttr("uk-tooltip");
        $("#configure_next").removeAttr("uk-tooltip");

        var query = {
            action: "saveCostFunction",
            scenario_id: this.props.scenarioId,
            costFunction: JSON.stringify(costFunctionData),
            invalidatedPeriods: JSON.stringify(_this.state.invalidatedPeriods),
            build: this.state.build.toString()
        }
     
        let onThenCallback = (data) => {
            if(data !== null && !data.hasOwnProperty("ERROR")){
                _this.setState({
                    changed: false,
                    build: false,
                    emptyRows:[],
                    switchAfter:false,
                    openModalAfter:false,
                    newScenario: undefined
                },function(){
                    _this.props.launchToast(true);
                    _this.props.configure(false);
                    if(newScenario){
                        _this.props.startChangeScenario(newScenario);
                    }else{
                        _this.getCostFunction(undefined, undefined, switchAfter,undefined,openModalAfter);
                    }
                });
            } else {
                _this.setState({
                    changed: false,
                    switchAfter:false,
                    openModalAfter:false
                },function(){
                    _this.props.launchToast(false);
                    _this.getCostFunction(undefined, undefined, switchAfter,undefined,openModalAfter);
                })
            }
       }

        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "saveCostFunctions",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader] : true,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.showCustomizedLoader]:true,
            [FETCHAPI_PARAMS.screenName]:lang.observability.configure_build.configure_cost_function.screen_name,
            [FETCHAPI_PARAMS.requestDescription]:lang.observability.configure_build.configure_cost_function.requests_description.save_cost_functions
        }

        fetchAPI(fetchOptions);
    }


    /**
     * function reexpands the previously expanded rows after state update
     * @param {*} rows 
     */
    reExpandCollapsedRows(rows) {
        var obj = this;
        var allRows = rows || this.tabulator.getRows();
        allRows.forEach(function(row) {
            let isCollapsed = !row._row.modules.dataTree.open;
            if(obj.state.expandedRowsCostkeys.indexOf(Number(row.getData()[COST_FUNCTIONS_FIELDS.ID])) !== -1 && (!isCollapsed || obj.redrawTable)) {
                //!isExpanded - if row is already expanded, do not retry to expand
                //if redrawTable = true because if a row has been moved, it is expanded in tabulator data, but not in the table, so re-expand anyways
                row.treeCollapse();
                obj.reExpandCollapsedRows(row.getTreeChildren());
            } else {
                obj.reExpandCollapsedRows(row.getTreeChildren()); //In case a parent was expanded, the subChildren that are collapsed should stay collapsed when clicking on configure or splitting
            }
        })
    }

    onCheckShowChildren(e, cell) {
        let _this = this;
        var value = e.target.checked ? "true" : "false";
        var data = cell.getRow().getTable().getData();
        data = this.updateDataVisibility(data, cell.getRow().getData()[COST_FUNCTIONS_FIELDS.ID], value);
        this.tabulator.replaceData(data);
        this.reExpandCollapsedRows();
        this.setState({
            changed:true,
            // build: true
        },function(){
            _this.props.setIsChanged(true);
        })
    }

    updateDataVisibility(data, costkey, value, subChild=false) {
        for (var e in data) {
            var children = data[e][COST_FUNCTIONS_FIELDS.CHILDREN];
            if(data[e][COST_FUNCTIONS_FIELDS.ID] === costkey || subChild) {
                data[e][COST_FUNCTIONS_FIELDS.SHOW_CHILDREN] = value;
                this.updateDataVisibility(children, COST_FUNCTIONS_FIELDS.SHOW_CHILDREN, value, true);
            } else  if(children && children.length) {
               this.updateDataVisibility(children, costkey, value);
            }
        }
        return data;
    }

    updateChildren(children, field, value) {
        var obj = this;
        children.forEach(function(child) {
            child[field] = value;
            var children = child["children"];
            if(children && children.length) {
                obj.updateChildren(children, field, value);
            }
        });
        return children;
    }

    shouldComponentUpdate(nextProps, nextState) {
      var shouldUpdate = JSON.stringify(nextState) !== JSON.stringify(this.state) ||
          JSON.stringify(nextProps) !== JSON.stringify(this.props);
      
      return shouldUpdate;
    }

    addTooltipIcon(tooltipMessage, cell, faClass){
        var div = document.createElement("div");

        if(cell !== undefined) {
            var p = document.createElement("p");
            p.innerHTML = cell.getValue();
            div.appendChild(p);
        }

        var el = document.createElement("i");
        el.classList.add("fal", faClass, "uk-margin-xsmall-left", "uk-cursor-pointer");
        el.setAttribute("uk-tooltip", tooltipMessage);

        var innerDiv = document.createElement("div");
        innerDiv.appendChild(el);
        // if (cell) {
        //     innerDiv.style.display = "inline";
        // }else{
        //     innerDiv.style.display = "flex";
        // }
        innerDiv.appendChild(el);

        div.appendChild(innerDiv);
        // div.style.display = "inline-block";

        return div;
    }
    
    
    addDropDownTitleFormatter(cell, params, tooltipMessage) {
        let div = document.createElement("div");

        var icon = document.createElement("i");
        icon.classList.add("fal", "fa-info-circle", "uk-margin-small-left", "uk-cursor-pointer");
        icon.setAttribute("uk-tooltip", tooltipMessage);
        var period = typeof params.comp.props.selectedPeriod === "object" ? params.comp.props.selectedPeriod.value : params.comp.props.selectedPeriod;
        let p = document.createElement("p");
        p.innerHTML = "Amounts of " + period;
        p.id = "amount_period";
        div.appendChild(p);
        div.appendChild(icon);

        return div;
    }   

    addValidateAllButton(cell, params, tooltipMessage){
        let div = document.createElement("div");
        let p = document.createElement("p");
        div.classList.add("uk-display-inline-flex", "uk-flex-middle");
        p.setAttribute("id", "validate-all-icon");
        p.classList.add("text-link", "uk-margin-remove");
        p.title = "Validate all";

        // p.classList.add("disabled");     //set it to disabled by default
        p.textContent = cell.getValue() + " ";
        var el = document.createElement("i");
        el.classList.add("fal", "fa-info-circle", "uk-margin-small-left", "uk-cursor-pointer");
        el.setAttribute("id","validate_icon");
        var period = typeof params.comp.props.selectedPeriod === 'object' ? params.comp.props.selectedPeriod.value : params.comp.props.selectedPeriod;
        if (params.comp.disableValidateButton(period)) {
            el.setAttribute("uk-tooltip", "title: " + MESSAGES.configure_validate + "; pos: left");
            p.classList.add("disabled");
        } else{
            el.setAttribute("uk-tooltip", tooltipMessage);
        }
        if (params.comp.state.periodsStatus.filter(e=>e["status"] !== "STAGED").length !== params.comp.state.periodsStatus.length){
            p.onclick =  function(){
                params.comp.validateAll();
            };
        }else {
            p.title = "stage a period in order to validate";
        }

        div.appendChild(p);
        div.appendChild(el);
        return div;
    }

    setFilterAttrOptions(rowIndex, attribute, options, subType) {
        var _this = this;
        if(!this.filterRef.filterDialRef.state.filterRefs[rowIndex] || !this.filterRef.filterDialRef.state.filterRefs[rowIndex].ref.current) {
            return;
        }
        if(attribute === _valueOptions) {
            this.filterRef.filterDialRef.state.filterRefs[rowIndex].ref.current.isLoading = false;
        }
        if (attribute === _fileOptions) {
            this.filterRef.filterDialRef.state.filterRefs[rowIndex].ref.current.filterObj[ENGINE_FILTER.KEYS.RAW_FILE_SUBTYPE_ID] = subType;
        }
        this.filterRef.filterDialRef.state.filterRefs[rowIndex].ref.current.filterObj[attribute] = options;
        this.filterRef.filterDialRef.state.filterRefs[rowIndex].ref.current.forceUpdate();
        let menuIsOpen = this.filterRef.filterDialRef.state.filterRefs[rowIndex].ref.current.state.menuIsOpen;
        if(menuIsOpen) {
            this.filterRef.filterDialRef.state.filterRefs[rowIndex].ref.current.refresh(function() {
                _this.filterRef.filterDialRef.state.filterRefs[rowIndex].ref.current.selectRef.focus();
                
            });
        }
        
    }

    handleFilterRowChange(object, attribute, rowIndex, conditionIndex, valueObj, inputValue, tempExceptionData) {
        var _this = this;
       
        let callback = ()=>{};
        var tempRow = {};
        
        switch (attribute){
            case _columnEngine: // column change
                //setting the function options
                tempRow =_this.state.filter[rowIndex] ? this.state.filter[rowIndex] : {};
                var functionOptions = getObjectAsArray(MESSAGES.ui_filter.dropdowns.engine_filter_functions, valueObj[_fieldDataType].toLowerCase(), "value_type");
                _this.setFilterAttrOptions(rowIndex, _functionOptions, functionOptions);
                _this.state.filter[rowIndex][_fieldDataType] = valueObj[_fieldDataType];
                _this.state.filter[rowIndex][_functionOptions] = functionOptions;
                _this.state.filter[rowIndex][_columnEngine] = valueObj.value;
                callback = function(values) {
                    //setting the value options
                    _this.setFilterAttrOptions(rowIndex, _valueOptions, values);
                    _this.state.filter[rowIndex][_valueOptions] = values;
                }
                if (tempRow[_fieldDataType].toLowerCase() !== Formats.Numeric.toLowerCase()) {
                    _this.getConfigureColumnsValuesNew(tempRow, valueObj.columnType, "", callback);
                }
                break;

            case _logicalOperator: // to enable column drop down when adding new row 
            tempRow = _this.state.filter[rowIndex] ? this.state.filter[rowIndex] : {};
            
                tempRow[_columnOptions] = this.state.fields.concat(_this.state.filterVectors).filter(el=>el && el.label && el.label !== 'CountLines' && el.label !== 'Count');
                _this.setFilterAttrOptions(rowIndex+1, _fileOptions, [], tempRow.raw_file_subtype_id);
                _this.setFilterAttrOptions(rowIndex+1, _fileEngine, tempRow[_fileEngine], tempRow.raw_file_subtype_id);
                _this.setFilterAttrOptions(rowIndex+1, _columnOptions, tempRow[_columnOptions]);
                if (!_this.state.filter[rowIndex+1]){
                    _this.state.filter.push(
                        {[_fileEngine]:  tempRow[_fileEngine], [_columnOptions]:tempRow[_columnOptions], raw_file_subtype_id: tempRow.raw_file_subtype_id, [_fileOptions]: []}
                    );
                }
        }
    }

    showFilterPopup(filterRef) {
        var _this = this;
        let callback = ()=>{};
        var filter = filterRef ? filterRef : this.state.clickedRow[COST_FUNCTIONS_FIELDS.CONFIG][this.state.index][COST_FUNCTIONS_FIELDS.JSON_FILTER];
        filter = filter !== "" && typeof (filter) === "string" ? tryParse(filter).filter : filter.length > 0 ? filter :[];
        var fieldsConcatinated = _this.state.fields.concat(_this.state.filterVectors);
        if (filter.length === 0) {
            filter = [{[_fileEngine]:"invoiceline" ,
                [_columnOptions]: fieldsConcatinated.length > 0 ? fieldsConcatinated.filter(el=> el && el.label && el.label !== 'CountLines' && el.label !== 'Count')  : "", raw_file_subtype_id: "1"}];
        } else {
            for(var e in filter) {
                var columnType = "";
                filter[e][_columnOptions] = fieldsConcatinated.length > 0 ? fieldsConcatinated.filter(el=> el && el.label && el.label !== 'CountLines' && el.label !== 'Count') : "";
                filter[e][_functionOptions] = getObjectAsArray(MESSAGES.ui_filter.dropdowns.engine_filter_functions, filter[e][_fieldDataType].toLowerCase(), "value_type");
                callback = function(values, index) {
                    filter[Number(index)][_valueOptions] = values;
                    _this.setFilterAttrOptions(Number(index), _valueOptions, values);
                }
                if (filter[e][_fieldDataType].toLowerCase() !== 'numeric') {
                    var current = _this.state.vectorList.filter(elt => elt["vector_name"] === filter[e][_columnEngine]);
                    if (current.length > 0) {
                        columnType = "vector";
                    }
                   
                    _this.getConfigureColumnsValuesNew(filter[e], columnType, "", callback, e);
                }

            }
        }

        this.setState({
            showFilter: true,
            filter: filter
        }, function() {
            _this.reExpandCollapsedRows();
            _this.filterRef.openFilterModal(filter);
        })
    }

    fetchLeavesByType(data, returned) {
        for(var e in data) {
            if (data[e][_children] && data[e][_children].length > 0) {
                this.fetchLeavesByType(data[e][_children], returned);
            } else if ((data[e][COST_FUNCTIONS_FIELDS.COST_TYPE] === costtype.attribute) || (data[e][COST_FUNCTIONS_FIELDS.COST_TYPE] === costtype.invoicelinetype)){
                returned.push(data[e]);
            }
        }
        return returned;
    }

    showRulePopUp() {
        let obj = this;
        var transAndAttrs = [];
        obj.fetchLeavesByType(obj.tabulator.getData(),transAndAttrs);
        obj.setState({
            showFilter:false,
            showRule: true,
            transactionAttributeLeaves: transAndAttrs
        }, function() {
            obj.reExpandCollapsedRows();
            UIkit.modal("#rulesModal").show();
            obj.ruleRef.setState({id:generateActionToken(5)})
        
        })
    }

    updateMessage(data,row, message){
        for (var e in data) {
            if(data[e][COST_FUNCTIONS_FIELDS.ACTUAL_ID] == row[COST_FUNCTIONS_FIELDS.PSS_ID]) {
                message.push("This change affects these profit stack lines: " + data[e][COST_FUNCTIONS_FIELDS.NAME].replace('Accrual','Actual') +" and " + data[e][COST_FUNCTIONS_FIELDS.NAME]);
            } else if (data[e].children && data[e].children.length > 0) {
               this.updateMessage(data[e].children, row, message);
            }
        }
    }

    setClickedRow(evt, cell) {
        let obj = this;
        clickedRowParent = cell.getRow().getData();
        elementParent = cell.getRow().getData()[COST_FUNCTIONS_FIELDS.ID];
        var message = [];
        var index = $(evt.currentTarget).attr("id");
        var data = this.tabulator.getData();
        this.updateMessage(data,clickedRowParent, message);
        this.setState({
            clickedRow: cell.getRow().getData(),
            message:message,
            element: cell.getRow().getData()[COST_FUNCTIONS_FIELDS.ID],
            index: index
            
        },()=>{
            if(obj.ruleRef !== null){
                obj.ruleRef.setState({
                    filterRowRefs:[],
                    rowNum:1,
                    returnedTranslatedRule: obj.state.clickedRow?.translatedRule
                });
            }
            
        })
    }

    discardFilter(){
        var _this = this;
        if(clickedRowParent && clickedRowParent[COST_FUNCTIONS_FIELDS.FILTER] && clickedRowParent[COST_FUNCTIONS_FIELDS.FILTER].length === 0) {
            _this.filterRef.filterDialRef.state.filterRefs=[];
            _this.filterRef.filterDialRef.updateAdvancedData([]);
        }
    }

    /**
     * save role and filter
     * @param {*} json_filter 
     * @param {*} ruleFinal 
     */
saveRuleAndFilter=(json_filter,ruleFinal, translatedRule)=>{
        var obj = this;
        if(json_filter){// if api request will only be sent if the user identifies a filter
            for(var e in json_filter) {
                if(!json_filter[e].raw_file_subtype_id) {
                    json_filter[e][_fileEngine]="invoiceline";
                    json_filter[e] [_columnOptions]= obj.state.fields.concat(obj.state.filterVectors).filter(el=>el.label !== 'CountLines');
                    json_filter[e].raw_file_subtype_id= "1";
                }
            }
            var requestedFilter ={filter:json_filter};
            json_filter.map(function(f) {
                var current = obj.state.vectorList && obj.state.vectorList.length > 0 ? obj.state.vectorList.filter(e => e["vector_name"] === f[_columnEngine]) : [];
            });
            var dataBody = { 
                action: "translateFilter",
                scenario_id: this.props.scenarioId,
                identifier: requestedFilter
            };
            let onThenCallback = (data) => {
                if (data && data.data!==undefined) {
                    obj.saveRowRuleAndFilter(requestedFilter,data.data,ruleFinal,translatedRule);
                } else if(data.ERROR) {
                    Popup.alert("There's something wrong with your query.");
                } 
            } 
            let fetchOptions = {
                [FETCHAPI_PARAMS.funcName]: "translateFilter",
                [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
                [FETCHAPI_PARAMS.showLoader]: false,
                [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
                [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
                [FETCHAPI_PARAMS.query]: dataBody,
                [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
                [FETCHAPI_PARAMS.screenName]:lang.observability.configure_build.configure_cost_function.screen_name,
                [FETCHAPI_PARAMS.requestDescription]:lang.observability.configure_build.configure_cost_function.requests_description.translate_filter
            };
            obj.fetchAPI(fetchOptions);
        }else{// save only the rule
            obj.saveRowRuleAndFilter(undefined,undefined,ruleFinal,translatedRule);
        }

    }

/**
 * function updates the accrual line configuration along with the validation status and rules and filters to be same exact as the actual line 
 * ps: this line is disabled and user cant modify anything in it 
 * @param {*} data 
 * @param {*} row 
 * @param {*} isRule 
 * @param {*} index 
 */
    updateAccrual(data, row, isRule, index, periods, invalidatedPeriods) {
        for (var e in data) {
            if(data[e][COST_FUNCTIONS_FIELDS.ACTUAL_ID] == row[COST_FUNCTIONS_FIELDS.PSS_ID]) {
                data[e][COST_FUNCTIONS_FIELDS.VALIDATION_STATUS] = "";
                if (isRule) {
                    data[e][COST_FUNCTIONS_FIELDS.IS_VALID] = COST_FUNCTIONS_FIELDS.VALIDATION_STATUS_ENUM.NOT_VALIDATED;
                    data[e][COST_FUNCTIONS_FIELDS.CONFIG] = row[COST_FUNCTIONS_FIELDS.CONFIG];
                    data[e][COST_FUNCTIONS_FIELDS.CONFIG][index][ROW_STATUS.FIELD] = ROW_STATUS.VALUES.EDITED;
                    var obj = copyObjectValues(data[e]);
                    if (periods && periods.length> 0) {
                        for (var e in periods) {
                            invalidatedPeriods.push({costKey:obj[COST_FUNCTIONS_FIELDS.ID],period: periods[e]});
                        }
                    }
                } else {
                    data[e][COST_FUNCTIONS_FIELDS.IS_VALID] = row[COST_FUNCTIONS_FIELDS.IS_VALID];
                    var obj = copyObjectValues(data[e]);
                    if (periods && periods.length> 0) {
                        for (var e in periods) {
                            invalidatedPeriods.push({costKey:obj[COST_FUNCTIONS_FIELDS.ID],period: periods[e]});
                        }
                    }
                    if (data[e][COST_FUNCTIONS_FIELDS.CONFIG][index]) {
                        data[e][COST_FUNCTIONS_FIELDS.CONFIG][index][ROW_STATUS.FIELD] = ROW_STATUS.VALUES.EDITED;
                    }
                }
            } else if (data[e][COST_FUNCTIONS_FIELDS.CHILDREN]) {
                this.updateAccrual(data[e].children, row, isRule, index, periods, invalidatedPeriods);
            }
        }

    }
    /**
     * function updates the rule of the config index that has changed along with the accrual line if the the line modified is actual
     * @param {*} rule 
     */
    saveRowRuleAndFilter=(json_filter ,filterFromAPI, rule, translatedRule)=> {// and filter
        var periods = this.props.periods;
        var invalidatedPeriods = this.state.invalidatedPeriods;
        var obj = this;
        var clickedRow = clickedRowParent;
        clickedRow[COST_FUNCTIONS_FIELDS.VALIDATION_STATUS] = "";
        clickedRow[COST_FUNCTIONS_FIELDS.IS_VALID] = COST_FUNCTIONS_FIELDS.VALIDATION_STATUS_ENUM.NOT_VALIDATED;
        if(rule){
            clickedRow[COST_FUNCTIONS_FIELDS.CONFIG][this.state.index][COST_FUNCTIONS_FIELDS.RULE] = translateRule(JSON.parse(JSON.stringify({rule: rule}).replaceAll("'","\"")).rule, this.props.costCenter);
        }
        if(translatedRule){
            clickedRow.translatedRule = translatedRule;
        }
        clickedRow[COST_FUNCTIONS_FIELDS.CONFIG][this.state.index][ROW_STATUS.FIELD] = ROW_STATUS.VALUES.EDITED;
        if(filterFromAPI!== undefined){
            clickedRow[COST_FUNCTIONS_FIELDS.IS_VALID] = clickedRow[COST_FUNCTIONS_FIELDS.RULE] !== '' ? COST_FUNCTIONS_FIELDS.VALIDATION_STATUS_ENUM.NOT_VALIDATED : COST_FUNCTIONS_FIELDS.VALIDATION_STATUS_ENUM.MISSING_RULE;
            clickedRow[COST_FUNCTIONS_FIELDS.CONFIG][obj.state.index][COST_FUNCTIONS_FIELDS.JSON_FILTER] = JSON.stringify(json_filter);
            clickedRow[COST_FUNCTIONS_FIELDS.CONFIG][obj.state.index][COST_FUNCTIONS_FIELDS.FILTER] = filterFromAPI;
        }
        for (var e in periods) {
            if (clickedRow[COST_FUNCTIONS_FIELDS.CONFIG][this.state.index][COST_FUNCTIONS_FIELDS.IS_DEFAULT]) {
                if (obj.periodExist(periods[e].value, clickedRow[COST_FUNCTIONS_FIELDS.CONFIG])) {
                    if (!clickedRow[COST_FUNCTIONS_FIELDS.CONFIG][this.state.index].periods.includes(periods[e].value)){
                        clickedRow[COST_FUNCTIONS_FIELDS.CONFIG][this.state.index].periods.push(periods[e].value)
                    }
                }
            }
        }
        // adding the periods that will be invalidated db side once user saves his changes
        for (var e in clickedRow[COST_FUNCTIONS_FIELDS.CONFIG][this.state.index].periods) {
            invalidatedPeriods.push({costKey:clickedRow[COST_FUNCTIONS_FIELDS.ID],period: clickedRow[COST_FUNCTIONS_FIELDS.CONFIG][this.state.index].periods[e]});
        }
        clickedRowParent = null;
        var data = this.tabulator.getData();
        let pslClickedRow = getEmbeddedChild(data,_children,COST_FUNCTIONS_FIELDS.ID,clickedRow[COST_FUNCTIONS_FIELDS.ID]);
        if(pslClickedRow!== null) {
            pslClickedRow[COST_FUNCTIONS_FIELDS.JSON_FILTER] =  clickedRow[COST_FUNCTIONS_FIELDS.CONFIG][obj.state.index][COST_FUNCTIONS_FIELDS.JSON_FILTER];
            pslClickedRow[COST_FUNCTIONS_FIELDS.FILTER] = clickedRow[COST_FUNCTIONS_FIELDS.CONFIG][obj.state.index][COST_FUNCTIONS_FIELDS.FILTER] 
        }
        this.updateAccrual(data, clickedRow, true, this.state.index, clickedRow[COST_FUNCTIONS_FIELDS.CONFIG][this.state.index].periods, invalidatedPeriods);
        this.tabulator.replaceData(data);
        this.tabulator.scrollToRow(elementParent);
        this.setState({
            clickedRow: clickedRow,
            showRule: false,
            showFilter: false,
            changed:true,
            build:true,
            invalidatedPeriods:invalidatedPeriods
        }, function(){
            obj.savedRowNumber = -1;
            obj.savedRowIndex = -1;
            this.reExpandCollapsedRows();
            obj.props.setIsChanged(true);
            // this.tabulator.scrollToRow(elementParent);
        })
    }

    onCancelFilter() {

        this.setState({
            showFilter: false,
        },function(){
            this.reExpandCollapsedRows();
            // this.filterRef.clear();
            this.tabulator.scrollToRow(elementParent /*this.state.element*/);
        });
    }

    onCancelRule() {
        this.setState({
            showRule: false,
        },function(){
            this.reExpandCollapsedRows();
            // this.tabulator.scrollToRow(elementParent /*this.state.element*/);
        });
    }

    shouldHaveRuleAndFiter(rowData) {
        var shouHave = true;
        if(["ASSIGNABLE", "", undefined].indexOf(rowData[COST_FUNCTIONS_FIELDS.AC_TYPE]) === -1) {
            shouHave = false;
        } 
        if([costtype.invoicelinetype, costtype.attribute, costtype.calculated].includes(rowData[COST_FUNCTIONS_FIELDS.COST_TYPE])) {
            shouHave = false;
        }
        if(rowData[COST_FUNCTIONS_FIELDS.RETURN_NAME] !== undefined &&[PSL_RETURN_NAMES.GROSS_PROFIT, PSL_RETURN_NAMES.NET_PROFIT].indexOf(rowData[COST_FUNCTIONS_FIELDS.RETURN_NAME].toLowerCase()) !== -1) {
            shouHave = false;
        }
        if(!rowData[COST_FUNCTIONS_FIELDS.COST_CENTER]) {
            shouHave = false;
        }
        return shouHave;
    }

    getColumnFormatter(colField) {
        var obj = this;
        var columnFormatter;	
        switch(colField) {
            case COST_FUNCTIONS_FIELDS.NAME:
            columnFormatter = function(cell, formatterParams) {
                var rowData = cell.getRow().getData();
                var p = document.createElement("p");
                p.textContent = cell.getValue();
                if(rowData.buildStatus === "NOT_BUILT"){
                        var redDotDiv = document.createElement("div");
                        redDotDiv.classList.add("column-red-dot");
                        p.appendChild(redDotDiv);
                }
                if(cell.getRow().getData()["level"] !== 1) { // moving each child level to the right to appear as a child of its parent
                    var pixels = (cell.getRow().getData()["level"]-1)*20;
                    $(p).css("padding-left", convertPxToViewport(pixels));
                }
                if (rowData['level'] === 1) { 
                    $(cell.getRow().getElement()).css({"background-color": "#f3f3f3"});
                    $(cell.getRow().getElement()).css({"border-color":"#DCDCDC"});
                } else if (rowData["level"] === 2){
                    $(cell.getRow().getElement()).css({"background-color": "rgba(202, 202, 202, 0.5)"});
                    $(cell.getRow().getElement()).css({"border-color":"#DCDCDC"});
                } else {
                    $(cell.getRow().getElement()).css({"background-color": "rgb(202, 202, 202, 0.8)"});
                    $(cell.getRow().getElement()).css({"border-color":"#cacaca"});
                }
                return p;
            }
            break;

			case COST_FUNCTIONS_FIELDS.SHOW_CHILDREN:
			columnFormatter = function(cell, formatterParams) {
                if(cell.getRow().getData()[COST_FUNCTIONS_FIELDS.COST_TYPE] === costtype.invoicelinetype){// return null if the line is splitted by transaction 
                    return ""
                }
                if(obj.props.viewMode && cell.getRow().getData()["children"] !== undefined){
                    var p = document.createElement("p");
                    p.innerHTML = cell.getRow().getData()[COST_FUNCTIONS_FIELDS.SHOW_CHILDREN] ? _yes : _no;
                    return p;
                } else{
                    if (cell.getRow().getData()["children"] !== undefined) {
                        let rowData = cell.getRow().getData();
                        //////////////////////SWITCH
                        let div = document.createElement("div");
                        div.classList.add("uk-flex","uk-flex-center");
                        let label = document.createElement("label");
                        label.classList.add("switch");
                        let input = document.createElement("input");
                        input.type = "checkbox";
                        input.name =  "chosenEntity";
                        input.classList.add("chosenEntity");
                        input.checked = rowData[COST_FUNCTIONS_FIELDS.SHOW_CHILDREN].toString() === "true";
                        input.value = input.checked ? 'off' : 'on';
                        input.onchange = (e) => {
                            obj.onCheckShowChildren(e, cell);
                        }
                        label.appendChild(input);
                        let span = document.createElement("span");
                        span.classList.add("slider","round");
                        label.appendChild(span)
                        div.appendChild(label);
                        return div;
                    }
                }   
            };
            break;

            case COST_FUNCTIONS_FIELDS.CHILDREN_ACCESS:
			columnFormatter = function(cell, formatterParams) {
                if (cell.getRow().getData()[COST_FUNCTIONS_FIELDS.SHOW_CHILDREN].toString() === "true" && cell.getRow().getData()[COST_FUNCTIONS_FIELDS.COST_TYPE] !== costtype.invoicelinetype) {
                    if (obj.props.viewMode && cell.getRow().getData()["children"] !== undefined) {
                        var p = document.createElement("p");
                        p.innerHTML = cell.getValue() && cell.getValue() !== "" ? obj.state.pss_restrictions.filter(e=>e.value === cell.getValue())[0].label : obj.state.pss_restrictions.filter(e=>e.value === obj.state.default_restriction)[0].label;
                        return p;
                    } else {
                        if (cell.getRow().getData()["children"] !== undefined) {
                            var div = document.createElement('div');
                            div.classList.add("uk-flex","uk-flex-center");
                            let select = createDropdown(["form-control", "table-form-control"]);
                            select.onchange = (e) => obj.handleRestrictionChange(e, cell);
                            select.style.width = "100%";
                            select.title = cell.getValue();
                            select.style.cursor = "pointer";
                            // select.classList.add("form-control", "table-form-control");
                            var data = obj.state.pss_restrictions;
                            
                            for (var field in data) {
                                let option = document.createElement("option");
                                option.value = data[field].value;
                                option.title = data[field].label;
                                option.innerHTML = data[field].label;
                                select.appendChild(option);
                            }
                            select.value = cell.getValue() && cell.getValue() !== "" ? cell.getValue() : obj.state.default_restriction;		//set the cell value as the selected option in the SELECT dropdown
                            div.appendChild(select);
                            return div;
                        }
                    }
                }   
            };
            break;

            case COST_FUNCTIONS_FIELDS.JSON_FILTER:
                columnFormatter = function(cell, formatterParams) {
                    if (cell.getRow().getData()[COST_FUNCTIONS_FIELDS.JSON_FILTER]) {
                        return cell.getRow().getData()[COST_FUNCTIONS_FIELDS.JSON_FILTER];
                    } else{
                        return "";
                    }
                }
            break;

            case COST_FUNCTIONS_FIELDS.FILTER:
            columnFormatter = function(cell, formatterParams) { 
                var rowData = cell.getRow().getData();
                var vectorOptions = obj.getVectorsForFilter(obj.state.vectorList);
                if(rowData[_children] === undefined && cell.getRow().getData()[COST_FUNCTIONS_FIELDS.COST_TYPE] !== costtype.invoicelinetype)  {
                    // view mode
                    if (obj.props.viewMode) { 
                        var mainPar = document.createElement("div");
                        for (var e in cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG]) {
                            //checking it line's row has no rule for the selected period
                            if (!rowData[COST_FUNCTIONS_FIELDS.CONFIG][e][COST_FUNCTIONS_FIELDS.FILTER] && (!rowData[COST_FUNCTIONS_FIELDS.CONFIG][e][COST_FUNCTIONS_FIELDS.JSON_FILTER] || rowData[COST_FUNCTIONS_FIELDS.CONFIG][e][COST_FUNCTIONS_FIELDS.JSON_FILTER]=== "{\"filter\":[]}") && rowData[COST_FUNCTIONS_FIELDS.CONFIG][e].periods.includes(obj.props.selectedPeriod.value) ||
                                (!rowData[COST_FUNCTIONS_FIELDS.CONFIG][e][COST_FUNCTIONS_FIELDS.FILTER] && (!rowData[COST_FUNCTIONS_FIELDS.CONFIG][e][COST_FUNCTIONS_FIELDS.JSON_FILTER] || rowData[COST_FUNCTIONS_FIELDS.CONFIG][e][COST_FUNCTIONS_FIELDS.JSON_FILTER]=== "{\"filter\":[]}") && rowData[COST_FUNCTIONS_FIELDS.CONFIG][e].periods.length== 0 && obj.periodExist(obj.props.selectedPeriod.value, cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG]))){
                                var cellContainer  = document.createElement("div");
                                cellContainer.innerHTML = MESSAGES.filter_not_define;
                                mainPar.appendChild(cellContainer);
                                // checking if rule exists and if the selected period is within the periods of the row
                            } else if (rowData[COST_FUNCTIONS_FIELDS.CONFIG][e].periods.length !== 0 && rowData[COST_FUNCTIONS_FIELDS.CONFIG][e].periods.includes(obj.props.selectedPeriod.value)){
                                var queryFilter = getReadableFilterText(tryParse(rowData[COST_FUNCTIONS_FIELDS.CONFIG][e][COST_FUNCTIONS_FIELDS.JSON_FILTER], "") ? tryParse(rowData[COST_FUNCTIONS_FIELDS.CONFIG][e][COST_FUNCTIONS_FIELDS.JSON_FILTER], "").filter : "", vectorOptions);
                                var cellContainer  = document.createElement("div");
                                cellContainer.innerHTML = queryFilter.replace("_cc","");
                                cellContainer.classList.add("uk-display-block");
                                mainPar.appendChild(cellContainer);
                                //checking if rule exists and line is default i-e no periods are fetched from db 
                            } else if (rowData[COST_FUNCTIONS_FIELDS.CONFIG][e].periods.length== 0 && obj.periodExist(obj.props.selectedPeriod.value, cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG])) {
                                var queryFilter = getReadableFilterText(tryParse(rowData[COST_FUNCTIONS_FIELDS.CONFIG][e][COST_FUNCTIONS_FIELDS.JSON_FILTER], "") ? tryParse(rowData[COST_FUNCTIONS_FIELDS.CONFIG][e][COST_FUNCTIONS_FIELDS.JSON_FILTER], "").filter : "", vectorOptions);
                                var cellContainer  = document.createElement("div");
                                cellContainer.innerHTML = queryFilter.replace("_cc","");
                                mainPar.appendChild(cellContainer);
                            }
                        }
                       return mainPar;
                    }else {
                        var mainPar = document.createElement("div");
                        mainPar.classList.add("uk-flex", "uk-flex-column")
                        for (var e in cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG]) {
                            // input.classList.add("uk-cursor-pointer");
                            var cellContainer = document.createElement("div");
                            // if row is a parent or an invoilinetype user shouldnt be able to define its filter 
                            if (!rowData[COST_FUNCTIONS_FIELDS.COST_CENTER] || rowData[COST_FUNCTIONS_FIELDS.COST_TYPE] === costtype.invoicelinetype) {
                                var cellContainer = document.createElement("div");
                                cellContainer.classList.add("ccf-configure-cell", "uk-flex-inline", "uk-flex-middle");
                                var a = document.createElement("span");
                                var link = document.createTextNode(MESSAGES.define_filter);
                                cellContainer.title = MESSAGES.define_filter;
                                a.appendChild(link);
                                a.classList.add('disabled'/*, "uk-cursor-pointer"*/);
                                cellContainer.appendChild(a);
                                if (rowData[STATUS] === ACCRUALS.FIELDS.STATUS_VALUES.ACCRUAL) {
                                    cellContainer.classList.add("disabled");
                                }
                                 mainPar.appendChild(cellContainer);
                            } else {
                                // if user can define a filter for this line
                                var queryFilter = getReadableFilterText(tryParse(cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG][e][COST_FUNCTIONS_FIELDS.JSON_FILTER], "") ? tryParse(cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG][e][COST_FUNCTIONS_FIELDS.JSON_FILTER], "").filter : "", vectorOptions);
                                var a = document.createElement("span");
                                a.id = e;
                                if(queryFilter !== "") { // defined filter
                                    var a = document.createElement("span");
                                    var link = document.createTextNode(queryFilter.replace("_cc",""));
                                    a.id = e;
                                    cellContainer.title = queryFilter.replace("_cc","");
                                    cellContainer.classList.add("configure-cost-function-filter-ellipsis", "configure-mode");
                                    a.appendChild(link); 
                                    cellContainer.appendChild(a);
                                } else { // undefined filter
                                    var link = document.createTextNode(MESSAGES.define_filter);
                                    cellContainer.title = MESSAGES.define_filter;
                                    a.appendChild(link); 
                                    a.id = e;
                                    cellContainer.appendChild(a);
                                }
                                var div = document.createElement("div");
                                div.classList.add("ccf-configure-cell", "uk-flex-inline", "uk-flex-middle");
                                div.appendChild(cellContainer);
                                if (rowData[STATUS] === ACCRUALS.FIELDS.STATUS_VALUES.ACCRUAL) {
                                    div.classList.add("disabled");
                                }
                                mainPar.appendChild(div);
                            }
                        }
                        if (cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG]) {
                            var height = cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG].length *40
                            $(cell.getRow().getElement()).css({"height": convertPxToViewport(height)})
                        }
                        return mainPar;
                    }
                    
                }
            }
            break;


            case COST_FUNCTIONS_FIELDS.RULE:
            columnFormatter = function(cell, formatterParams) { 
                var rowData = cell.getRow().getData();
                var vectorOptions = obj.getVectorsForFilter(obj.state.vectorList);
                let metricFields = obj.state.metricFields;
                // view mode 
                if (obj.props.viewMode && !rowData[COST_FUNCTIONS_FIELDS.CHILDREN]) {
                    var mainPar = document.createElement("div");
                    for (var e in cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG]) {
                        // undefined rule within the selected period
                        if ((!rowData[COST_FUNCTIONS_FIELDS.CONFIG][e][COST_FUNCTIONS_FIELDS.RULE] && rowData[COST_FUNCTIONS_FIELDS.CONFIG][e].periods.includes(obj.props.selectedPeriod.value)) ||
                            (!rowData[COST_FUNCTIONS_FIELDS.CONFIG][e][COST_FUNCTIONS_FIELDS.RULE] && rowData[COST_FUNCTIONS_FIELDS.CONFIG][e].periods.length == 0 && obj.periodExist(obj.props.selectedPeriod.value, cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG]))) {
                            var cellContainer  = document.createElement("div");
                            var div  = document.createElement("div");
                            if(rowData[COST_FUNCTIONS_FIELDS.COST_CENTER]){
                                div.innerHTML = MESSAGES.default_rule;
                                div.classList.add("uk-display-inline-block");
                            }else{
                                div.innerHTML = MESSAGES.define_rule;
                                div.classList.add("red", "disabled");
                            }
                            
                            cellContainer.appendChild(div);
                            mainPar.appendChild(cellContainer);
                            // defined rule with periods containg the selected period
                        } else if (rowData[COST_FUNCTIONS_FIELDS.CONFIG][e].periods.length !== 0 && rowData[COST_FUNCTIONS_FIELDS.CONFIG][e].periods.includes(obj.props.selectedPeriod.value)){
                            var readableText = cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG][e].rule === "" ? cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG][e].rule : cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG][e].rule ?  getReadableRuleText(cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG][e].rule, vectorOptions,metricFields) : "";
                            var cellContainer  = document.createElement("div");
                            if(rowData[COST_FUNCTIONS_FIELDS.COST_TYPE] === costtype.invoicelinetype) {
                                readableText = cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG][e].rule;
                                cellContainer.classList.add("disabled");
                            }
                            cellContainer.innerHTML = readableText.replace("_cc","");
                            mainPar.appendChild(cellContainer);
                            //defined rule of a default row that has no periods
                        } else if (rowData[COST_FUNCTIONS_FIELDS.CONFIG][e].periods.length == 0 && obj.periodExist(obj.props.selectedPeriod.value, cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG])) {
                            var readableText = cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG][e].rule === "" ? cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG][e].rule : cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG][e].rule ?  getReadableRuleText(cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG][e].rule, vectorOptions,metricFields) : "";
                            var cellContainer  = document.createElement("div");
                            if(rowData[COST_FUNCTIONS_FIELDS.COST_TYPE] === costtype.invoicelinetype) {
                                readableText = cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG][e].rule;
                                cellContainer.classList.add("disabled");
                            }
                            cellContainer.innerHTML = readableText.replace("_cc","");
                            mainPar.appendChild(cellContainer);
                        }
                    }    
                    return mainPar;
                }
                // configure mode
                // checking if row is a leaf and not invoicelinetype
                if(rowData[COST_FUNCTIONS_FIELDS.CHILDREN] === undefined && rowData[COST_FUNCTIONS_FIELDS.COST_TYPE] !== costtype.invoicelinetype && !obj.props.viewMode)  {
                    var mainPar = document.createElement("div");
                    mainPar.classList.add("uk-flex", "uk-flex-column");
                    for (var e in cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG]) {
                        if (!rowData[COST_FUNCTIONS_FIELDS.COST_CENTER]) { // not mapped costkey  
                            var cellContainer = document.createElement("div");
                            var a = document.createElement("span");
                            var link = document.createTextNode(MESSAGES.define_rule);
                            cellContainer.title = MESSAGES.define_rule;
                            a.appendChild(link); 
                            a.classList.add("red", 'disabled');
                            cellContainer.appendChild(a);
                            if (rowData[STATUS] === ACCRUALS.FIELDS.STATUS_VALUES.ACCRUAL) {
                                cellContainer.classList.add("disabled");
                            }
                            // i.disabled = true;
                            mainPar.appendChild(cellContainer);
                        }  else {                        
                            var readableText = cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG][e].rule === "" ? cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG][e].rule : cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG][e].rule ?  getReadableRuleText(cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG][e].rule, vectorOptions, metricFields) : "";
                            var a = document.createElement("span");
                            var cellContainer = document.createElement("div");
                            if (cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG][e].rule === "") { // has no defined rule
                                var link = document.createTextNode(MESSAGES.default_rule);
                                cellContainer.title = MESSAGES.default_rule;
                                a.appendChild(link); 
                                // a.classList.add("red");
                                cellContainer.appendChild(a);
                            } else{ // has defined rule
                                var link = document.createTextNode(readableText.replace("_cc",""));
                                cellContainer.title = readableText.replace("_cc","");
                                a.appendChild(link); 
                                cellContainer.classList.add("configure-cost-function-filter-ellipsis", "configure-mode");
                                cellContainer.appendChild(a);
                            }
                            a.id = e;
                            var div = document.createElement("div");
                            div.classList.add("ccf-configure-cell", "uk-flex-inline", "uk-flex-middle");
                            div.appendChild(cellContainer);
                            if (rowData[STATUS] === ACCRUALS.FIELDS.STATUS_VALUES.ACCRUAL) {
                                div.classList.add("disabled"); //disabling rule for accrual lines
                            }
                            mainPar.appendChild(div);
                        }
                    }
                    return mainPar
                } 
                else if(rowData[COST_FUNCTIONS_FIELDS.CHILDREN] ) { // a parent => no rule should be defined
                    // let cellContainer = document.createElement("div");
                    // var rule = "ID Σ ("
                    // for(var e in rowData[COST_FUNCTIONS_FIELDS.CHILDREN]) {
                    //     rule += rowData[COST_FUNCTIONS_FIELDS.CHILDREN][e][COST_FUNCTIONS_FIELDS.ID];
                    //     rule += Number(e) !== rowData[COST_FUNCTIONS_FIELDS.CHILDREN].length -1 ? " ," : ""
                    // }
                    // rule += " )";
                    // cellContainer.textContent = rule.replace("_cc","");
                    // cellContainer.title = rule.replace("_cc","");
                    // return cellContainer;
                    return "";
                }
                else if (rowData[COST_FUNCTIONS_FIELDS.COST_TYPE] === costtype.invoicelinetype) { // invoicelinetype = > disabled
                    var message = rowData["rule"] && rowData["rule"] !== "" ? rowData["rule"].replace("_cc","") :  MESSAGES.define_rule;
                    var cellContainer = document.createElement("div");
                    var a = document.createElement("span");
                    var link = document.createTextNode(message);
                    a.appendChild(link); 
                    a.classList.add("red","disabled");
                    a.disabled = true
                    cellContainer.appendChild(a);
                    return cellContainer;
                }
            }
            break;

            case COST_FUNCTIONS_FIELDS.COST_CENTER:
            columnFormatter = function(cell, formatterParams) {
                if(cell.getRow().getData()["children"] === undefined && cell.getValue() !== undefined && cell.getRow().getData()[COST_FUNCTIONS_FIELDS.COST_TYPE] !== costtype.invoicelinetype)  {
                    if(cell.getValue() === "ALL") {
                        return _no;
                    } else {
                        return _yes;
                    }
                }
            }
            break;

            case COST_FUNCTIONS_FIELDS.AMOUNT:
                columnFormatter = function (cell, formatterParams) {    
                var costKey = cell.getRow().getData()[COST_FUNCTIONS_FIELDS.ID];
                var allLeafNodes = leafNodes ? leafNodes : [];
                    
                let div = document.createElement("div");
                var amount = cell.getValue();
                if (allLeafNodes.length > 0 ) {
                    allLeafNodes.map(function(item){
                        if(item[COST_FUNCTIONS_FIELDS.ID] === costKey){
                            amount = isNaN(item["amount"]) ? 0 :  parseFloat(item["amount"]) ;
                        } else if (item[COST_FUNCTIONS_FIELDS.PARENT_COST_KEY] === costKey ||
                            obj.isGrandChild(cell.getRow().getData(), item[COST_FUNCTIONS_FIELDS.ID])) {
                            amount += parseFloat(isNaN(item["amount"]) ? 0 : parseFloat(item["amount"]));
                        }
                    });
                }

                if (cell.getRow().getData()[STATUS] ===  ACCRUALS.FIELDS.STATUS_VALUES.ACCRUAL && !obj.state.isYearBuilt) {
                    div.innerHTML = formatValHTML("N/A", FormatTypes.TEXT);
                }else{
                    div.innerHTML = formatValHTML(amount, FormatTypes.AMOUNT);
                    if(Number(amount)< 0) {
                        div.classList.add("red");
                    }

                    var costCenter = cell.getRow().getData()[COST_FUNCTIONS_FIELDS.COST_CENTER];
                    
                    if((costCenter !== "ALL" && costCenter !== "" && costCenter !== 'undefined' && costCenter !== undefined) && (Number(amount) !==  '0' && Number(amount) !== 0)) {
                        $(div).addClass("uk-cursor-pointer uk-text-decoration-underline");
                        div.onclick = () => {
                            obj.getAmountPerCostKey(cell);
                        }
                    }
                }   
                return div;
            }
            break;

            case COST_FUNCTIONS_FIELDS.IS_VALID:
            columnFormatter = function (cell, formatterParams) { 
                var rowPosition = cell.getRow().getPosition();
                var period = typeof obj.props.selectedPeriod === 'object' ? obj.props.selectedPeriod.value : obj.props.selectedPeriod;
                if(cell.getRow().getData()["children"] === undefined && cell.getRow().getData()[COST_FUNCTIONS_FIELDS.COST_TYPE] !== INVOICELINETYPE) {
                    if (cell.getRow().getData()[COST_FUNCTIONS_FIELDS.COST_CENTER] === undefined || cell.getRow().getData()[COST_FUNCTIONS_FIELDS.COST_CENTER] === "" || cell.getRow().getData()[COST_FUNCTIONS_FIELDS.COST_TYPE] === INVOICELINETYPE) {
                        return "";
                    } else {
                        var div = document.createElement("div");
                        div.classList.add("configure-tooltip");

                        let img = document.createElement("i");
                        switch(cell.getRow().getData()[COST_FUNCTIONS_FIELDS.IS_VALID]) {
                            case COST_FUNCTIONS_FIELDS.VALIDATION_STATUS_ENUM.NOT_VALIDATED:
                                //remove "disabled" class for (validate all) icon
                                if(!obj.disableValidateButton(period)) {
                                    $("#validate-all-icon").removeClass("disabled");
                                }
                                img.classList.add("fas", "fa-clock", "fa-xs", "disabled-grey");
                                break;
                            case COST_FUNCTIONS_FIELDS.VALIDATION_STATUS_ENUM.MISSING_RULE:
                                // img.classList.add("fas", "fa-cog");
                                break;
                            case COST_FUNCTIONS_FIELDS.VALIDATION_STATUS_ENUM.WARNING:
                                img.classList.add("fas", "fa-exclamation", "uk-cursor-pointer", "fa-lg", "pi-text-yellow");
                                break;
                            case COST_FUNCTIONS_FIELDS.VALIDATION_STATUS_ENUM.PENDING:
                                img = document.createElement("img");
                                img.src = '/images/FhHRx.gif';
                                img.style.width= convertPxToViewport(15);
                                img.style.height= convertPxToViewport(15);
                                break;
                            case COST_FUNCTIONS_FIELDS.VALIDATION_STATUS_ENUM.VALID:
                                img.classList.add("fas", "fa-check", "uk-cursor-pointer", "fa-xs");
                                break;
                        }
                        if (cell.getValue() === COST_FUNCTIONS_FIELDS.VALIDATION_STATUS_ENUM.WARNING ||
                            cell.getValue() === COST_FUNCTIONS_FIELDS.VALIDATION_STATUS_ENUM.VALID) {
                            img.onclick = () => {
                                var toolTipdiv = obj.createTooltip(cell);
                                cell.getElement().style.overflow = "visible";   //setting tooltip position over the clicked cell
                                $(toolTipdiv).css({
                                    position: "absolute",
                                    top: $(img).position().top - 53,
                                    right: $(img).position().left + 22
                                });

                                var oldTooltip = $("#toggleTooltipConfigure");
                                if(oldTooltip.length) {     //remove the previously created tooltips for this cell
                                    oldTooltip.remove();
                                }

                                var icon = document.createElement("div");
                                icon.classList.add("tooltip-configure", "cursor-mouse");
                                icon.setAttribute("uk-toggle", "mode:click; target: #toggleTooltipDiv" + rowPosition);
                                icon.setAttribute("id", "toggleTooltipConfigure");
                                obj.createDocumentClick();
                                icon.appendChild(toolTipdiv);
                                img.appendChild(icon);
                            }
                        } else {
                            img.onclick = () => {};
                        }
                        div.appendChild(img);
                        return div;
                    }
                }
            };
            break;
            case COST_FUNCTIONS_FIELDS.CONFIGURED_PERIODS:
                columnFormatter = function(cell, formatterParams) {
                    var mainPar = document.createElement("p");
                    if (!cell.getRow().getData()[_children] && cell.getRow().getData()[COST_FUNCTIONS_FIELDS.COST_CENTER] && cell.getRow().getData()[COST_FUNCTIONS_FIELDS.COST_TYPE] !== INVOICELINETYPE) {
                        for (var e in cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG]) {
                            var div = document.createElement("div");
                            var cellContainer = document.createElement("div");
                            var a = document.createElement("a");
                            cellContainer.id = "period-drop-down";
                            a.id = e;
                            a.innerHTML = cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG][e][COST_FUNCTIONS_FIELDS.IS_DEFAULT] ? cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG].length === 1 ? MESSAGES.all : MESSAGES.view_periods : MESSAGES.select_periods;
                            if (a.innerHTML !== MESSAGES.all) { // more than one row is added = > should be clickablee
                                a.classList.add("uk-cursor-pointer");
                            } else{
                                a.classList.add("decoration");
                            }
                            
                            if ( cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG][e].periods.length === 0 && !cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG][e][COST_FUNCTIONS_FIELDS.IS_DEFAULT]) {
                                a.classList.add("red"); // row added with undefined periods => colored red
                            }
                            if (a.innerHTML !== MESSAGES.all) {
                                a.onclick = (e)=>{obj.showPeriodComp(cell, e)}
                            }
                            if(obj.props.scenarioStatus !== _sandbox && a.innerHTML !== MESSAGES.all){
                                a.classList.add("disabled");
                                cellContainer.setAttribute("uk-tooltip",lang.configure_vectors_checkout_validation);
                                cellContainer.classList.add("disabled-with-events");
                            }
                            cellContainer.appendChild(a);
                            // if row is default view periods should appear instead of select periods
                            if(cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG][e][COST_FUNCTIONS_FIELDS.IS_DEFAULT]) {
                                var icon = document.createElement("i");
                                icon.id = e+"_icon"+cell.getRow().getData()[COST_FUNCTIONS_FIELDS.ID];
                                icon.setAttribute("uk-tooltip",MESSAGES.warning_periods.replace('{x}',obj.state.periods));
                                icon.classList.add("fa-lg","fas" ,"fa-exclamation","pi-text-yellow","uk-cursor-pointer","uk-hidden","uk-margin-xsmall-left");
                                if (a.innerHTML !== MESSAGES.all) { // if row default and line has more than one row and a period is unchecked from any non default row it should appear in a warning icon next to the default row
                                    cellContainer.appendChild(icon);
                                }
                            }
                            div.classList.add("ccf-configure-cell");
                            if(cell.getRow().getData()[STATUS] === ACCRUALS.FIELDS.STATUS_VALUES.ACCRUAL) { // disabling accrual line
                                div.classList.add("uk-disabled");
                                a.classList.add("disabled");
                            }
                            div.appendChild(cellContainer)
                            mainPar.appendChild(div);
                        }
                        return mainPar; 
                    }
                };
            break;

            case COST_FUNCTIONS_FIELDS.DEFAULT:
                columnFormatter = function(cell, formatterParams) {
                    if (!cell.getRow().getData()[_children] && cell.getRow().getData()[COST_FUNCTIONS_FIELDS.COST_CENTER] && cell.getRow().getData()[COST_FUNCTIONS_FIELDS.COST_TYPE] !== INVOICELINETYPE) {
                        var mainPar = document.createElement("div");
                        for (var e in cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG]) {
                            var div = document.createElement("div");
                            var cellContainer = document.createElement("div");
                            var span = document.createElement("span");
                            span.classList.add("uk-margin-remove");
                            if (cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG][e][COST_FUNCTIONS_FIELDS.IS_DEFAULT] || cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG][e][COST_FUNCTIONS_FIELDS.IS_DEFAULT] === "true") {
                                //  row is by default checked if only one row exists
                                span.innerHTML = _yes;
                            }
                            else {
                                span.innerHTML = " ";
                            }
                            if (cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG].length === 1) {
                                cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG][e][COST_FUNCTIONS_FIELDS.IS_DEFAULT] = true;
                                span.innerHTML = _yes;
                            } else {
                                // radio.onclick= (evt) => {
                                //     obj.setRowToDefault(evt, cell);
                                // }
                            }
                            if(cell.getRow().getData()[STATUS] === ACCRUALS.FIELDS.STATUS_VALUES.ACCRUAL) {
                                div.classList.add("uk-disabled"); //disabling defalut radio button for accrual lines
                            }
                            cellContainer.appendChild(span);
                            div.classList.add("ccf-configure-cell", "uk-flex", "uk-flex-between");
                            div.appendChild(cellContainer)
                            mainPar.appendChild(div);
                        }
                        return mainPar;  
                    }
                };
            break;

            case COST_FUNCTIONS_FIELDS.ADD:
                columnFormatter = function(cell, formatterParams) {
                    let dotsButtonContainer = document.createElement("div");
                    let rowData = cell.getRow().getData();
                    for (var e in cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG]) {
                        if (rowData[COST_FUNCTIONS_FIELDS.CONFIG][e][ROW_STATUS.FIELD] === ROW_STATUS.VALUES.NEW || (!rowData[_children] && rowData[COST_FUNCTIONS_FIELDS.COST_CENTER] && rowData[COST_FUNCTIONS_FIELDS.COST_TYPE] !== costtype.invoicelinetype && rowData[COST_FUNCTIONS_FIELDS.COST_TYPE] !== costtype.accrued)) {
                            let rowCostKey = rowData.costKey;
                            /* actions three dotted dropdown button container*/
                            let dropdownContainer = document.createElement("div");
                            dropdownContainer.id = "cost_function_drop_down_"+rowCostKey;
                            dropdownContainer.setAttribute("uk-dropdown", "mode: click");
                            
                            /* three dotted button*/
                            let dotsButton = getTableIconButton(["fa-2x", "fal", "fa-ellipsis-v"], ["uk-button-icon", "dots-button"]);
                            if(e==="0"){// [ROW_STATUS.FIELD] === ROW_STATUS.VALUES.NEW
                                dotsButton.id = "cost_function_action_"+rowCostKey;
                            }else{
                                dotsButton.id = "cost_function_action_"+rowCostKey+"_"+e;
                            }
                            dotsButton.style.display = "none";
                            dotsButtonContainer.classList.add("uk-inline");
                            dotsButtonContainer.onclick = ()=> {obj.saveClickPosition(cell,e)};// save the position of the row we clicked on
                            if(obj.props.scenarioStatus !== _sandbox){
                                dotsButton.classList.add("disabled");
                                dotsButtonContainer.setAttribute("uk-tooltip",lang.configure_vectors_checkout_validation);
                                dotsButtonContainer.classList.add("disabled-with-events");
                            }
                            /*edit button*/
                            let editIconDiv = getTableButton(
                                lang.edit_rule_or_filter,
                                [],
                                ["justify-content-start", "uk-button-icon"],
                                ["fa-lg", "fal", "fa-edit", "uk-margin-small-right"],
                                "left",
                                lang.edit_rule_or_filter
                            ); 
                    
                            editIconDiv.id = e;
                            editIconDiv.onclick = (evt)=>{
                                obj.setClickedRow(evt, cell);
                                obj.showRulePopUp();
                            };
                            /* split button*/
                            let splitIconDiv = getTableButton(
                                lang.split_rule_or_filter,
                                [],
                                ["justify-content-start", "uk-button-icon"],
                                ["fa-lg", "fal","fa-plus-circle", "uk-margin-small-right"],
                                "left",
                                lang.split_rule_or_filter
                            ); 
                            splitIconDiv.id = e;
                            splitIconDiv.onclick = (event) =>{
                                obj.addNewFilterAndRule(event,cell);
                            }
                            /* set as default button*/
                            let defaultIconDiv = getTableButton(
                                lang.set_as_default,
                                [],
                                ["justify-content-start", "uk-button-icon"],
                                ["fa-lg", "fal","fa-check-circle", "uk-margin-small-right", "black"],
                                "left",
                                lang.set_as_default
                            ); 
                            defaultIconDiv.id = e
                            defaultIconDiv.onclick = (evt) =>{
                                obj.setRowToDefault(evt, cell);
                            }

                            /* delete button */
                            let deleteIconDiv = getTableButton(
                                lang.delete,
                                [],
                                ["justify-content-start", "uk-button-icon"],
                                ["fal", "fal", "fa-trash-alt", "uk-margin-small-right"],
                                "left",
                            ); 
                            deleteIconDiv.id = e;
                            deleteIconDiv.onclick=(evt)=>{
                                obj.deleteRuleAndFilterRow(evt, cell);
                            }

                            dropdownContainer.appendChild(editIconDiv);
                            dropdownContainer.appendChild(splitIconDiv);
                            if(!rowData[COST_FUNCTIONS_FIELDS.CONFIG][e][COST_FUNCTIONS_FIELDS.IS_DEFAULT]){
                                dropdownContainer.appendChild(defaultIconDiv);
                            }
                            if(!rowData[COST_FUNCTIONS_FIELDS.CONFIG][e][COST_FUNCTIONS_FIELDS.IS_DEFAULT] && rowData[STATUS] !== ACCRUALS.FIELDS.STATUS_VALUES.ACCRUAL) {
                                dropdownContainer.appendChild(deleteIconDiv);
                            }

                            dotsButtonContainer.appendChild(dotsButton);
                            dotsButtonContainer.appendChild(dropdownContainer);
                        }
                    }
                    return dotsButtonContainer;
                };
            break;
            default:      
            columnFormatter = function(cell, formatterParams) {
                if(cell.getRow().getData()[COST_FUNCTIONS_FIELDS.COST_TYPE] !== costtype.invoicelinetype){
                    return cell.getValue();
                }
            }
            break;
		}
		
		return columnFormatter;
    }


    /**
     *  save the position of the row we clicked on
     * @param {*} cell 
     */
    saveClickPosition=(cell,e)=>{
        let _this = this;
        if(_this.savedRowNumber >=0){// hide the previous three dots icon -if exists-
            $("#cost_function_action_"+_this.savedRowNumber).css("display", "none");
        }
        if(_this.savedRowIndex>=0){
            $("#cost_function_action_"+_this.savedRowNumber+"_"+_this.savedRowIndex).css("display", "none");
        }
        let rowNumber = cell.getRow().getData().costKey;
        $("#cost_function_action_"+rowNumber).css("display", "block");
        this.savedRowNumber = rowNumber;
        this.savedRowIndex = e;
    }

    addNewFilterAndRule(event, cell) {
        let _this = this;
        var rowData = cell.getRow().getData();
        rowData[COST_FUNCTIONS_FIELDS.CONFIG].push({rule:CONFIGURE_VARS.default.rule , filter:"", periods:[], json_filter:"", [ROW_STATUS.FIELD]: ROW_STATUS.VALUES.NEW});
        _this.setClickedRow(event,cell);
        _this.ruleRef.collectAndSaveRule();//to save count by default
        this.setState({
            changed:true,
            build:true,
            modifiedPeriods:[],
            deselectedPeriods: []
        },function(){
            _this.tabulator.updateRow(rowData[COST_FUNCTIONS_FIELDS.ID],rowData);
            _this.props.setIsChanged(true);
            $('#cost_functions .tabulator-tableHolder').animate({
                scrollTop:  globaltop
            },100)

        })

    }

    //checking if a period exist in any of the config rows of a ps line
    periodExist(period, config) {
        var found = false;
        for (var e in config) {
            if (!config[e][COST_FUNCTIONS_FIELDS.IS_DEFAULT]) {
                if (config[e].periods.includes(period)) {
                    found = true
                }
            }
        }
        return !found;
    }

    showPeriodComp(cell, evt) {
        var periods = this.props.periods;
        var is_default = false;
        var obj = this;
        var index = $(evt.currentTarget).attr("id");
        // when clicking on periods drop down of defalut line we're fetching the periods that are not checked 
        //in other rows and adding them here since default lines has no period record on db
        for (var e in periods) {
            if (cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG][index].periods.includes(periods[e].value)) {
                periods[e].checked = true;
            } else {
                periods[e].checked = false;
            }
            if (cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG][index][COST_FUNCTIONS_FIELDS.IS_DEFAULT]) {
                if (obj.periodExist(periods[e].value, cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG])) {
                    if (!cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG][index].periods.includes(periods[e].value)){
                        cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG][index].periods.push(periods[e].value)
                    }
                    periods[e].checked = true;
                }else {
                    periods[e].checked = false;
                }
                periods[e].disabled = true;
            } else {
                periods[e].disabled = false;
            }
        }
        if (cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG][index][COST_FUNCTIONS_FIELDS.IS_DEFAULT]) {
            is_default = true;
        }
        this.setState({
            periods: periods,
            is_default: is_default,
            index: index,
            rowData: cell.getRow().getData(),
            modifiedPeriods:[],
            deselectedPeriods:[]
            // showPeriodComp: true
        },function(){
            obj.periodRef.setState({
                filteredText: ""
            })
            $(".div-periods-drop-down").animate({ scrollTop: 0 }, "fast")
            $("#filterText").val("")
            $("#periods_comp").removeClass("uk-hidden");
            $("#periods_comp_with_message").removeClass("uk-hidden");
            //positioning the periods component to open below the cell directly
            $("#periods_comp").css({
                top:($(evt.currentTarget).offset().top - $("#periods_comp").height()) + 240,
                left:$(evt.currentTarget).offset().left 
            })
            $("#periods_comp_with_message").css({
                top:($(evt.currentTarget).offset().top - $("#periods_comp_with_message").height()) + 300,
                left:$(evt.currentTarget).offset().left
            })
        })
    } 

    /**
     * function shows the hidden warning icon with the perods unchecked from non default lines and  hides it after 5 secs
     * @param {*} index 
     * @param {*} id 
     * @param {*} period 
     */
    envokeWarning(index, id, period) {
        if (period.length > 0) {
            $("#"+index+"_icon"+id).removeClass("uk-hidden");
            $("#"+index+"_icon"+id).attr('uk-tooltip', MESSAGES.warning_periods.replace('{x}',period.join(', ')));
            setTimeout(function(){
                $("#"+index+"_icon"+id).addClass("uk-hidden");
            }, 100000);
        }
    }
    /**
     * adding periods checked in periods drop down to the cell config rows of the modified row 
     * @param {*} period 
     * @param {*} show 
     */ 
    
    addPeriods(period, show) {
        var _this = this;
        var invalidatedPeriods = _this.state.invalidatedPeriods || [];
        for (var e in period) {
            invalidatedPeriods.push({costKey:_this.state.rowData[COST_FUNCTIONS_FIELDS.ID],period: period[e]});
        }
        var data = this.tabulator.getData();
         _this.findRow(data, _this.state.rowData[COST_FUNCTIONS_FIELDS.ID], show, period, undefined, _this.state.rowData);
         var newData = copyObjectValues(data);
         this.updateAccrual(newData, _this.state.rowData, false, this.state.index, period, invalidatedPeriods);
         var defaultIndex = _this.state.rowData[COST_FUNCTIONS_FIELDS.CONFIG].findIndex(elt=>elt[COST_FUNCTIONS_FIELDS.IS_DEFAULT]);
         this.tabulator.replaceData(newData);
         this.reExpandCollapsedRows();
         var modified = getUniqueArrayValues(this.state.modifiedPeriods.concat(period));
         
        this.setState({
            invalidatedPeriods: invalidatedPeriods,
            changed: true,
            build:true,
            modifiedPeriods: modified
        },function(){
            if (!show) {
                for (var e in period) {
                    this.state.deselectedPeriods.push(period[e]);
                }
                _this.envokeWarning(defaultIndex, _this.state.rowData[COST_FUNCTIONS_FIELDS.ID], getUniqueArrayValues(this.state.deselectedPeriods));
            } else {
                this.setState({
                    deselectedPeriods: this.state.deselectedPeriods.splice(this.state.deselectedPeriods.indexOf(period), 1)
                })
            }
            _this.props.setIsChanged(true);
        })
        
    }

    /**
     * function deletes the unchecked period from config row that's modified and adds it to default row
     * function adds the checked period from config row that's modified and deletes it from the row it exists in
     * @param {*} arr 
     * @param {*} index 
     * @param {*} period 
     * @param {*} show 
     */
    deleteIndexRowPeriod(arr, index, period, show) {
        for (var elm in arr) {
            if (show) {
                if (elm !== index) {
                    for (var i in period) {
                        var delDefIndex = arr[elm].periods.findIndex(elt=>elt === period[i]);
                        if (delDefIndex !== -1) {
                            arr[elm].periods.splice(delDefIndex, 1);
                            arr[elm][ROW_STATUS.FIELD] = ROW_STATUS.VALUES.EDITED;
                        }
                    }
                } 
            }else{
                if (elm === index) {
                    for (var i in period) {
                        var delDefIndex = arr[elm].periods.findIndex(elt=>elt === period[i]);
                        if (delDefIndex !== -1) {
                            arr[elm].periods.splice(delDefIndex, 1);
                            arr[elm][ROW_STATUS.FIELD] = ROW_STATUS.VALUES.EDITED;
                        }
                    }
                } 
            }
            
        }
        return arr;
    }

    /* function updates the period selected or unselected for the psl row 
    **/
    findRow(data, id, show, period, index, row) {
        var _this = this;
        var sameRow = {};
        for (var e in data) {
            if (data[e][COST_FUNCTIONS_FIELDS.ACTUAL_ID] == sameRow[COST_FUNCTIONS_FIELDS.PSS_ID]) {
                data[e][COST_FUNCTIONS_FIELDS.CONFIG] = copyObjectValues(sameRow[COST_FUNCTIONS_FIELDS.CONFIG]);
            }
            if (data[e][COST_FUNCTIONS_FIELDS.ID] === id){
                var defaultIndex = data[e][COST_FUNCTIONS_FIELDS.CONFIG].findIndex(elt=>elt[COST_FUNCTIONS_FIELDS.IS_DEFAULT]);
                if (show) {
                    data[e][COST_FUNCTIONS_FIELDS.CONFIG] = this.deleteIndexRowPeriod(data[e][COST_FUNCTIONS_FIELDS.CONFIG], _this.state.index, period, show);
                    for (var em in period) {
                        if ( !data[e][COST_FUNCTIONS_FIELDS.CONFIG][_this.state.index].periods.includes(period[em])) {
                            data[e][COST_FUNCTIONS_FIELDS.CONFIG][_this.state.index].periods.push(period[em]);
                            if (row) {
                                row[COST_FUNCTIONS_FIELDS.CONFIG] = data[e][COST_FUNCTIONS_FIELDS.CONFIG];
                            }
                        }
                    }
                    data[e][COST_FUNCTIONS_FIELDS.CONFIG][_this.state.index][ROW_STATUS.FIELD] = ROW_STATUS.VALUES.EDITED;

                } else {
                    data[e][COST_FUNCTIONS_FIELDS.CONFIG] = this.deleteIndexRowPeriod(data[e][COST_FUNCTIONS_FIELDS.CONFIG], _this.state.index, period,show);
                    data[e][COST_FUNCTIONS_FIELDS.CONFIG][_this.state.index][ROW_STATUS.FIELD] = ROW_STATUS.VALUES.EDITED;
                    data[e][COST_FUNCTIONS_FIELDS.CONFIG][defaultIndex].periods = data[e][COST_FUNCTIONS_FIELDS.CONFIG][defaultIndex].periods.concat(period);
                    data[e][COST_FUNCTIONS_FIELDS.CONFIG][defaultIndex][ROW_STATUS.FIELD] = ROW_STATUS.VALUES.EDITED;
                    if (row) {
                        row[COST_FUNCTIONS_FIELDS.CONFIG] = data[e][COST_FUNCTIONS_FIELDS.CONFIG];
                    }
                    if (index !== undefined) {
                        data[e][COST_FUNCTIONS_FIELDS.CONFIG].splice(index,1);
                        if(row && row[COST_FUNCTIONS_FIELDS.CONFIG]) {
                            row[COST_FUNCTIONS_FIELDS.CONFIG] = data[e][COST_FUNCTIONS_FIELDS.CONFIG];
                        }
                    }
                }
                sameRow = data[e];
            } else if (data[e][COST_FUNCTIONS_FIELDS.CHILDREN], show, period) {
                this.findRow(data[e][COST_FUNCTIONS_FIELDS.CHILDREN], id, show, period, index, row);
            }
        }
    }

    hidePeriodDropDown(e) {
        let node = $(e.target);
        if (node.parent().attr("id") !== "period-drop-down") {
            $("#periods_comp").hide();
            $("#periods_comp_with_message").hide();
        }
    }

    deleteRuleAndFilterRow(evt, cell) {
        var _this = this;
        var invalidatedPeriods = _this.state.invalidatedPeriods || [];
        var rowData = copyObjectValues(cell.getRow().getData());
        var index = Number($(evt.currentTarget).attr("id"));

        var periods = rowData[COST_FUNCTIONS_FIELDS.CONFIG][index].periods;
        for (var e in periods) {
            invalidatedPeriods.push({costKey: rowData[COST_FUNCTIONS_FIELDS.ID],period: periods[e]});
        }
        var modifiedPeriods = this.state.modifiedPeriods.concat(periods);
        var data = cell.getTable().getData();
        this.setState({
            index: index,
            invalidatedPeriods: invalidatedPeriods,
            changed: true,
            build:true,
            modifiedPeriods: modifiedPeriods
        },function(){ 
            var defaultIndex = rowData[COST_FUNCTIONS_FIELDS.CONFIG].findIndex(elt=>elt[COST_FUNCTIONS_FIELDS.IS_DEFAULT]);
            this.envokeWarning(defaultIndex, rowData[COST_FUNCTIONS_FIELDS.ID], this.state.modifiedPeriods);
            this.findRow(data, rowData[COST_FUNCTIONS_FIELDS.ID], false, periods, index, rowData);
            this.updateAccrual(data, rowData, false, index, periods, invalidatedPeriods);    
            this.tabulator.replaceData(data);
            _this.props.setIsChanged(true);
        })
    }

    setRowToDefault(evt, cell) {
        var obj = this;
        var periods = this.props.periods;
        var index = Number($(evt.currentTarget).attr("id"));
        for (var e in cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG]) {
            if (cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG][e][COST_FUNCTIONS_FIELDS.IS_DEFAULT]) {
                for (var elt in periods) {
                    if (obj.periodExist(periods[elt].value, cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG])) {
                        if (!cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG][e].periods.includes(periods[elt].value)){
                            cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG][e].periods.push(periods[elt].value)
                        }
                    }
                }
            } 
        }

        for (var e in cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG]) {
            if (cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG][e][COST_FUNCTIONS_FIELDS.IS_DEFAULT]) {
                cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG][e][COST_FUNCTIONS_FIELDS.IS_DEFAULT] = false;
                cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG][e][ROW_STATUS.FIELD]= ROW_STATUS.VALUES.EDITED;
            }
            if (Number(e) === index) {
                cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG][index][COST_FUNCTIONS_FIELDS.IS_DEFAULT] = true;
                cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG][index][ROW_STATUS.FIELD]= ROW_STATUS.VALUES.EDITED;
            }

        }
        // this.tabulator.replaceData(cell.getTable().getData());
        // var data = this.tabulator.getData();
        let data = cell.getTable().getData();
        this.updateAccrual(data,cell.getRow().getData(), false, index);
        this.setState({
            changed: true
        },function(){
            obj.tabulator.replaceData(data);
            obj.props.setIsChanged(true);
        })
    }

    handleRestrictionChange(e, cell) {
        let _this = this;
        cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CHILDREN_ACCESS] = $(e.currentTarget).val();
        this.tabulator.replaceData(cell.getTable().getData());
        this.setState({
            changed: true
        },function(){
            _this.props.setIsChanged(true);
        })
    }

    /* TODO(enhancement): replace document.onclick */
    createDocumentClick() {
        setTimeout(() => {
            document.onclick = () =>
            {
                $(".toggleTooltipDiv").css("display", "none");
                $(".tooltip-configure").css("display", "none");
                setTimeout(() => {
                    document.onclick = () => {}
                }, 100);
            }
        }, 100);
    }

    isSubChild(data, costKey) {
        for (var e in data) {
            if (data[e].costKey === costKey) {
                return 1;
            } else{
                if (data[e].children) {
                    if(this.isSubChild(data[e].children, costKey) !== 1){
                       continue;
                    } else {
                        return 1;
                    }
                }
            }
        }
    }

    isGrandChild(data, costKey) {
        if(data.children) {
            return this.isSubChild(data.children, costKey) === 1 ? true : false;
        }
        return false;
    }

    updateData(costkey, periodName, data) {
        troubleshootResult[costkey] = data; 
       
        $("#toggleTooltipButton_" + costkey).hide();

        if (troubleshootResult[costkey]) {
            if (troubleshootResult[costkey].error) {
                $("#toggleToolTipTroubleshootdiv_"+ costkey).html("<div class='uk-float-left uk-text-left uk-margin-top troubleshoot-line-height'>" + troubleshootResult[costkey].error.message + "</div>");
            } else {
                var troubleshootResults = [];

                Object.keys(troubleshootResult[costkey]).forEach(function eachkey(key) {
                    if(key !== "logDates"){
                       var message = MESSAGES[key];
                    
                        if(key === "countCostKeyFilter"){
                            if(troubleshootResult[costkey][key][0]["fcount"] === "0"){
                                troubleshootResults.push(message);
                            }
                        }
                        else if (troubleshootResult[costkey][key].length !==0){
                                troubleshootResults.push(message);
                        }
                     }
                });

                $("#toggleToolTipTroubleshootdiv_" + costkey).html("<div class='uk-float-left uk-text-left uk-margin-top troubleshoot-line-height'>" + troubleshootResults.toString() + "</div>");
            }
        }
    }

    createTooltip(cell) {
        var obj = this;
        var data = cell.getRow().getData()[COST_FUNCTIONS_FIELDS.VALIDATION_STATUS]; //validation status
        data = JSON.parse(data)
        
        var rowPosition = cell.getRow().getPosition();
        var assignAmount = data && data["assigned"] ? data["assigned"] : 0;
        var amount = cell.getRow().getData()["amount"];
        var variance = amount - assignAmount;
        var costkey = cell.getRow().getData()[COST_FUNCTIONS_FIELDS.ID];

        var assignAmountFormatted = formatValHTML(assignAmount, FormatTypes.AMOUNT);
        var varianceFormatted = formatValHTML(variance, FormatTypes.AMOUNT);
        var variancePercFormat = amount !== 0 ? advancedFormatCurrency((variance / amount), "", 2) : 0;
        var variancePerc = variancePercFormat === "-" ? 0 : variancePercFormat;
        
        var toolTipdiv = document.createElement("div");
        toolTipdiv.innerHTML = "<h4 class='uk-margin-remove-top mrgb5'>Built</h4>"+
            "<div class='tooltip-amounts uk-display-inline-block'>"+
                "<div class='uk-flex'><div class='uk-margin-small-right uk-text-normal'>Assigned Amount:</div><div class='amount uk-text-bold'>" + assignAmountFormatted + "</div></div>"+
                "<div class='uk-flex'><div class='uk-margin-small-right uk-text-normal'>Variance Amount:</div><div class='amount uk-text-bold'>" + varianceFormatted + " </div></div>"+
                "<div class='uk-flex'><div class='uk-margin-small-right uk-text-normal'>Variance %:</div><div class='amount uk-text-bold'>" + formatValHTML(variancePerc * 100,  FormatTypes.PERCENTAGE) + "</div></div>"+
            "</div>";

        toolTipdiv.setAttribute("id", "toggleTooltipDiv" + rowPosition);
        toolTipdiv.classList.add("toggleTooltipDiv");
        toolTipdiv.addEventListener("click", function (ev) {
            ev.stopPropagation();
        });
        if (cell.getValue() === COST_FUNCTIONS_FIELDS.VALIDATION_STATUS_ENUM.WARNING ||
            cell.getValue() === COST_FUNCTIONS_FIELDS.VALIDATION_STATUS_ENUM.VALID) {
                if(cell.getRow().getData()[COST_FUNCTIONS_FIELDS.IS_VALID] === COST_FUNCTIONS_FIELDS.VALIDATION_STATUS_ENUM.WARNING) {
                    toolTipdiv.classList.add("configure-tooltip-position");
        }}
        //Showing troubleshoot button only when validation fails
        if(cell.getRow().getData()[COST_FUNCTIONS_FIELDS.IS_VALID] === COST_FUNCTIONS_FIELDS.VALIDATION_STATUS_ENUM.WARNING && obj.props.user.is_system === "true"){
            var button = document.createElement("button");
            button.innerHTML = "Troubleshoot";
            button.classList.add("toggleTooltipButton");
            button.setAttribute("id", "toggleTooltipButton_"+ costkey);
            
            button.onclick = () => {
                obj.props.troubleshootCostFunction(costkey, obj.state.timePeriod, obj.updateData);
            }
            toolTipdiv.appendChild(button);

            var toolTipTroubleshootdiv = document.createElement("div");
            toolTipTroubleshootdiv.classList.add("word-wrap");
            toolTipTroubleshootdiv.setAttribute("id", "toggleToolTipTroubleshootdiv_" + costkey);
            toolTipTroubleshootdiv.classList.add("tooltip-amounts", "uk-text-left");

            toolTipdiv.appendChild(toolTipTroubleshootdiv);
        }
        
        return toolTipdiv;
    }

    getMetricsPeriodStatus() {
        var obj = this;
        var query = {
            action: "getMetricFields",
            scenario_id: this.props.scenarioId
        }
        let onThenCallback = (data) => {
            if (data && data.metrics) {
                obj.setState({
                    metricFields: data.metrics,
                },function(){
                    obj.props.setTabsData("getMetricsPeriodStatus", obj.state.metricFields);
                    obj.getMetricsPeriodStatusFetched = false;
                });
            } else if(data.ERROR) {
                Popup.alert("There's something wrong with your query.");
            } 
        } 
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "getMetricsPeriodStatus",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: true,
            [FETCHAPI_PARAMS.showCustomizedLoader]: true,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]:lang.observability.configure_build.configure_cost_function.screen_name,
            [FETCHAPI_PARAMS.requestDescription]:lang.observability.configure_build.configure_cost_function.requests_description.get_metric_fields
        };
        let data = obj.props.getTabsData("getMetricsPeriodStatus");
        if(data){
            obj.setState({
                metricFields: data
            })
        }else{
            obj.getMetricsPeriodStatusFetched = true;
            obj.fetchAPI(fetchOptions);
        }
    }

    getVectorPeriodStatus(callback) {
        var obj = this;
        var query = {
            action: "getVectorsStatus",
            scenario_id: this.props.scenarioId,
            vectors: "",
            timePeriod: this.state.timePeriod
        }
        let onThenCallback = (data) => {
            if (data && data.data) {
                var filterVectors = [{ isGroup: true,isDisabled: true, label: "Vectors",value: "Vectors"}];
                data.data.map(function (vector) {
                    filterVectors.push({ label: vector[_displayName]+"Key", value: vector["vector_name"]+"Key", type: "string",[_fieldDataType]:"string", columnType:"vector" });
                    filterVectors.push({ label: vector[_displayName]+"Name", value: vector["vector_name"]+"Name", type: "string",[_fieldDataType]:"string", columnType:"vector" });
                    filterVectors.push({ label: vector[_displayName]+"Number", value: vector["vector_name"]+"Number", type: "string",[_fieldDataType]:"string", columnType:"vector" });
                    // filterVectors.push({[VECTOR_STAGING_ATTRIBUTES.ID]: vector[VECTOR_STAGING_ATTRIBUTES.ID],label: vector[_displayName]+"Key", value: vector["vector_name"]+"Key", type: "string",[_fieldDataType]:"string", columnType:"vector" });
                    // filterVectors.push({[VECTOR_STAGING_ATTRIBUTES.ID]: vector[VECTOR_STAGING_ATTRIBUTES.ID],label: vector[_displayName]+"Name", value: vector["vector_name"]+"Name", type: "string",[_fieldDataType]:"string", columnType:"vector" });
                    // filterVectors.push({[VECTOR_STAGING_ATTRIBUTES.ID]: vector[VECTOR_STAGING_ATTRIBUTES.ID],label: vector[_displayName]+"Number", value: vector["vector_name"]+"Number", type: "string",[_fieldDataType]:"string", columnType:"vector" });
                });
                obj.setState({
                    vectors: data.data,
                    filterVectors: filterVectors,
                    vectorList: data.data,
                },function(){
                    obj.props.setTabsData("getVectorPeriodStatus", data);
                    obj.getVectorPeriodStatusFetched = false;
                    if(typeof callback === "function"){
                        callback()
                    }
                });
            } else if(data.ERROR) {
                Popup.alert("There's something wrong with your query.");
            } 
        } 
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "getVectorPeriodStatus",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: true,
            [FETCHAPI_PARAMS.showCustomizedLoader]:true,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]:lang.observability.configure_build.configure_cost_function.screen_name,
            [FETCHAPI_PARAMS.requestDescription]:lang.observability.configure_build.configure_cost_function.requests_description.get_vectors_status
        };
        let data = obj.props.getTabsData("getVectorPeriodStatus");
        if(data && data.data){
            var filterVectors = [{ isGroup: true,isDisabled: true, label: "Vectors",value: "Vectors"}];
            data.data.map(function (vector) {
                filterVectors.push({ [VECTOR_STAGING_ATTRIBUTES.ID]: vector[VECTOR_STAGING_ATTRIBUTES.ID],label: vector[_displayName]+"Key", value: vector["vector_name"]+"Key", type: "string",[_fieldDataType]:"string", columnType:"vector" });
                filterVectors.push({ [VECTOR_STAGING_ATTRIBUTES.ID]: vector[VECTOR_STAGING_ATTRIBUTES.ID],label: vector[_displayName]+"Name", value: vector["vector_name"]+"Name", type: "string",[_fieldDataType]:"string", columnType:"vector" });
                filterVectors.push({ [VECTOR_STAGING_ATTRIBUTES.ID]: vector[VECTOR_STAGING_ATTRIBUTES.ID],label: vector[_displayName]+"Number", value: vector["vector_name"]+"Number", type: "string",[_fieldDataType]:"string", columnType:"vector" });
            });
            obj.setState({
                vectors: data.data,
                filterVectors: filterVectors,
                vectorList: data.data,
            });
        }else{
            obj.fetchAPI(fetchOptions);
            obj.getVectorPeriodStatusFetched = true;
        }
    }

    onmount(){
        var obj = this;
        if(obj.props.scenarioStatus !== _sandbox) {
            $(".md-step-oval").addClass('disabled');
        }else{
            $(".md-step-oval").removeClass('disabled');
        }
        if (obj.props.viewMode) {
            $("#PeriodRange").removeClass("uk-hidden");
            $("#period_label").removeClass("uk-hidden");
        }
    }

    componentDidMount() {
        var obj = this;
        obj.onmount(); 
        // this.getStagedPeriods();
        //Uncommented this because it was not being called hence causing issues.
        this.getFields();
        this.getMetricsPeriodStatus();
        // this.getVectorPeriodStatus();
        var options = {
            layout: "fitColumns",      //fit columns to width of table
            responsiveLayout: false,  //hide columns that dont fit on the table
            tooltips: true,            //show tool tips on cells
            addRowPos: "top",          //when adding a new row, add it to the top of the table
            history: true,             //allow undo and redo actions on the table
            pagination: false,          //paginate the data
            movableColumns: false,     //allow column order to be changed
            selectable: false,
            movableRows: false,
            resizableColumns: false,
            resizableRows: false,
            autoResize: true,
            dataTree: true,
            dataTreeChildField: "children",
            dataTreeStartExpanded: true,
            dataTreeElementColumn: "expand",
            dataTreeChildIndent: 30,
            dataTreeCollapseElement: getExpandCollapseButtons(false, ["uk-button-icon", "dark", "transparent-bg"]),
            dataTreeExpandElement: getExpandCollapseButtons(true, ["uk-button-icon", "dark", "transparent-bg"]),
            dataTreeBranchElement: false, //hide branch element
            virtualDomBuffer: 2000,
            virtualDom:true,
            placeholder: MESSAGES.no_data_available,
            width: "100%",
            height:"100%",// ($(window).height() - $("#cost_functions").offset().top)+"px",
            renderComplete: this.onTabulatorRenderComplete,
            index: COST_FUNCTIONS_FIELDS.ID,
            scrollVertical: (top) => {
                obj.addListeners(obj.tabulator.getData());
                globaltop = top;
            },
            reactiveData:true,      //tabulator listens to any change in the data array and updates the table
            dataTreeRowCollapsed: function(row, level){
                let costKey = Number(row.getData()[COST_FUNCTIONS_FIELDS.ID]);
                if(obj.state.expandedRowsCostkeys.indexOf(costKey) === -1) {
                    //if not already added, add costkey to state. but when programmatically re-expanded, don't add it a second time
                    obj.state.expandedRowsCostkeys.push(costKey);
                }             
            },
            dataTreeRowExpanded: function(row, level){
                setTimeout(() => {
                    obj.addListeners(obj.tabulator.getData());
                }, 50);
                let costKey = row.getData()[COST_FUNCTIONS_FIELDS.ID] ? Number(row.getData()[COST_FUNCTIONS_FIELDS.ID]) : "";
                let index = costKey ? obj.state.expandedRowsCostkeys.indexOf(costKey) : "";
                if(index !== -1) {
                    //if not already added, add costkey to state. but when programmatically re-expanded, don't add it a second time
                    obj.state.expandedRowsCostkeys.splice(index, 1);
                }
            },
            tableBuilt:function(){
                obj.tableBuilt = true
            },
            tableBuilding:function(){
                obj.tableBuilt = false
            },
        }
        
        this.tabulator = new Tabulator(this.refs.mainTable, options);
    }

    onTabulatorRenderComplete() {
        let _this = this;
        if(!this.tabulator) {
            return;
        }
        setTimeout(() => {
            _this.addListeners(_this.tabulator.getData());
        }, 100);
        this.redrawTable = false;
    }


    /**
     * recursive function to add listeners for nested data
     * @param {*} data 
     * @returns 
     */
    addListeners=(data)=>{
        if(!this.tabulator){
            return;
        }
        let _this = this;
        if(data){
            data.forEach(row =>{
                if(!row.children){
                    _this.addListenersOnHover(row.costKey,row.config);
                } else{
                    _this.addListeners(row.children)
                }
            });
        }
    }


    /**
     * add lisneters to show and hide the three dots on hover
     * @param {*} rowNumber 
     */
    addListenersOnHover=(rowNumber,config)=>{
        // const btns = document.querySelectorAll('button[id^=cost_function_action_'+rowNumber+']')
        // btns.forEach(btn => {
        //     if(btn && btn.parentElement){
        //         btn.parentElement.parentElement.parentElement.addEventListener('mouseover', event => {
        //             btn.classList.remove("uk-display-none");
        //             btn.classList.add("uk-display-block");// css("display", "block");
        //         });
        //         btn.parentElement.parentElement.parentElement.addEventListener('mouseout', event => {
        //             btn.classList.remove("uk-display-block");
        //             btn.classList.add("uk-display-none");//css("display", "none");
        //         });
        //     }
        // });
        let _this = this;
        if(document.getElementById("cost_function_action_"+ rowNumber) && document.getElementById("cost_function_action_"+rowNumber).parentElement){
            if(((document.getElementById("cost_function_action_"+rowNumber).parentElement.parentElement.parentElement.children[4].offsetHeight- 33)/2) > 5){
                document.getElementById("cost_function_action_"+rowNumber).style.marginTop = convertPxToViewport((document.getElementById("cost_function_action_"+rowNumber).parentElement.parentElement.parentElement.children[4].offsetHeight - 33)/2);
            }
            document.getElementById("cost_function_action_"+rowNumber).parentElement.parentElement.parentElement.addEventListener("mouseover", event => {
                if(config){
                    for(var e in config){
                        $("#cost_function_action_"+rowNumber).css("display", "block");
                        $("#cost_function_action_"+rowNumber+"_"+e).css("display", "block");
                        // var div = document.getElementById("cost_function_action_"+rowNumber);
                        // if(Number(e)>0){
                        //     div = document.getElementById("cost_function_action_"+rowNumber+"_"+e);
                        // }
                        // $("#cost_function_action_"+rowNumber).css("display", "block");
                        // $("#cost_function_action_"+rowNumber+"_"+e).css("display", "block");
                        // var rect = div.getBoundingClientRect();
                        // let top = rect.top;
                        // let bottom = rect.bottom;
                        // let pos = event.y;
                        // if(!((pos<=bottom + 33) && pos>=top+ 33 )){
                        //     if(Number(e)>0){
                        //         $("#cost_function_action_"+rowNumber+"_"+e).css("display", "none");
                        //     }else{
                        //         $("#cost_function_action_"+rowNumber).css("display", "none");
                        //     }
                        // }
                    }
                }
            });
            document.getElementById("cost_function_action_"+ rowNumber).parentElement.parentElement.parentElement.addEventListener("mouseout", event => {
                if(rowNumber !== _this.savedRowNumber){// dont hide the three dots on mouse out if it is opened
                    $("#cost_function_action_"+rowNumber).css("display", "none");
                    if(config){
                        for(var e in config){
                            $("#cost_function_action_"+rowNumber+"_"+e).css("display", "none");
                        }
                    }
                }
            });
        }
    }


    /**
     * function that trigerrs on every click on the screen, if the target of the click is outside the three dots, hide them 
     * @param {*} e 
     * @param {*} _this 
     */
    hideThreeDots=(e, _this)=>{
        let container = $("#cost_function_drop_down_"+this.savedRowNumber);
        if ((!container.is(e.target) && container.has(e.target).length === 0) && (container[0] && e.target && e.target.parentElement && e.target.parentElement.children[1] && container[0].id !== e.target.parentElement.children[1].id)) {
            if(this.savedRowNumber >=0){
                $("#cost_function_action_"+_this.savedRowNumber).css("display", "none");// when clicking outside the three dots, hide them 
                    if(this.savedRowIndex){
                        $("#cost_function_action_"+this.savedRowNumber+"_"+this.savedRowIndex).css("display", "none");
                    }
                this.savedRowNumber = -1;
                this.savedRowIndex = -1;
            }
        }
    }

    setOpenCheckBeforeChangeDialog = (isOpen) => {
      let _this = this;
      _this.setState({
        openCheckBeforeChangeDialog: isOpen
      })
    }

  checkBeforeChangeDialogActions = () => {
    return (
      <>
        <Button
          label={lang.modal.buttons.proceed}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => this.executeSave(this.state.switchAfter, this.state.openModalAfter, this.state.newScenario)}
        />
        <Button
          label={lang.modal.buttons.cancel}
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={this.onCancel}
        />
      </>
    )
  }

  checkBeforeChangeDialogContent = () => {
    return (
      <>
        <h5 className="uk-margin-small-bottom fs-18 uk-margin-medium-top">{MESSAGES.empty_rows}</h5>
        <ul className="uk-list-disc fs-18">
          {this.state.emptyRows.map(rowName => {
            return <li key={rowName}>{rowName}</li>
          })}
        </ul>
        <h5 className="uk-margin-medium-top fs-18">
          {MESSAGES.discard_unconfigured_rows}
        </h5>
      </>
    )
  }
  setOpenDetailedAmountDialog = (isOpen) => {
    let _this = this;
    _this.setState({
      openDetailedAmountDialog: isOpen
    })
  }

  detailedAmountDialogActions = () => {
    return (
      <Button
        label={"Close"}
        variant={BUTTON_VARIANT.PRIMARY}
        size={SIZES.DEFAULT}
        type={BUTTON_TYPE.DEFAULT}
        onBtnClick={() => this.setOpenDetailedAmountDialog(false)}
      />
    )
  }

  detailedAmountDialogContent = () => {
    const { costCenterData } = this.state;
    return (
      <div className="uk-display-block uk-padding-large">
      </div>
    )
  }

	render() {
        
        let _this = this;
        var vectorOptions = this.getVectorsForFilter(this.state.vectorList);
        var heightClass = this.state.is_default == false ? " custom-height " : "";
        var container = this.state.is_default === false ? $("#periods_comp_with_message") : $("#periods_comp");
        $(document).click(function(e) {
            _this.hideThreeDots(e, _this);
            var container2 = $("#period-drop-down");
            // if the target of the click isn't the container nor a descendant of the container
            if ((!container.is(e.target) && container.has(e.target).length === 0) && (container2[0] && e.target && e.target.parentElement && container2[0].id !== e.target.parentElement.id)) {
                container.addClass("uk-hidden");
            }
        });
       		return(
            <React.Fragment>
                <div>
                {/* <div id="toastConfigureCostFunction" className="toast toast-success">
                    <div id="desc"><i className={"fa-lg fas" +(this.state.isError ? " fa-minus-circle uk-text-primary" : " fa-check-circle  uk-margin-small-right greenText" )}aria-hidden="true"></i>{this.state.message}</div>
                </div> */}
                <div id={`${(this.state.is_default === false ? "periods_comp_with_message" : "periods_comp")}`} className={"hide-columns-dropdown uk-hidden " + heightClass}>
                    {/* {this.state.showPeriodComp? */}
                        <PeriodsDropDown ref={el=>this.periodRef = el} funkName={this.addPeriods} periods={this.state.periods} setQuarters={this.setQuarters} 
                        placeHolderText={MESSAGES.find_period} showText={MESSAGES.select_all} hideText={MESSAGES.unselect_all} 
                        defaultStatus={this.state.defaultStatus} is_default={this.state.is_default} message={MESSAGES.default_message}
                        report={CONFIGURE_SECTIONS.FIELDS.CONFIG_COST_FUNCTIONS} />
                    {/* :""} */}
                </div>
                <div id="cost_functions" ref="mainTable"/>
             
                    {clickedRowParent ?
                        <div id="rule_modal" className={this.state.showRule?"":"uk-hidden"}>
                            <Rules ref={el => this.ruleRef = el} 
                                rule={clickedRowParent?.[COST_FUNCTIONS_FIELDS.CONFIG]?.[this.state.index]?.[COST_FUNCTIONS_FIELDS.RULE]}  
                                isCostCenter={clickedRowParent ? clickedRowParent[COST_FUNCTIONS_FIELDS.COST_CENTER] : ""}
                                label={clickedRowParent ? clickedRowParent[COST_FUNCTIONS_FIELDS.NAME] : ""}
                                vectors={vectorOptions} timePeriod={this.state.timePeriod} message={this.state.message} periods={this.state.periods}
                                onCancelRule={this.onCancelRule} fields={this.state.fields !== undefined ? this.state.fields.filter(function(e){return e.type === 'numeric'}) : []}
                                user={this.props.user} tableName={this.state.tableName} scenarioId={this.props.scenarioId} costCenter={this.props.costCenter} clickedRowParent={clickedRowParent}
                                filter={clickedRowParent ? getReadableFilterText(tryParse(clickedRowParent[COST_FUNCTIONS_FIELDS.CONFIG][this.state.index][COST_FUNCTIONS_FIELDS.JSON_FILTER], "") ? tryParse(clickedRowParent[COST_FUNCTIONS_FIELDS.CONFIG][this.state.index][COST_FUNCTIONS_FIELDS.JSON_FILTER], "").filter : "", vectorOptions) : ""}
                                transactionAttributeLeaves={this.state.transactionAttributeLeaves} statusRefPeriods={this.state.statusRefPeriods} headerVectors={_invoiceLine.concat(vectorOptions)} costKey={clickedRowParent?clickedRowParent[COST_FUNCTIONS_FIELDS.ID] : ""} scenario_id={this.props.scenarioId}
                                disableValidateButton={this.disableValidateButton} scenarioStatus={this.props.scenarioStatus} showFilterPopup={this.showFilterPopup} clickedRow={this.state.clickedRow} saveRuleAndFilter={this.saveRuleAndFilter} index = {this.state.index} discardFilter={this.discardFilter} metricFields={this.state.metricFields} 
                                />
                        </div>
                    :""}
                    {this !== null && this.state !== null &&  this.state.showFilter ? 
                        <div id = "engine_filter_modal">
                            <EngineFilterModal ref={el => this.filterRef = el}
                                handleFilterRowChange={this.handleFilterRowChange}
                                tableName={this.state.tableName}
                                timePeriod={this.state.timePeriod}
                                stagingReport={CONFIGURE_SECTIONS.FIELDS.CONFIG_COST_FUNCTIONS}
                                scenario={this.props.scenarioId}
                                saveRuleAndFilter={this.saveRuleAndFilter}
                                discardFilter={this.discardFilter}
                                loadEntitiesFunc={this.getConfigureColumnsValuesNew}
                                label={this.state.clickedRow[COST_FUNCTIONS_FIELDS.NAME]}
                                message={this.state.message}
                                isFromCostFunction={true}
                                saveFilterObjectTemp={this.saveFilterObjectTemp}
                            />
                        </div>
                    : ""}
                </div>

                <Modal
                 id={"check-before-save-dialog"}
                 openDialog={this.state.openCheckBeforeChangeDialog}
                 bodyContent={this.checkBeforeChangeDialogContent}
                 dialogActions={this.checkBeforeChangeDialogActions}
                 closeClick={() => this.setOpenCheckBeforeChangeDialog(false)}     
                 size={DIALOG_SIZE.MEDIUM}
                />
                <Modal
                 id={"detailed-amount-dialog"}
                 title={"Detailed Amount"}
                 openDialog={this.state.openDetailedAmountDialog}
                 bodyContent={this.detailedAmountDialogContent}
                 dialogActions={this.detailedAmountDialogActions}
                 closeClick={() => this.setOpenDetailedAmountDialog(false)}     
                 size={DIALOG_SIZE.LARGE}
                />
            </React.Fragment>
		);
	}

}

export default ConfigureCostFunctions;