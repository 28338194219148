import $ from "jquery";
import shortid from "shortid";
import { updateDrillListData, updateListData, updateListObjBeforeBack } from "../../actions/listActions";
import { linearizeHierarchy } from "../../class/array";
import { checkIfSectionIdExists, getLocalStorageValueByParameter, toggleLoader } from "../../class/common";
import {
    ALL_WIDGETS,
    BUTTON_TYPE,
    BUTTON_VARIANT,
    COLORS_PALETTE,
    COLUMN_PROFILE,
    DROPDOWN_TYPE,
    ENTITY_STACKS_OBJECT,
    FY_VALUES,
    HEADER_ELEMENT,
    MENU_ITEM,
    MENU_ITEM_URLS,
    PSL_RETURN_NAMES,
    SELECTED_DRILL_LIST,
    SIZES,
    SUFFIX,
    VECTOR_ANALYSIS,
    period_calendar_type, CUSTOM_REPORT
} from "../../class/constants";
import { copyObjectValues, findOptionByKey, parseBoolean, tryParse } from "../../class/utils";
import { Segment } from "../../components/Segment";
import TileCalendarContainer from "../../components/quarterCalendar/TileCalendarContainer";
import Button from "../../newComponents/Button";
import DropDown from "../../newComponents/DropDown";
import { lang } from "./../../language/messages_en";
import {
  checkBuiltPeriodAvailability,
  enablePreviousPeriods,
  initPeriodYears,
  isPeriodTileDisabled,
  periodTileContent
} from "./periodFunctions";
import { getPSTier } from "../../class/jqueries";
import { generatePeriods, getActualYear, getPeriodFromDate, getPeriodTextFromMonth, monthDiff } from "../../class/date";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import {IconTitle} from "../IconTitle";
import React from "react";

function renderDropDown(callBack, value, options, placeholder, id) {
  return (
    <DropDown
      id={id}
      type={DROPDOWN_TYPE.INPUT}
      onDropdownClick={callBack ? callBack : console.log("callBack")}
      placeholder={placeholder}
      value={value}
      options={options}
    />
  );
}

function renderButton(label, id, dataDismissModal, leftIcon, callBack, disabled) {
  return (
    <Button
      variant={BUTTON_VARIANT.PRIMARY}
      size={SIZES.DEFAULT}
      type={BUTTON_TYPE.DEFAULT}
      data-dismiss={dataDismissModal}
      leftIcon={leftIcon}
      label={label}
      id={id}
      disabled={disabled}
      onBtnClick={callBack ? callBack : console.log("callBack")}
    />
  );
}

function renderTitle(title) {
  return <div key={shortid.generate()} className="template-report-title">{title}</div>;
}

function renderBreadcrumbsAndDescription(gotToLandingFunc, title, reportDescription, additionalCrumb, redirectedFromObj) {
    return (
        <div className="breadcrumbs_description">
            <Breadcrumbs 
              title={title} 
              additionalCrumb={additionalCrumb} 
              redirectedFromObj={redirectedFromObj} 
              gotToLandingFunc={gotToLandingFunc}
            />
            {reportDescription && <div className="report_header_description">{reportDescription}</div>}
        </div>
    )
}

/**
 * This function renders a period calendar
 * @param {*} periodsToCheck period json array. the periods to filter on wether the period is built, active or generated
 * @param {*} startPeriod 
 * @param {*} endPeriod 
 * @param {*} inputValue 
 * @param {*} handleChange 
 * @param {*} onSelectCallBack 
 * @param {*} allPeriods 
 * @param {*} toggleCalendar 
 * @param {*} isDropDownDisabled 
 * @param {*} key 
 * @param {*} checkSegmentGeneration this is to check for built & generated periods (in profit landscape and erosion)
 * @param {*} tooltipMessage 
 * @param {*} minYear 
 * @param {*} maxYear 
 * @param {*} typeOfCheck "active" or "built". This is to either check built periods or active periods
 * @returns calendar component
 */
function renderCalendar(
  periodsToCheck,
  startPeriod,
  endPeriod,
  inputValue,
  handleChange,
  onSelectCallBack,
  allPeriods,
  toggleCalendar,
  isDropDownDisabled,
  key,
  checkSegmentGeneration = false, // this is true if report is landscape or erosion
  tooltipMessage = "",
  minYear,
  maxYear,
  typeOfCheck,
  updateTilesFunc
) {
  return (
    <div key={"calendar_" + key} style={{ zIndex: 6 }} uk-tooltip={"title:" + tooltipMessage}>
      <div id={"period_table_container"} className={isDropDownDisabled ? "disabled" : ""}>
        <div className="uk-cursor-pointer" id="selection-range-container" onClick={toggleCalendar} onChange={() => {}}>
          <i className="far fa-calendar uk-display-flex calendar_icon"></i>
          <input readOnly autoComplete="off" id="selection_range_input" className="calendar-input" value={inputValue} />
        </div>
        <>
          <TileCalendarContainer
            minYear={minYear}
            maxYear={maxYear}
            newHeader
            years={initPeriodYears(minYear, maxYear, allPeriods)}
            nextStartTile={startPeriod}
            nextEndTile={endPeriod}
            handleElementChange={(e) => handleChange(HEADER_ELEMENT.SELECTION_RANGE, e)}
            isTileDisabled={isPeriodTileDisabled}
            tileContent={(tile) => periodTileContent(tile, allPeriods, periodsToCheck, checkSegmentGeneration, typeOfCheck)}
            type={HEADER_ELEMENT.PERIOD}
            columnNumber={3}
            periodsToCheck={periodsToCheck}
            typeOfCheck={typeOfCheck}
            onSelectCallback={onSelectCallBack ? onSelectCallBack : ""}
            isYearsLimited
            checkSegmentGeneration={checkSegmentGeneration}
            updateTiles={updateTilesFunc}
          />
        </>
      </div>
    </div>
  );
}

/**
 * This function is used for output screens period calendar.
 * @param {*} periodsStatusState 
 * @param {*} handleChange 
 * @param {*} onSelectCallBack 
 * @param {*} allPeriods 
 * @param {*} toggleCalendar 
 * @param {*} isDropDownDisabled 
 * @param {*} scenarioState 
 * @param {*} checkSegmentGeneration 
 * @param {*} tooltipMessage 
 * @returns period calendar component that has minYear=2010 and maxYear=2030
 */
function renderPeriodCalendar(
  periodsStatusState,
  handleChange,
  onSelectCallBack,
  allPeriods,
  toggleCalendar,
  isDropDownDisabled,
  scenarioState,
  checkSegmentGeneration = false, // this is true if report is landscape or erosion
  tooltipMessage = "",
  screenName
) {
  let builtPeriods = periodsStatusState.builtPeriods;
  let startPeriod = periodsStatusState.startPeriod;
  let endPeriod = periodsStatusState.endPeriod;
  
  let endDateInput = endPeriod
  let inputValue = startPeriod

  if (inputValue !== endDateInput) {
    inputValue += " " + lang.COMMON.TO.toLowerCase() + " " + endDateInput;
  }

  let key = screenName ? screenName + "_" + scenarioState?.scenario : scenarioState?.scenario;

  return renderCalendar(
    builtPeriods,
    startPeriod,
    endPeriod,
    inputValue,
    handleChange,
    onSelectCallBack,
    allPeriods,
    toggleCalendar,
    isDropDownDisabled,
    key,
    checkSegmentGeneration,
    tooltipMessage,
    2010,
    2030,
    period_calendar_type.BUILT,
    enablePreviousPeriods,
  );
}


function messageDialogContent(msg) {
  return <span className="fs-16">{msg}</span>;
}

function dateToTemporalExpressions(dateString) {
  const now = new Date();
  let date = new Date(dateString+"T00:00:00");
  const diff = now - date;
  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30);
  const years = Math.floor(months / 12);

  if (years >= 2) {
    return years + ' '+ lang.menu.years_ago;
  } else if (years === 1) {
    return lang.menu.last_year;
  } else if (months >= 2) {
    return months + ' ' + lang.menu.months_ago;
  } else if (months === 1) {
    return lang.menu.last_month;
  } else if (weeks >= 2) {
    return weeks + ' ' + lang.menu.weeks_ago;
  } else if (weeks === 1) {
    return lang.menu.last_week;
  } else if (days >= 2) {
    return days + ' ' + lang.menu.days_ago;
  } else if (days === 1) {
    return lang.menu.yesterday;
  }else { 
    return lang.menu.today;
  }
  // } else if (hours >= 2) {
  //   return hours + ' hours ago';
  
  // } else if (hours === 1) {
  //   return 'an hour ago';
  // } else if (minutes >= 2) {
  //   return minutes + ' minutes ago';
  // } else if (minutes === 1) {
  //   return 'a minute ago';
  // } else if (seconds >= 2) {
  //   return seconds + ' seconds ago';
  // } else {
  //   return 'just now';
  // }
}

function getMonthDayYear(dateString){
  if (!!dateString) {
    try {
      let date = new Date(dateString+"T00:00:00");
      return date.toLocaleString('en-US', { month: 'long' }) + " " + date.getDate() +", "+ date.getFullYear()
    }catch (err) {
      console.log("Couldn't parse date " + err)
      return "";
    }
  }
  return "";
}

function tablesToExcel(reportName, userSettings, vectorState, datasetState, scenarioState, reportRef, getExportQueryFilter, dataTier, allRows) {
  var displayName = userSettings.user.first_name + " " + userSettings.user.last_name;
  var options = {
      report: reportName,
      vector: dataTier || ( Object.keys(vectorState).length > 0 ? findOptionByKey(vectorState?.vectorOptions, vectorState?.vectors[0])?.label : ""),
      dataset: datasetState?.dataset,
      startQuarter: datasetState?.nextStartQuarter,
      endQuarter: datasetState?.nextEndQuarter,
      user: displayName,
      scenarioNumber: scenarioState.scenarioNumber,
      comparisonScenarioNumber: scenarioState.scenarioObjects[1] ? scenarioState.scenarioObjects[1][SCENARIOS.F.NUMBER] : undefined,
      // months: this.state.FY,
      exportQueryFilter: getExportQueryFilter ? getExportQueryFilter() : ""
  };
  options.isProfitStack = false;
  setTimeout(function () {
    if (typeof reportRef?.current?.exportTableToExcel == "function") {
      reportRef?.current?.exportTableToExcel(options, allRows)
    } else{
      reportRef?.current?.pageComponent?.exportTableToExcel(options, allRows);
    }
  }, 10);
}

function extractAndPrepareObject(vectorState, allVectorsSelected, entity, selectedDropdownVector, loopedItem) {
    let commonVectorsObject = allVectorsSelected.filter(option => vectorState.vectorOptions.some(selected => selected.value === option.column_return_name || selected.value === selectedDropdownVector));
    let vectorCounter = 1
    for (let [i, commonVectorObject] of commonVectorsObject.entries()) {
        let {column_return_name, machine_name} = commonVectorObject;
        let valueToExtract = `${column_return_name}_${machine_name}`;
        const suffixesToBeExcluded = [SUFFIX.ENTITY_COUNT, SUFFIX.COUNT_PER_SELECTION, SUFFIX.COUNT_PER_TOTAL];
        if (entity[valueToExtract] && column_return_name !== selectedDropdownVector && suffixesToBeExcluded.every(suffix => !valueToExtract.includes(suffix))) {
            let vectorName = vectorState.vectorOptions.filter(f => f.value === column_return_name)[0]?.label;
            loopedItem[valueToExtract] = entity[valueToExtract];
            if (!Object.values(loopedItem).includes(vectorName)) {
                let vectorNameKey = ENTITY_STACKS_OBJECT.VECTOR_NAME + vectorCounter;
                loopedItem[vectorNameKey] = vectorName;
            }
            if (!Object.keys(loopedItem).includes(ENTITY_STACKS_OBJECT.VECTOR_MACHINE_NAME + vectorCounter)) {
                let machineName = ENTITY_STACKS_OBJECT.VECTOR_MACHINE_NAME + vectorCounter;
                loopedItem[machineName] = column_return_name;
                vectorCounter++;
            }
        }
    }
    return loopedItem;
}

function runExtendedProfitStack(dropdownName, vectorState, datasetState, profitFormat, profitStackSelectedEntities, reportRef,
    PSViewsState, periodsStatusState, scenarioState, filterDialRef, filterFinal, filterFinalBasic, manageColumnsProfile, userAllowedMenuLinks,
    history, isDrilling, dispatch, drillFilter=[], allVectorsSelected, selectedDropdownVector, originalDrillFilter, drillVectorType) {
  var profitStackItems = [];
  // var isDrilling = false;//this.state.isDrilling;
  const profitStackTier = vectorState.vector;
  const profitStackDataSet = datasetState.dataSet;
  let drillProfile = {};
  if (isDrilling) {
      drillProfile = reportRef?.current?.pageComponent.returnDrillColumnProfile();
  }
  var drilledPath = [];
  var entities = profitStackSelectedEntities.current;
  let nameIndex = Object.keys(entities[0]).indexOf(PSL_RETURN_NAMES.NAME);
  let numberIndex = Object.keys(entities[0]).indexOf(PSL_RETURN_NAMES.NUMBER);
  let quadrantIndex = Object.keys(entities[0]).indexOf(PSL_RETURN_NAMES.QUADRANT);
  let quadrantTierIndex = Object.keys(entities[0]).indexOf(PSL_RETURN_NAMES.QUADRANT_TIER);
  for(let i=0; i < entities.length; i++) {
    let entity = entities[i];
    var loopedItem = {};

    loopedItem.key = entity.key;
    loopedItem.vectorName = vectorState.vectorOptions.filter(f=>f.value === vectorState.nextVectors[0])[0].label;
    loopedItem.machineName = selectedDropdownVector;
    loopedItem.number = entity.number;
    loopedItem.name = entity.name;
    loopedItem.lines = entity.lines;
    loopedItem.profittier = entity.quadranttier;
    loopedItem.segment = nameIndex === -1 && numberIndex === -1 && quadrantTierIndex === - 1  && quadrantIndex > -1 ? entity.quadrant :
    nameIndex === -1 && numberIndex === -1 && quadrantTierIndex > -1 ? entity.quadranttier : undefined;
    loopedItem.tier = getPSTier(profitStackTier, isDrilling);
    loopedItem.dataset = profitStackDataSet;
    loopedItem.profitFormat = profitFormat;
    loopedItem.color = COLORS_PALETTE[i]; // get the correspondant color based on entities order
    if(!isDrilling) {
      loopedItem = extractAndPrepareObject(vectorState, allVectorsSelected, entity, selectedDropdownVector, loopedItem);
    }
    profitStackItems.push(loopedItem);
  }

  // get drilled parameters if drilling
  let cardsData = reportRef?.current?.pageComponent?.state.cardsData;
  for(let e in cardsData) {
    let cardsDataDetails = cardsData[e]["data"][0];
    let cardKey = cardsDataDetails[PSL_RETURN_NAMES.KEY];
    let cardQT = cardsDataDetails[PSL_RETURN_NAMES.QUADRANT_TIER];
    let cardQuadrant = cardsDataDetails[PSL_RETURN_NAMES.QUADRANT];

    let tier = cardsDataDetails.tier;
    if (typeof tier !== typeof undefined && tier !== false) {
      let drilledPathStr = "";
      if(Array.isArray(tier)){
        drilledPathStr += (tier[0] + ": ");
      }else{
        drilledPathStr += (tier + ": ");
      }
      if(cardKey) {
          var number = cardsDataDetails.number;
          var name = cardsDataDetails.name;
          if(Array.isArray(tier)){
            for(let i in tier){
              drilledPathStr += (name?.[i] ? name[i] : "") + (name?.[i] && number?.[i] ?":" + number[i] : number?.[i] ? number[i] : "");
              if(i<(tier.length -1)){
                drilledPathStr += ", ";
              }
            }
          }else{
            drilledPathStr += (name ? name : "") + (name && number ?":" + number : number ? number : "")
          }
      } else if(cardQT) {
        if(Array.isArray(cardQT)){
          for(let i in cardQT){
            drilledPathStr += (new Segment().getSegmentObject(cardQT[i]).label);
            if(i<(cardQT.length -1)){
              drilledPathStr += ", ";
            }
          }
        }else{
          drilledPathStr += (new Segment().getSegmentObject(cardQT).label);
        }
      } else {
        if(Array.isArray(cardQuadrant)){
          for(let i in cardQuadrant){
            drilledPathStr += (new Segment().getSegmentObject(cardQuadrant[i]).label);
            if(i<(cardQuadrant.length -1)){
              drilledPathStr += ", ";
            }
          }
        }else{
          drilledPathStr += (new Segment().getSegmentObject(cardQuadrant).label);
        }
      }
      drilledPath.push(drilledPathStr);
    }
    let otherSelectedVectorsData = cardsData[e].otherSelectedVectorsData;

    //grouping by tierkey
    const tierKeyMap = otherSelectedVectorsData.flat().reduce((acc, vector) => {
      if (!acc[vector.label]) {
          acc[vector.label] = [];
      }
      acc[vector.label].push(vector.name || vector.number || new Segment().getSegmentObject(vector.value).label || vector.value);
      return acc;
    }, {});

      let otherVectorsLabels = Object.entries(tierKeyMap).map(([tierKey, values]) => `${tierKey} :${[...new Set(values)].join(', ')}`);
      drilledPath = drilledPath.concat(otherVectorsLabels);
}
  let isExtendedStack = dropdownName === HEADER_ELEMENT.CUSTOM_VIEW;
  let selectedStackVisibility = PSViewsState.profitStackViews.find(e=>e.value === PSViewsState.profitStackViewId)?.visibility || COLUMN_PROFILE.VISIBILITY_OPTIONS.COMPANY ;
  if(PSViewsState.profitStackViews.find(e=>e.value === PSViewsState.profitStackViewId).value === 0){
    selectedStackVisibility = COLUMN_PROFILE.VISIBILITY_OPTIONS.USER; // having access to user stacks allow the user to view the default stack
  }
  let access = selectedStackVisibility && selectedStackVisibility === COLUMN_PROFILE.VISIBILITY_OPTIONS.COMPANY?
    checkIfSectionIdExists(userAllowedMenuLinks, isExtendedStack? ALL_WIDGETS.FIELDS.PROFIT_STACK : MENU_ITEM.FIELDS.VIEW_COMPANY_STACKS) : true;
  // for the user stacks and default stack, no need to check any access, user always have access to view these stacks
  if (!access) {
    alert("No access");
    return;
  }

  if (profitStackSelectedEntities.length === 0 || profitStackSelectedEntities.length > 10) {
    Popup.alert("Make sure to select at least one item but not more than 10!");
  } else {
    sessionStorage.setItem("selectedProfile_list", JSON.stringify(manageColumnsProfile)); // save the selected list for back navigation
    
    if(isDrilling && drillProfile) {
      sessionStorage.setItem(SELECTED_DRILL_LIST, JSON.stringify(drillProfile)); // save the selected list for back navigation
      let drillListObj = fillDrillListDataBeforeBackObj(reportRef?.current?.pageComponent);
      drillListObj.manageColumnsDrillProfile = drillProfile;
      drillListObj.drillFilter = drillFilter;
      drillListObj.originalDrillFilter = originalDrillFilter;
      drillListObj.drillVectorType = drillVectorType;
      drillListObj.drillCardWidths = reportRef?.current?.pageComponent?.state.drillCardWidths;
      drillListObj.drillListWidths = reportRef?.current?.pageComponent?.state.drillListWidths;
      drillListObj.columnsWidths = reportRef?.current?.pageComponent?.state.columnsWidths;
      dispatch(updateDrillListData([drillListObj], "drillListData", false));   
    } else {
      let listObjBeforeBack = fillListDataBeforeBackObj(reportRef?.current?.pageComponent);
      let listData = reportRef?.current?.pageComponent?.state.dataFinal;
      listData?.chunkData?.map((m) => (m.checked = false)); // uncheck selected entites
      listData.order = reportRef?.current?.pageComponent?.state.order?.toLowerCase();
      listData.orderer = reportRef?.current?.pageComponent?.state.sorter;
      listData.tabulatorSortField = reportRef?.current?.pageComponent?.state.tabulatorSortField;
      listData.columnsWidths = reportRef?.current?.pageComponent?.state.columnsWidths;

      dispatch(updateListObjBeforeBack([listObjBeforeBack], "listObjBeforeBack", false));   
      dispatch(updateListData([listData], "listData", false));   
    }

    let startDate = periodsStatusState.startDate;//getValidPeriods().startDate;
    let endDate = periodsStatusState.endDate;
    var pathName = MENU_ITEM_URLS.LIST_ENTITY_STACKS;
    history.push({
        pathname: pathName,
        search: "?",
        hash: "",
        state: {
            origin: window.location.href,
            origin_name: profitFormat,
            profitFormat: profitFormat,
            profitStackItems: profitStackItems,
            drilledPath: drilledPath,
            drillFilter: drillFilter,
            drilling: isDrilling,
            useFilterCookies: false,
            savedFilterDataState: filterDialRef.current.state.savedFilterData,
            FY: FY_VALUES.OFF,
            scenarios: scenarioState.scenarios,
            originalScenarios: scenarioState.scenarios,
            dataset: datasetState.dataSet,
            mainVector: vectorState.vectors[0], // main vector - from vector dropdown
            vectorOptions: vectorState.vectorOptions,
            filter: filterFinal,
            mainFilter: filterFinal,
            outsideFilter: JSON.stringify({ filter: tryParse(filterFinal) }),
            mainFilterId: filterDialRef.current.state.savedFilterData.saved_filter_id,
            // order: this.props.location.state ? this.props.location.state.order : undefined,
            startDate: startDate,
            endDate: endDate,
            profitStackViewId: PSViewsState.profitStackViewId,
            headerOptionChanged: false,
            datasetOptions: datasetState.datasetOptions,
            periods: periodsStatusState.periods,
            isRedirectionFromStacks: true, // if it is a redirection, we won't remove the selected profile from session
            cardsData: cardsData,
            drillRows: [],//drillRows,
            drillProfile: drillProfile,
            // submitDrill: this.pageComponent.state.submitDrill,
            mainFilterFinalBasic: filterFinalBasic,
            manageColumnsProfile: manageColumnsProfile,
            vector: vectorState.vectors,
            scenarioState: scenarioState,
            originalDrillFilter:originalDrillFilter
        }
    });
  }
}
function fillDrillListDataBeforeBackObj(pageComponent) {
 var tempState = {};
 tempState.cardsData = pageComponent?.state.cardsData;
 tempState.drillDataList = pageComponent?.state.drillDataList;
 tempState.drillColumns = pageComponent?.state.drillColumns;
 tempState.drillTier = pageComponent?.state.drillTier;
 tempState.tier = pageComponent?.state.tier;
 tempState.sorter = pageComponent?.state.sorter;
 tempState.manageColumnsProfile = pageComponent?.props.manageColumnsProfile;
 
 tempState.drillDataList.order = pageComponent?.state.order?.toLowerCase();
 tempState.drillDataList.orderer = pageComponent?.state.sorter;
 tempState?.drillDataList?.chunkData?.map((m) => (m.checked = false)); // uncheck selected entites
 return tempState;
}

function fillListDataBeforeBackObj(pageComponent) {
 var tempState = {};
 tempState.manageColumnsProfile = pageComponent?.props.manageColumnsProfile;

 return tempState;
}

function getLeftMenuLinks(userAllowedMenuLinks) {
  let menuItemGroupArr = window.location.pathname.split('/');
  let menuItemGroup = menuItemGroupArr.length > 1 ? menuItemGroupArr[1] : menuItemGroupArr[0];
  let leftMenuAllowedLinks = userAllowedMenuLinks ? copyObjectValues(userAllowedMenuLinks).filter(e => e[MENU_ITEM.COLUMNS.MENU_ITEM_MACHINE_NAME] === menuItemGroup) : [];
  
  if (leftMenuAllowedLinks.length > 0) {
    leftMenuAllowedLinks[0].children = linearizeHierarchy(leftMenuAllowedLinks[0].children, 'children').filter(f => f.menu_item_type === 'link')
  }
  return leftMenuAllowedLinks;
}

function redirectToVectorDefinitionsScreen(event, scenario) {
  event.preventDefault(event);
  console.log(scenario)
  localStorage.setItem("tabs", Number(localStorage.getItem("tabs")) + 1);
  let vectorsWindows = window.open(window.location.origin + "/vector_definitions");
  vectorsWindows.scenario = JSON.stringify(scenario);
}

function redirectToPSLineDefinitionsScreen(event, scenario) {
  event.preventDefault(event)
  localStorage.setItem("tabs", Number(localStorage.getItem("tabs")) + 1);
  let defWindows = window.open(window.location.origin + "/profit_stack_line_definitions");
  defWindows.scenario = JSON.stringify(scenario);
}

function redirectToModelCoherenceScreen(event, scenario) {
    event.preventDefault(event)
    localStorage.setItem("tabs", Number(localStorage.getItem("tabs")) + 1);
    let defWindows = window.open(window.location.origin + "/model_coherence");
    defWindows.scenario = JSON.stringify(scenario);
}

/**
 * This function is used when you want to show a full screen loader for a specific action.
 * Example: when applying in Format Popup, we show a full loader and the behavior of the report loader (that does not cover left menu & header)
 * is still correct
 * @param {Boolean} show - show or hide the loader
 * @param {String} tag - name of the action or function that displays the loader
 */
function toggleFullScreenLoader(show, tag) {
  toggleLoader(show, tag, undefined, undefined, undefined, "full-screen-loader");
}

/**
 * function used to launch app toast
 */
function openAppToast(){
    $("#toastApp").addClass("show");
    setTimeout(function(){
        $("#toastApp").removeClass("show");
    }, 4000);
}


function setTreeRowColor(level){
    let borderColorOpacity = level === 0 ? 0.3 : 0.3 + Number(`0.${level}`);
    let rowLevel = $(`.row_level_${level}`);
    if (rowLevel.hasClass("tabulator-tree-level-0")) {
        rowLevel.attr('style', `border-color:rgba(188, 188, 220, ${borderColorOpacity}) !important`);
    } else if (rowLevel.hasClass("tabulator-tree-level-1")) {
        rowLevel.attr('style', `border-color:rgba(188, 188, 220, ${borderColorOpacity}) !important`);
    } else if (rowLevel.hasClass("tabulator-tree-level-2")) {
        rowLevel.attr('style', `border-color:rgba(188, 188, 220, ${borderColorOpacity}) !important`);
    } else if (rowLevel.hasClass("tabulator-tree-level-3")) {
        rowLevel.attr('style', `border-color:rgba(188, 188, 220, ${borderColorOpacity}) !important`);
    } else if (rowLevel.hasClass("tabulator-tree-level-4")) {
        rowLevel.attr('style', `border-color:rgba(188, 188, 220, ${borderColorOpacity}) !important`);
    } else if (rowLevel.hasClass("tabulator-tree-level-5")) {
        rowLevel.attr('style', `border-color:rgba(188, 188, 220, ${borderColorOpacity}) !important`);
    } else {
        rowLevel.attr('style', `border-color:rgba(188, 188, 220, ${borderColorOpacity}) !important`);
    }
}

/**
 * This function checks if the data has at least one vector that has segment/tier only selected in manage columns
 * and has N/A as values
 * @param {*} data 
 * @param {*} vectors 
 * @returns boolean
 */
function atLeastOneVectorHasNAInData (data, vectors) {
  let atLeastOneVectorHasNA = false
  for (let index in vectors) {
    let vectorName = data[vectors[index].value + SUFFIX.NAME] ;
    let vectorNumber = data[vectors[index].value + SUFFIX.NUMBER] ;
    let vectorTier = data[vectors[index].value + SUFFIX.SEGMENT_TIER] ;
    let vectorSegment = data[vectors[index].value + SUFFIX.SEGMENT] ;
    if(!vectorName && !vectorNumber && (vectorSegment || vectorTier)) {
      let isSegmentNA = vectorSegment?.toLowerCase() === VECTOR_ANALYSIS.FIELDS.NA_NAME.toLowerCase();
      let isTierNA = vectorTier?.toLowerCase() === VECTOR_ANALYSIS.FIELDS.NA_NAME.toLowerCase();
      atLeastOneVectorHasNA = isTierNA ||isSegmentNA;
      // if we found at least 1 vector that only has segment/tier selected with a N/A value no need to continue the loop.
      if(atLeastOneVectorHasNA) { 
        return true; 
      } 
    }
 };
 return false;
}

/**
 * Set drill column width state to be the same as the saved column widths in the list
 * @param {*} columnWidths 
 * @returns 
 */
function initDrillColumnWidth(columnWidths=[]) {
  let drillColumnsWidths =[];
  drillColumnsWidths.push({cardIndex: 0, widths: columnWidths});
  return drillColumnsWidths;
}

const isParentGroup = (item) => {
    const isItemFeature = item[MENU_ITEM.COLUMNS.CATEGORY] === MENU_ITEM.FIELDS.FEATURE;
    const doesItemHasChildren = item.children?.length > 0 && item.children.some(child => child[MENU_ITEM.COLUMNS.CATEGORY] !== MENU_ITEM.FIELDS.FEATURE);
    return !isItemFeature && doesItemHasChildren;
};

const excludeModelingSettings = (item) => {
    return (   item[MENU_ITEM.COLUMNS.MENU_ITEM_MACHINE_NAME] === MENU_ITEM.FIELDS.MANAGE_STACKS
        || item[MENU_ITEM.COLUMNS.MENU_ITEM_MACHINE_NAME] === MENU_ITEM.FIELDS.CONFIGURE_BRIDGE_REPORT
        || item[MENU_ITEM.COLUMNS.MENU_ITEM_MACHINE_NAME] === MENU_ITEM.FIELDS.MANAGE_CUSTOM_REPORTS)

};

/**
 * Returns the icon image based on category and type
 * @param {*} item
 * @returns
 */
const getIconImageName = (item) => {
    if (item.menu_item_category === "CONFIGURATION" && excludeModelingSettings(item) ) {
        return "configuration";
    } else if (item.category === CUSTOM_REPORT) {
        return "custom-report";
    } else if (item.menu_item_category === "ADMINISTRATION") {
        return "general_settings_menu";
    } else {
        return item[MENU_ITEM.COLUMNS.MENU_ITEM_MACHINE_NAME];
    }
};

const getMenuItemsContent = (children, onMenuItemClick) => {
    return children.map((child) => (
        <IconTitle
            key={child.menu_item_machine_name}
            iconTitleContainerClass={"tooltip-menu-items-children"}
            title={child.menu_item_display_name}
            subtitle={child[MENU_ITEM.COLUMNS.MENU_ITEM_DESCRIPTION]}
            titleClass={"tooltip-menu-items-children-title"}
            subtitleClass={"tooltip-menu-items-children-subtitle"}
            svgClass={"tooltip-menu-items-children-svg"}
            titleContainerClass={"tooltip-menu-items-title-container"}
            type={child.menu_item_type}
            parent_machine_name={child.parent_machine_name}
            machine_name={child.menu_item_machine_name}
            redirect_only={"true"}
            is_custom_report={`${child.category === CUSTOM_REPORT}`}
            is_tableau={`${child.is_tableau}`}
            menu_item_machine_name={`${child.menu_item_machine_name}`}
            url={child.url}
            icon={<img src={"/images/menu-svgs/" + getIconImageName(child) + ".svg"}/>}
            onClick={onMenuItemClick}
        />
    ));
};

function getMenuItemsAccordionContent(linkGroup, onMenuItemClick) {
    const useNewComponents = parseBoolean(getLocalStorageValueByParameter("useNewComponents"));
    const menuItemsContent = [];

    linkGroup.children.forEach((item) => {
        if (isParentGroup(item)) {
            const contents = getMenuItemsContent(item.children, onMenuItemClick);
            menuItemsContent.push({ titleObj: item, contentComponent: contents });
        } else if (item.menu_item_type === "link") {
            menuItemsContent.push({
                titleComponent: (
                    <IconTitle
                        key={item.menu_item_machine_name}
                        iconTitleContainerClass={"tooltip-menu-items"}
                        title={item.menu_item_display_name}
                        subtitle={item[MENU_ITEM.COLUMNS.MENU_ITEM_DESCRIPTION]}
                        titleClass={"tooltip-menu-items-title"}
                        subtitleClass={"tooltip-menu-items-subtitle"}
                        svgClass={`tooltip-menu-items-svg ${useNewComponents ? "new-design" : ""}`}
                        titleContainerClass={"tooltip-menu-items-title-container"}
                        type={item.menu_item_type}
                        parent_machine_name={item.parent_machine_name}
                        machine_name={item.menu_item_machine_name}
                        redirect_only="true"
                        is_custom_report={`${item.category === CUSTOM_REPORT}`}
                        is_tableau={`${item.is_tableau}`}
                        menu_item_machine_name={`${item.menu_item_machine_name}`}
                        icon={<img src={"/images/menu-svgs/" + getIconImageName(item) + ".svg"}/>}
                        url={item.url}
                        onClick={onMenuItemClick}
                        addBetaLabel={item.menu_item_machine_name === "return_insights"}
                    />
                )
            });
        }
    });

    return menuItemsContent;
};


export {
  dateToTemporalExpressions,
  getLeftMenuLinks,
  getMonthDayYear,
  messageDialogContent,
  redirectToPSLineDefinitionsScreen,
  redirectToModelCoherenceScreen,
  redirectToVectorDefinitionsScreen,
  renderButton,
  renderDropDown,
  renderPeriodCalendar,
  renderCalendar,
  renderTitle,
  runExtendedProfitStack,
  tablesToExcel,
  toggleFullScreenLoader,
  openAppToast,
  setTreeRowColor,
  atLeastOneVectorHasNAInData,
  extractAndPrepareObject,
  initDrillColumnWidth,
  renderBreadcrumbsAndDescription,
  getMenuItemsAccordionContent
};

