import { useEffect, useRef, useState } from "react";
import { convertPxToViewport } from "../../class/formatting";
import { getValidPeriods } from "../../templateLayout/functions/periodFunctions";
import { isValidDate } from "../../class/utils";
import { extractFromFullQuarter, generatePeriods, getFullQuarterFromStartEndQuarters, getGeneratedQuarterRange, getLastBuiltPeriodForSegments, getPeriodQuarter, getQuarterFromDate, monthDiff } from "../../class/date";
import { API_URL, FY_VALUES, RETURN_INSIGHTS, ROLLING_SEGMENTS } from "../../class/constants";
import { FETCH_METHOD, fetchAPI, FETCHAPI_PARAMS } from "../../class/networkUtils";
import { lang } from "../../language/messages_en";

import { ReactComponent as TableIcon } from "../../styles/images/actionableInsights/table.svg";

import OutliersTable from "./OutliersTable";
import { Skeleton } from "@mui/material";
import { SkeletonLoader } from "../../form/elements";
const ManagerView = (props) => {
    const [skusByBrands, setSkusByBrands] = useState([]);
    const [insights, setInsights] = useState([]);
    const [isFetchingInsights, setIsFetchingInsights] = useState(false);
    const abortControllers = useRef(new Map());

    useEffect(() => {
        return () => {
            abortControllers.current.forEach((controller, key) => {
                if (!controller.signal.aborted) {
                    controller.abort();
                }
            });
            abortControllers.current.clear(); 
        };
    }, []);
    
    useEffect(()=> {
        if (Object.keys(props?.periodsStatusState).length > 0 && Object.keys(props.clientPeriodsState).length > 0  && Object.keys(props.datasetState).length > 0) {
            setSkusByBrands([]);
            getSkusByBrandData();
        } else {
            setSkusByBrands([]);
        }
    }, [props?.periodsStatusState, props.clientPeriodsState, props.datasetState]);

    const createAbortController = (key) => {
        if (abortControllers.current.has(key)) {
            abortControllers.current.get(key).abort(); 
        }
        const controller = new AbortController();
        abortControllers.current.set(key, controller);
        return controller;
    };

    const getInsights=(insightsData)=>{
        const controller = createAbortController("getInsights");
        let query = {
            action: "getInsights",
            insightsData: insightsData,
            view: RETURN_INSIGHTS.MANAGER_VIEW.VALUE
        }
        let onThenCallback = (data)=>{
            abortControllers.current.delete("getInsights");
            setIsFetchingInsights(false);
            setInsights(data.insights);
        }
    
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "getInsights",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: false,
            [FETCHAPI_PARAMS.path]: API_URL.RETURNS_INSIGHTS,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]: lang.observability.output.returns_insights.screen_name ,
            [FETCHAPI_PARAMS.requestDescription]: lang.observability.output.returns_insights.get_insights_manager,
            [FETCHAPI_PARAMS.signal]:  controller.signal,
        }
        fetchAPI(fetchOptions);
    }

    const getPeriodsObject =()=> {
        let periods = [];
        let periods_yoy = [];
        let periods_pa = [];
        let quarter = "";
        let quarterPre = "";
        let segmentPeriod = "";
        let months = FY_VALUES.M3;
        let startDate = getValidPeriods(props.periodsStatusState, props.clientPeriodsState)?.startDate;
        let endDate = getValidPeriods(props.periodsStatusState, props.clientPeriodsState)?.endDate;
      
        if (isValidDate(startDate) && isValidDate(endDate) && startDate  && endDate) {
            let periodsCount = monthDiff(startDate, endDate) + 1;
            periods = generatePeriods(startDate, periodsCount);
            periods_yoy =  generatePeriods(startDate, periodsCount,false);
            periods_pa = generatePeriods(startDate, periodsCount,true);
        }
    
        let periodsStateOrProps = props.clientPeriodsState?.periods;
        if (!!periodsStateOrProps && isValidDate(startDate) && isValidDate(endDate)) {
            let firstQuarter = getQuarterFromDate(startDate);
            let endQuarter = getQuarterFromDate(endDate);
    
            let firstPreQuarter = getPeriodQuarter(periods_pa[0]);
            let endPreQuarter = getPeriodQuarter(periods_pa[periods_pa.length-1]);
    
            let generatedRange = getGeneratedQuarterRange(props.datasetState.datasetOptions, firstQuarter, endQuarter);
            let generatedRangePre = getGeneratedQuarterRange(props.datasetState.datasetOptions, firstPreQuarter, endPreQuarter);
    
            let fullQuarter = extractFromFullQuarter(getFullQuarterFromStartEndQuarters(generatedRange[0], generatedRange[1]));
            let fullQuarterPre = extractFromFullQuarter(getFullQuarterFromStartEndQuarters(generatedRangePre[0], generatedRangePre[1]));
    
            quarter = fullQuarter.quarter;
            quarterPre = fullQuarterPre.quarter;
            months = fullQuarter.months;
    
            let lastSelectedPeriod = periods[periods.length - 1];
            let builtPeriods = props.periodsStatusState?.actuallyBuiltPeriods?.map(m => m.label);
            segmentPeriod = getLastBuiltPeriodForSegments(builtPeriods, lastSelectedPeriod, 12);
        }
        return {periods: periods, segmentPeriod: segmentPeriod, quarter: quarter, months: months, periods_yoy: periods_yoy, periods_pa: periods_pa, preQuarter: quarterPre};
    }
    
    const getSkusByBrandData = () => {
        setIsFetchingInsights(true);
        let periodsObj = getPeriodsObject();// to get the quarter
        const controller = createAbortController("getSkusByBrandData")
        let query = {
            action: "getSkusByBrandData",
            scenario_id: props.scenarioState.scenario ,
            currentPeriods:periodsObj.periods,
            previousPeriods: periodsObj.periods_pa,
            previousYearPeriods: periodsObj.periods_yoy,
            quarter: periodsObj.quarter+(periodsObj.months && periodsObj.months !== FY_VALUES.M3? periodsObj.months :""),
            rollingPeriod: periodsObj.segmentPeriod,
            rollingSegment: ROLLING_SEGMENTS.R_12,
            FY:  FY_VALUES.M3,
        }

        let onThenCallback = (data)=>{
            abortControllers.current.delete("getSkusByBrandData");
            setSkusByBrands(data.skus_by_brands);
            getInsights(data.skus_by_brands);
        }

        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "getSkusByBrandData",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: false,
            [FETCHAPI_PARAMS.path]: API_URL.RETURNS_INSIGHTS,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]: lang.observability.output.returns_insights.screen_name ,
            [FETCHAPI_PARAMS.requestDescription]: lang.observability.output.returns_insights.get_returns,
            [FETCHAPI_PARAMS.scopeFilterApplicable]: true,
            [FETCHAPI_PARAMS.periods]:  periodsObj.periods.join(",") + "," + periodsObj.periods_pa.join(","),
            [FETCHAPI_PARAMS.signal]:  controller.signal,
        }
        fetchAPI(fetchOptions);
    }
    
    return (
        <div style={{background:"#f9fafb", padding:"0.78125vw 0.677vw", overflow:"auto", maxHeight:"84%"}}>
        <div className="manager-view-container">
            <div style={{display:"flex", flexDirection:"column", gap:"0.78125vw"}}>
                <div className="bold">Insights</div>
                <div style={{display:"flex", gap:"1.042vw", flexWrap: "wrap"}}>
                    {isFetchingInsights?
                    <div className="ul-container"><SkeletonLoader /></div>
                    :insights?.map((e, index) => (
                        <div className="ul-container" 
                            key={index}
                            style={{
                                width:"calc(33.33% - 1.042vw)",
                                backgroundColor:"#ffff",
                                borderRadius:"0.417vw",
                                display:"flex", flexDirection:"column",
                                rowGap:convertPxToViewport(8),
                                boxShadow:"3px 6px 8px 0 rgba(0, 0, 0, 0.1)",
                                borderLeft:"4px solid #6495ED",
                                padding:"0.885vw 0.677vw"
                                }}
                        >
                            <div style={{color:"#6495ED", fontSize:"0.833vw", fontWeight:'600'}}>{e.title}</div>
                            <div style={{color:"grey", fontSize:"0.78125vw"}}>
                                {e.insight}
                            </div>
                        </div>
                    ))
                    }
                </div>
            </div>
            <div className= "table-parent-container">
                <div style={{display:"flex", flexDirection:"column", gap:"1.042vw"}}>
                    <span style={{fontSize: "1.042vw", fontWeight: '600'}}>Category Manager Dashboard</span>
                    <div className="table-container">
                        <div><TableIcon/></div>
                        <div className="manager-view-title">Brand/Sku Outliers Analysis</div>
                    </div>
                </div>
            </div>
            <div style={{display:"inline-grid"}}>
                {skusByBrands?.length > 0 ? 
                    <OutliersTable data={skusByBrands} />:  
                    <div style={{paddingTop:'6vw', display:'flex', flexDirection:"column", justifyContent:"space-between"}}>
                        <Skeleton variant="text" sx={{ fontSize: '2rem' }} width={'100%'} height={'15%'} animation="wave"/>
                        <Skeleton variant="text" sx={{ fontSize: '2rem' }} width={'100%'} height={'15%'} animation="wave"/>
                        <Skeleton variant="text" sx={{ fontSize: '2rem' }} width={'100%'} height={'15%'} animation="wave"/>
                        <Skeleton variant="text" sx={{ fontSize: '2rem' }} width={'100%'} height={'15%'} animation="wave"/>
                        <Skeleton variant="text" sx={{ fontSize: '2rem' }} width={'100%'} height={'15%'} animation="wave"/>
                    </div>
                }
            </div>
        </div>
        </div>
    )

}
export default ManagerView;