import { parseBoolean, tryParse } from "../../class/utils";
import { lang } from "../../language/messages_en";

function formatMetricFileConfifuration(file, calculatedColumnsFiles) {
  if(!file) { return {}; }

  let config = tryParse(file.metric_configuration, []);
  if (config?.length === 0) { return {}; }

  let metricConfig = {};
  let dataSources = [];
  let dataFields = [];
  config = config[0]?.metricConfiguration;
  
  for (let row of config) {
    if(row?.file_type === "calculated_columns") { // in case we select calculated columns in the metrics
      let ccFile = calculatedColumnsFiles.find(f => f.columnName === row.raw_field_name);
      let ccConfig = formatCalculateColumnFileConfiguration(ccFile)
      dataSources = dataSources.concat(ccConfig.dataSources);
      dataFields = dataFields.concat(ccConfig.dataFields);
    } else {
      if(row?.display_file_type) {
        dataSources.push(row?.display_file_type);
      }
  
      if(row?.raw_field_name) {
        dataFields.push(row?.raw_field_name);
      }
    }
  }

  metricConfig = {
    dataSources: dataSources,
    dataFields: dataFields,
  };

  return metricConfig;
}

function formatCalculateColumnFileConfiguration(file) {
  if(!file) { return {}; }

  let config = tryParse(file.columnFormula, []);
  if (config?.length === 0) { return {}; }

  let fileConfig = {};
  let dataSources = [];
  let dataFields = [];
  let conditionsResult = config?.conditions?.[0]?.result?.filter((f) => f.type === "column");

  if (conditionsResult?.length > 0) {
    for (let row of conditionsResult) {
      dataSources.push(row.file?.label);
      dataFields.push(row.column?.label);
    }
  } else {
    let result = config?.result?.filter((f) => f.type === "column");
    if (result?.length > 0) {
      for (let row of result) {
        dataSources.push(row.file?.label);
        dataFields.push(row.column?.label);
      }
    }
  }

  fileConfig = {
    dataSources: dataSources,
    dataFields: dataFields,
  };

  return fileConfig;
}

// Helper function to get all files (ancillary, calculated, and metric)
function getAllFiles(metricFields, ancillary, calculatedColumns) {
  const ancillaryFiles = ancillary?.map((m) => ({ label: m.display_file_type, value: m.file_type }));

  const metricFiles = metricFields?.map((item) => ({
    label: item.metric_display_name,
    value: item.metric,
    configuration: formatMetricFileConfifuration(item, calculatedColumns),
  }));

  const calculatedColumnsFiles = calculatedColumns?.map((m) => ({
    label: m.displayColumnName,
    value: m.columnName,
    configuration: formatCalculateColumnFileConfiguration(m),
  }));

  return (calculatedColumnsFiles || []).concat(ancillaryFiles || [], metricFiles || []);
}

// Helper function to get the file entity for the column name
function getFileEntityForColumnName(columnName, allFiles) {
  const fileEntity = allFiles?.find((f) => f.value === columnName);
  return fileEntity?.label;
}

function getFileColumn(line, files) {
  let fileColumn;
  const isVariance = !parseBoolean(line.is_matched);

  if (isVariance) {
    fileColumn = lang.of_variance;
  } else {
    const subtype = line.subType;
    if (subtype && subtype !== "") {
      fileColumn = line.driver_type === "metric" ? files.metricFields?.find((e) => e.metric === subtype).metric_display_name : subtype;
    } else {
      const allFiles = getAllFiles(files.metricFields, files.ancillaryFiles, files.calculatedColumnsFiles);
      fileColumn = getFileEntityForColumnName(line.column_name, allFiles);
    }
  }
  return fileColumn;
}

function getDataSources(line, files) {
  const isVariance = !parseBoolean(line.is_matched);

  if (isVariance) { // we don't show any data sources/fields if it's variance
    return {};
  }

  const allFiles = getAllFiles(files.metricFields, [], files.calculatedColumnsFiles);
  if (line.driver_type === "ancillary") {
    return { dataFields: [line.rawFileFieldName] };
  }

  if (line.driver_type === "metric") {
    const fileEntity = allFiles?.find((f) => f.value === line.subType);
    return fileEntity.configuration;
  }

  const fileEntity = allFiles?.find((f) => f.value === line.column_name);
  return fileEntity?.configuration || {};
}

export {
  getAllFiles,
  getFileColumn,
  getFileEntityForColumnName,
  getDataSources,
  formatMetricFileConfifuration,
  formatCalculateColumnFileConfiguration,
};
